import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link as RouterLink, useNavigate, useParams } from 'react-router-dom';
// Formik
// import * as Yup from 'yup';
import { Formik, Field, Form, ErrorMessage, FieldArray } from 'formik';
import { TextField } from 'formik-mui';
// material
import {
  Typography,
  Container,
  Grid,
  FormLabel,
  Button,
  MenuItem,
  CircularProgress
} from '@mui/material';
import Chip from '@mui/material/Chip';
import { LoadingButton } from '@mui/lab';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import RichTextEditor from 'src/components/RichTextEditor';
//
import SectionImg from '../../../components/SectionImg';
// ----------------------------------------------------------------------
import { allCountries } from 'src/Redux/actions/countryActions';
import { updatePageContent, singlePageContent } from 'src/Redux/actions/pageSectionActions';
import Loader from 'src/components/Loader';

export default function EditHomePage() {
  const { slug } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  // Get All Countries
  const [countryLoading, setCountryLoading] = useState(false);
  const pageState = useSelector((state) => state.countries?.items);
  
  // Get Single page
  const [pageLoading, setPageLoading] = useState(false);
  const pageData = useSelector((state) => state.pageSections?.items);
  useEffect(() => {
    const fetchData = () => {
      try {
        setCountryLoading(true);
        dispatch(allCountries(setCountryLoading));
      } catch (error) {
        setCountryLoading(false);
      }
    };
    fetchData();
  }, []);

  // Get Page by slug
  useEffect(() => {
    const fetchData = () => {
      try {
        setPageLoading(true);
        setTimeout(() => {
          dispatch(singlePageContent(slug));
          setPageLoading(false);
        }, 1000);
      } catch (error) {
        setPageLoading(true);
      }
    };
    fetchData();
  }, []);

  return (
    <Container>
      <Grid container justifyContent="center">
        <Grid item lg={10} md={10} xs={10} sm={10}>
          {pageLoading ? (
            <Loader />
          ) : (
            <Card
              sx={{
                minWidth: 275,
                boxShadow: 3,
                borderRadius: '10px',
                border: '1px solid #929eaa6b'
              }}
            >
              <CardContent>
                <Formik
                  enableReinitialize
                  initialValues={pageData?.page}
                  // validationSchema={PageSchema}
                  onSubmit={(values) => {
                    dispatch(updatePageContent(values, navigate));
                    setLoading(true);
                    setTimeout(() => {
                      setLoading(false);
                    }, 1000);
                  }}
                >
                  {({ values, push, remove, setFieldValue }) => (
                    <Form autoComplete="off" style={{ padding: 'inherit' }}>
                      <Typography variant="h4" gutterBottom mb={2}>
                        Edit Page Content
                      </Typography>
                      <div className="input-section">
                        {countryLoading && <CircularProgress size={30} />}
                        {!countryLoading && pageState?.countries?.length && values?.country && (
                          <Field
                            name="country"
                            select
                            value={values?.country}
                            component={TextField}
                            sx={{ width: '95%' }}
                            label="Select Country"
                          >
                            <MenuItem value="">Not Selected</MenuItem>
                            {pageState.countries?.map((country) => {
                              if (country.active == true)
                                return (
                                  <MenuItem
                                    id={country._id}
                                    key={country._id}
                                    value={country.countrySlug}
                                    label={country.countryName}
                                  >
                                    {country.countryName}
                                  </MenuItem>
                                );
                            })}
                          </Field>
                        )}
                      </div>
                      {/* Page Meta */}
                      <SectionName name="Page Meta Data" color="info" />
                      <div className="input-section">
                        <FormLabel htmlFor="metaTitle">Meta Title </FormLabel>
                        <Field
                          id="metaTitle"
                          name="metaTitle"
                          type="text"
                          placeholder="Enter First Heading"
                          className="input-style"
                        />
                        <ErrorMessage name="metaTitle" component="div" className="error-color" />
                      </div>
                      <div className="input-section">
                        <FormLabel htmlFor="metaDescription">Meta Description </FormLabel>
                        <Field
                          id="metaDescription"
                          name="metaDescription"
                          type="text"
                          placeholder="Enter First Heading"
                          className="input-style"
                        />
                        <ErrorMessage
                          name="metaDescription"
                          component="div"
                          className="error-color"
                        />
                      </div>
                      <div className="input-section">
                        <FormLabel htmlFor="canonicalLink">Canonical Link </FormLabel>
                        <Field
                          id="canonicalLink"
                          name="canonicalLink"
                          type="text"
                          placeholder="Enter First Heading"
                          className="input-style"
                        />
                        <ErrorMessage
                          name="canonicalLink"
                          component="div"
                          className="error-color"
                        />
                      </div>
                      {/* Home Hero Section */}
                      <Typography variant="h5" gutterBottom mt={2} mb={1}>
                        Add Sections
                      </Typography>
                      <SectionName name="Hero Section" />
                      <div className="input-section">
                        <FormLabel htmlFor="heroSection.heading1">First Heading </FormLabel>
                        <Field
                          id="heroSection.heading1"
                          name="heroSection.heading1"
                          type="text"
                          placeholder="Enter First Heading"
                          className="input-style"
                        />
                        <ErrorMessage
                          name="heroSection.heading1"
                          component="div"
                          className="error-color"
                        />
                      </div>
                      <div className="input-section">
                        <FormLabel htmlFor="heroSection.heading2">Second Heading </FormLabel>
                        <Field
                          id="heroSection.heading2"
                          name="heroSection.heading2"
                          type="text"
                          placeholder="Enter Second Heading"
                          className="input-style"
                        />
                        <ErrorMessage
                          name="heroSection.heading2"
                          component="div"
                          className="error-color"
                        />
                      </div>
                      <div className="input-section">
                        <FormLabel htmlFor="heroSection.heading3">Third Heading </FormLabel>
                        <Field
                          id="heroSection.heading3"
                          name="heroSection.heading3"
                          type="text"
                          placeholder="Enter Third Heading"
                          className="input-style"
                        />
                        <ErrorMessage
                          name="heroSection.heading3"
                          component="div"
                          className="error-color"
                        />
                      </div>
                      <div className="input-section">
                        <FormLabel htmlFor="heroSection.btnLink1">First Button Link </FormLabel>
                        <Field
                          id="heroSection.btnLink1"
                          name="heroSection.btnLink1"
                          type="text"
                          placeholder="Enter Third Heading"
                          className="input-style"
                        />
                        <ErrorMessage
                          name="heroSection.btnLink1"
                          component="div"
                          className="error-color"
                        />
                      </div>
                      <div className="input-section">
                        <FormLabel htmlFor="heroSection.btnLink2">Second Button Link </FormLabel>
                        <Field
                          id="heroSection.btnLink2"
                          name="heroSection.btnLink2"
                          type="text"
                          placeholder="Enter Third Heading"
                          className="input-style"
                        />
                        <ErrorMessage
                          name="heroSection.btnLink2"
                          component="div"
                          className="error-color"
                        />
                      </div>
                      <div className="input-section">
                        <FormLabel id="heroSection.heroImg">Section Image</FormLabel>
                        <SectionImg Value={setFieldValue} name="heroSection.heroImg" />
                      </div>
                      {/* Top Values */}
                      <div>
                        <SectionName name="Top Values" />
                        {values?.valuesSection?.data?.length &&
                          values?.valuesSection?.data?.map((items, idx) => {
                            return (
                              <FieldArray key={idx} name="valuesSection.data">
                                {({ remove, push }) => (
                                  <div className="row">
                                    <div className="row">
                                      <div className="col button-style">
                                        <Typography variant="h6" gutterBottom mt={1}>
                                          Top Values Column {idx + 1}
                                        </Typography>
                                        <div className="input-section">
                                          <FormLabel
                                            htmlFor={`valuesSection.data[${idx}].valuesHeading`}
                                          >
                                            Values Heading
                                          </FormLabel>
                                          <Field
                                            id={`valuesSection.data[${idx}].valuesHeading`}
                                            name={`valuesSection.data[${idx}].valuesHeading`}
                                            type="text"
                                            placeholder="Enter Title"
                                            className="input-style"
                                          />
                                          <ErrorMessage
                                            name={`valuesSection.data[${idx}].valuesHeading`}
                                            component="div"
                                            className="error-color"
                                          />
                                        </div>

                                        <div className="input-section">
                                          <FormLabel
                                            htmlFor={`valuesSection.data[${idx}].valuesDesc`}
                                          >
                                            Values Description
                                          </FormLabel>
                                          <Field
                                            id={`valuesSection.data[${idx}].valuesDesc`}
                                            name={`valuesSection.data[${idx}].valuesDesc`}
                                            type="text"
                                            placeholder="Enter Title"
                                            className="input-style"
                                          />
                                          <ErrorMessage
                                            name={`valuesSection.data[${idx}].valuesDesc`}
                                            component="div"
                                            className="error-color"
                                          />
                                        </div>
                                        <div className="input-section">
                                          <FormLabel
                                            htmlFor={`valuesSection.data[${idx}].valuesImg`}
                                          >
                                            Values Image
                                          </FormLabel>
                                          <SectionImg
                                            imgType="svg"
                                            setValue={setFieldValue}
                                            name={`valuesSection.data[${idx}].valuesImg`}
                                          />
                                          <ErrorMessage
                                            name={`valuesSection.data[${idx}].valuesImg`}
                                            component="div"
                                            className="error-color"
                                          />
                                        </div>
                                      </div>
                                      <div className="col button-style">
                                        <div>
                                          <Button
                                            type="button"
                                            variant="contained"
                                            color="secondary"
                                            sx={{ mt: 2, mb: 2, mr: 1 }}
                                            onClick={() =>
                                              push({ valuesHeading: '', valuesDesc: '' })
                                            }
                                          >
                                            Add Column
                                          </Button>
                                          {idx !== 0 && (
                                            <Button
                                              type="button"
                                              sx={{ mt: 2, ml: idx !== 0 && 2, mb: 2 }}
                                              variant="contained"
                                              color="error"
                                              onClick={() => remove(idx)}
                                              className="home-section-delete-button"
                                            >
                                              Delete Column
                                            </Button>
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                )}
                              </FieldArray>
                            );
                          })}
                      </div>
                      {/* Social Media Growth - 4th Section */}
                      <SectionName name="Social Media Growth" />
                      <div className="input-section">
                        <FormLabel htmlFor="smGrowth.smHeading">Social Media Heading </FormLabel>
                        <Field
                          id="smGrowth.smHeading"
                          name="smGrowth.smHeading"
                          type="text"
                          placeholder="Enter Heading"
                          className="input-style"
                        />
                        <ErrorMessage
                          name="smGrowth.smHeading"
                          component="div"
                          className="error-color"
                        />
                      </div>
                      <div className="input-section">
                        <FormLabel htmlFor="smGrowth.smDescription1">
                          Social Media First Description{' '}
                        </FormLabel>
                        <RichTextEditor
                          id="smGrowth.smDescription1"
                          name="smGrowth.smDescription1"
                          placeholder="Enter First Description"
                          value={values?.smGrowth?.smDescription1}
                          setFieldValue={setFieldValue}
                        />
                        <ErrorMessage
                          name="smGrowth.smDescription1"
                          component="div"
                          className="error-color"
                        />
                      </div>
                      <div className="input-section">
                        <FormLabel htmlFor="smGrowth.smDescription2">
                          Social Media Second Description{' '}
                        </FormLabel>
                        <RichTextEditor
                          id="smGrowth.smDescription2"
                          name="smGrowth.smDescription2"
                          placeholder="Enter Second Description"
                          value={values?.smGrowth?.smDescription2}
                          setFieldValue={setFieldValue}
                        />
                        <ErrorMessage
                          name="smGrowth.smDescription2"
                          component="div"
                          className="error-color"
                        />
                      </div>
                      <div>
                        <SectionName name="Social Media Growth Sections" />
                        {values?.smGrowth?.smSection?.data?.length &&
                          values?.smGrowth?.smSection?.data?.map((items, idx) => {
                            return (
                              <FieldArray key={idx} name="smGrowth.smSection.data">
                                {({ remove, push }) => (
                                  <div className="row">
                                    <div className="row">
                                      <div className="col button-style">
                                        <Typography variant="h6" gutterBottom mt={1}>
                                          Social Media Column {idx + 1}
                                        </Typography>
                                        <div className="input-section">
                                          <FormLabel
                                            htmlFor={`smGrowth.smSection.data[${idx}].heading`}
                                          >
                                            Social Media Column Heading
                                          </FormLabel>
                                          <Field
                                            id={`smGrowth.smSection.data[${idx}].heading`}
                                            name={`smGrowth.smSection.data[${idx}].heading`}
                                            type="text"
                                            placeholder="Enter Title"
                                            className="input-style"
                                          />
                                          <ErrorMessage
                                            name={`smGrowth.smSection.data[${idx}].heading`}
                                            component="div"
                                            className="error-color"
                                          />
                                        </div>
                                        <div className="input-section">
                                          <FormLabel
                                            htmlFor={`smGrowth.smSection.data[${idx}].description`}
                                          >
                                            Social Media Column Description
                                          </FormLabel>
                                          <Field
                                            id={`smGrowth.smSection.data[${idx}].description`}
                                            name={`smGrowth.smSection.data[${idx}].description`}
                                            type="text"
                                            placeholder="Enter Title"
                                            className="input-style"
                                          />
                                          <ErrorMessage
                                            name={`smGrowth.smSection.data[${idx}].description`}
                                            component="div"
                                            className="error-color"
                                          />
                                        </div>
                                        <div className="input-section">
                                          <FormLabel
                                            htmlFor={`smGrowth.smSection.data[${idx}].btnLink`}
                                          >
                                            Social Media Column Button Link
                                          </FormLabel>
                                          <Field
                                            id={`smGrowth.smSection.data[${idx}].btnLink`}
                                            name={`smGrowth.smSection.data[${idx}].btnLink`}
                                            type="text"
                                            placeholder="Enter Title"
                                            className="input-style"
                                          />
                                          <ErrorMessage
                                            name={`smGrowth.smSection.data[${idx}].btnLink`}
                                            component="div"
                                            className="error-color"
                                          />
                                        </div>
                                      </div>
                                      <div className="col button-style">
                                        <div>
                                          <Button
                                            type="button"
                                            variant="contained"
                                            color="secondary"
                                            sx={{ mt: 2, mb: 2 }}
                                            onClick={() =>
                                              push({
                                                heading: '',
                                                description: '',
                                                btnLink: ''
                                              })
                                            }
                                          >
                                            Add Column
                                          </Button>
                                          {idx !== 0 && (
                                            <Button
                                              type="button"
                                              sx={{ mt: 2, ml: idx !== 0 && 2, mb: 2 }}
                                              variant="contained"
                                              color="error"
                                              onClick={() => remove(idx)}
                                              className="home-section-delete-button"
                                            >
                                              Delete Column
                                            </Button>
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                )}
                              </FieldArray>
                            );
                          })}
                      </div>
                      {/* Buy Section - 5th Section */}
                      <div>
                        <SectionName name="Buy Section" />
                        {values?.buySection?.data?.length &&
                          values?.buySection?.data?.map((items, idx) => {
                            return (
                              <FieldArray key={idx} name="buySection.data">
                                {({ remove, push }) => (
                                  <div className="row">
                                    <div className="row">
                                      <div className="col button-style">
                                        <Typography variant="h6" gutterBottom mt={1}>
                                          Buy Column {idx + 1}
                                        </Typography>
                                        <div className="input-section">
                                          <FormLabel htmlFor={`buySection.data[${idx}].heading`}>
                                            Buy Column Heading
                                          </FormLabel>
                                          <Field
                                            id={`buySection.data[${idx}].heading`}
                                            name={`buySection.data[${idx}].heading`}
                                            type="text"
                                            placeholder="Enter Heading"
                                            className="input-style"
                                          />
                                          <ErrorMessage
                                            name={`buySection.data[${idx}].heading`}
                                            component="div"
                                            className="error-color"
                                          />
                                        </div>
                                        <div className="input-section">
                                          <FormLabel
                                            htmlFor={`buySection.data[${idx}].description`}
                                          >
                                            Buy Column Description
                                          </FormLabel>
                                          <RichTextEditor
                                            id={`buySection.data[${idx}].description`}
                                            name={`buySection.data[${idx}].description`}
                                            placeholder="Enter Description"
                                            value={values.buySection.data[idx].description}
                                            setFieldValue={setFieldValue}
                                          />
                                          <ErrorMessage
                                            name={`buySection.data[${idx}].description`}
                                            component="div"
                                            className="error-color"
                                          />
                                        </div>
                                        <div className="input-section">
                                          <FormLabel htmlFor={`buySection.data[${idx}].btnLink`}>
                                            Buy Column Button Link
                                          </FormLabel>
                                          <Field
                                            id={`buySection.data[${idx}].btnLink`}
                                            name={`buySection.data[${idx}].btnLink`}
                                            type="text"
                                            placeholder="Enter Button Link"
                                            className="input-style"
                                          />
                                          <ErrorMessage
                                            name={`buySection.data[${idx}].btnLink`}
                                            component="div"
                                            className="error-color"
                                          />
                                        </div>
                                        <div className="input-section">
                                          <FormLabel htmlFor={`buySection.data[${idx}].buyImg`}>
                                            Buy Column Image
                                          </FormLabel>
                                          <SectionImg
                                            setValue={setFieldValue}
                                            name={`buySection.data[${idx}].buyImg`}
                                          />
                                          <ErrorMessage
                                            name={`buySection.data[${idx}].buyImg`}
                                            component="div"
                                            className="error-color"
                                          />
                                        </div>
                                      </div>
                                      <div className="col button-style">
                                        <div>
                                          <Button
                                            type="button"
                                            variant="contained"
                                            color="secondary"
                                            sx={{ mt: 2, mb: 2 }}
                                            onClick={() =>
                                              push({
                                                heading: '',
                                                description: '',
                                                btnLink: ''
                                              })
                                            }
                                          >
                                            Add Column
                                          </Button>
                                          {idx !== 0 && (
                                            <Button
                                              type="button"
                                              sx={{ mt: 2, ml: idx !== 0 && 2, mb: 2 }}
                                              variant="contained"
                                              color="error"
                                              onClick={() => remove(idx)}
                                              className="home-section-delete-button"
                                            >
                                              Delete Column
                                            </Button>
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                )}
                              </FieldArray>
                            );
                          })}
                      </div>
                      <Grid container>
                        <Grid item xs={4} sm={4} md={3} lg={2} mt={3}>
                          <LoadingButton
                            fullWidth
                            size="large"
                            type="submit"
                            variant="contained"
                            loading={loading}
                          >
                            Submit
                          </LoadingButton>
                        </Grid>
                        <Grid item xs={4} sm={4} md={3} lg={2} ml={2} mt={3}>
                          <Button
                            fullWidth
                            size="large"
                            variant="contained"
                            color="error"
                            component={RouterLink}
                            to="/dashboard/home-page-content"
                          >
                            Cancel
                          </Button>
                        </Grid>
                      </Grid>
                    </Form>
                  )}
                </Formik>
              </CardContent>
            </Card>
          )}
        </Grid>
      </Grid>
    </Container>
  );
}
export const SectionName = (props) => {
  return (
    <div style={{ margin: '15px 0 6px 0' }}>
      <Chip color={props.color ? props.color : 'success'} variant="outlined" label={props.name} />
    </div>
  );
};
