import React, { useState } from 'react';
import { Link as RouterLink, Navigate, useNavigate } from 'react-router-dom';
import { sentenceCase } from 'change-case';
import moment from 'moment';
// Material
import { Stack, Typography, Button, Container } from '@mui/material';
//
import Page from 'src/components/Page';
import Label from 'src/components/Label';
import Iconify from 'src/components/Iconify';
import { getUserRoles } from 'src/api/api';
import CustomTable from 'src/components/CustomTable';
import RoleActionButton from './components/RoleActionButton';
import withRole from 'src/components/HOC/withRole';
import { JavascriptOutlined } from '@mui/icons-material';

function UserRoles(props) {
  const navigate = useNavigate();
  // Fetch Data from API
  // Searchable Filters
  const [platform, setPlatform] = useState([]);
  const [loading, setLoading] = React.useState(false);
  const [rows, setRows] = useState([]);
  // For User Role
  const userRole = props.user?.userRole;
  const [state, setState] = useState({});

  // Get All User Roles
  React.useEffect(() => {
    setLoading(true);
    const promise = getUserRoles();
    Promise.resolve(promise).then((data) => {
      if (data.success) {
        const userroles = data?.userroles;
        if (userroles && userroles?.length) {
          setPlatform(data?.userroles);
          setRows(data?.userroles);
        }
        setLoading(false);
      } else {
        setPlatform([]);
        setRows([]);
        setLoading(false);
      }
    });
  }, []);

  // To check User Role
  React.useEffect(() => {
    const type = userRole == '1' ? null : navigate('/dashboard/app');
    return () => {
      setState({}); // To Avoid Memory Leak
      return type;
    };
  }, []);

  const columns = [
    { field: 'roleName', headerName: 'Role Name', width: 180 },
    {
      field: 'status',
      headerName: 'Status',
      width: 180,
      renderCell: (rowData) => <>{rowData.row.status === true ? 'Active' : 'In-Active'}</>
    },
    {
      field: 'roleValue',
      headerName: 'Role Value',
      width: 180,
      renderCell: (rowData) => (
        <Label
          variant="ghost"
          color={
            rowData.row.roleValue === 2
              ? 'error'
              : rowData.row.roleValue === 3
              ? 'warning'
              : rowData.row.roleValue === 4
              ? 'info'
              : rowData.row.roleValue === 5
              ? 'secondary'
              : 'success'
          }
        >
          {rowData.row.roleValue}
        </Label>
      )
    },
    {
      field: 'createdAt',
      headerName: 'Date',
      width: 220,
      renderCell: (rowData) => moment(rowData.row.createdAt).format('MMMM Do, YYYY - HH:mm a')
    },
    {
      field: 'action',
      headerName: 'Action',
      width: 150,
      renderCell: (rowData) => <RoleActionButton id={rowData.row._id} />
    }
  ];

  return (
    <Page title="Dashboard | User Roles">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={4}>
          <Typography variant="h4" gutterBottom>
            User Roles
          </Typography>
          <Button
            variant="contained"
            component={RouterLink}
            to="/dashboard/user-role/add-user-role"
            startIcon={<Iconify icon="eva:plus-fill" />}
          >
            Add Role
          </Button>
        </Stack>

        <CustomTable
          searchPlaceholder="Search..."
          tableHeading="for User Roles"
          platform={platform}
          setRows={setRows}
          rows={rows}
          rowId={(row) => row._id}
          columns={columns}
          loading={loading}
          data={platform?.length ? platform?.length : 'No Data'} // For No Data
        />
      </Container>
    </Page>
  );
}
export default withRole(UserRoles);
