import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { Form, Formik, Field, FieldArray, ErrorMessage } from 'formik';
import { Switch } from 'formik-mui';
// material
import {
  Stack,
  Typography,
  Grid,
  Box,
  Button,
  Menu,
  MenuItem,
  ListItemIcon,
  IconButton,
  FormLabel,
  TextField,
  CircularProgress,
  Divider,
  Chip,
  Card,
  CardContent,
  FormControlLabel
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
// Components
import CustomButton from 'src/components/Button';
import ImageDeleteDialog from './ImgDeleteDialog';
import Iconify from 'src/components/Iconify';
// Actions
import { allCountries } from 'src/Redux/actions/countryActions';
import { createCategoryContent, getServiceContent } from 'src/Redux/actions/singleServiceActions';
import { getServicesWithCategories } from 'src/api/api';
import RichTextEditor from 'src/components/RichTextEditor';
import SectionImg from 'src/components/SectionImg';
import Loader from 'src/components/Loader';

export default function UpdateServiceContent() {
  const { id, slug } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const [imageDialogOpen, setImageDialogOpen] = useState(null);

  // Fetch Data from API
  const [services, setServices] = useState([]);
  const [serviceLoading, setServiceLoading] = useState(false);
  const [countryLoading, setCountryLoading] = useState(false);
  const [contentLoading, setContentLoading] = useState(false);
  // const [countries, setCountries] = useState([]);
  const pageState = useSelector((state) => state.countries.items);
  const contentState = useSelector((state) => state.services.items); // For Single Service

  // Filter Services
  const [serviceValue, setServiceValue] = useState(contentState?.category?.service?.name);
  const [servicesCategory, setServicesCategory] = useState([]);
  const [serviceCategoryLoading, setServiceCategoryLoading] = useState(false);

  useEffect(() => {
    const fetchData = () => {
      try {
        setCountryLoading(true);
        dispatch(allCountries(setCountryLoading));
      } catch (error) {
        setCountryLoading(false);
      }
    };
    fetchData();
  }, []);

  // Get All Services
  useEffect(() => {
    setServiceLoading(true);
    const promise = getServicesWithCategories();
    Promise.resolve(promise).then((data) => {
      if (data.success) {
        const services = data?.services;
        if (services && services?.length) {
          setServices(data?.services);
        }
        setTimeout(() => {
          setServiceLoading(false);
        }, 1000);
      } else {
        setServices([]);
        setServiceLoading(false);
      }
    });
  }, []);

  // Filter SubCategories
  React.useEffect(() => {
    const data = services?.find((item) => {
      const newValue = serviceValue ? serviceValue : contentState?.category?.service?.name;
      return item.name == newValue;
    });
    setServiceCategoryLoading(true);
    setServicesCategory(data?.categories);
    setTimeout(() => {
      setServiceCategoryLoading(false);
    }, 1200);
  }, [contentState?.category, serviceValue]);

  useEffect(() => {
    const fetchData = () => {
      try {
        setContentLoading(true);
        dispatch(getServiceContent(id, slug));
        setTimeout(() => {
          setContentLoading(false);
        }, 1000);
      } catch (error) {
        setContentLoading(true);
      }
    };
    fetchData();
  }, []);

  const newArr = (contentState.content?.longDescription &&
    contentState.content.longDescription.map((item) => {
      let value = {
        img: item.img,
        text: item.text
      };
      value.img.oldImg = item.img ? item.img.src : '';
      return value;
    })) || [
    {
      img: { alt: '', width: 'auto', height: 'auto', position: 'left' },
      text: ''
    }
  ];

  return (
    <Grid container justifyContent="center">
      <Grid item lg={10} md={10} xs={10} sm={10}>
        {contentLoading ? (
          <Loader />
        ) : (
          <Card
            sx={{ minWidth: 275, boxShadow: 3, borderRadius: 1, border: '1px solid #929eaa6b' }}
          >
            <CardContent>
              <Formik
                enableReinitialize={true}
                initialValues={{
                  service: contentState.category ? contentState?.category?.service?.name : '',
                  category: contentState.category ? contentState?.category?._id : '',
                  country: slug,
                  content: {
                    shortDescription: contentState.content?.shortDescription
                      ? contentState.content.shortDescription
                      : '',
                    longDescription: newArr,
                    faqs:
                      contentState.content?.faqs?.length > 0
                        ? contentState.content?.faqs
                        : [{ title: '', description: '' }]
                  },
                  active: true
                }}
                onSubmit={(values) => {
                  dispatch(createCategoryContent(values, navigate));
                  setLoading(true);
                  setTimeout(() => {
                    setLoading(false);
                  }, 1000);
                }}
              >
                {({ errors, touched, values, setFieldValue, push }) => (
                  <Form autoComplete="off" style={{ padding: 'inherit' }}>
                    <Typography variant="h4" gutterBottom>
                      Update Service Content
                    </Typography>
                    <Typography variant="p" gutterBottom>
                      Service: <strong>{contentState.category?.name}</strong>
                    </Typography>
                    <Stack spacing={1} mt={2}>
                      <FormLabel id="country">Country</FormLabel>
                      {countryLoading && <CircularProgress size={30} />}
                      {!countryLoading && (
                        <Field
                          name="country"
                          value={values.country}
                          select
                          component={TextField}
                          onChange={(e) => {
                            setFieldValue('country', e.target.value);
                          }}
                          sx={{ width: '95%' }}
                          label="Select Value"
                          defaultValue=""
                          required
                        >
                          <MenuItem value="">Not Selected</MenuItem>
                          {pageState?.countries?.map((item) => {
                            if (item.active == true)
                              return (
                                <MenuItem
                                  key={item._id}
                                  id={item._id}
                                  value={item.countrySlug}
                                  label={item.countryName}
                                >
                                  {item.countryName}
                                </MenuItem>
                              );
                          })}
                        </Field>
                      )}
                      {errors.country && touched.country ? (
                        <div className="error-color">{errors.country}</div>
                      ) : null}
                      <FormLabel id="service">Service Type</FormLabel>
                      {serviceLoading && <CircularProgress size={30} />}
                      {!serviceLoading && (
                        <Field
                          name="service"
                          value={contentState?.category?.service?.name}
                          select
                          defaultValue=""
                          component={TextField}
                          onChange={(e) => {
                            setFieldValue('service', e.target.value);
                            setServiceValue(e.target.value);
                          }}
                          sx={{ width: '95%' }}
                          label="Select Value"
                          disabled
                        >
                          <MenuItem value="">Not Selected</MenuItem>
                          {services?.map((item) => {
                            const dataValue = !values.service
                              ? contentState?.category?.service?.name
                              : item.name;

                            return (
                              <MenuItem
                                id={item._id}
                                key={item._id}
                                value={dataValue}
                                label={item.name}
                              >
                                {item.name}
                              </MenuItem>
                            );
                          })}
                        </Field>
                      )}
                      {errors.service && touched.service ? (
                        <div className="error-color">{errors.service}</div>
                      ) : null}
                      <p className="helper-text-style">Cannot Change Service Type.</p>
                      <FormLabel id="category">Category</FormLabel>
                      {serviceCategoryLoading && <CircularProgress size={30} />}
                      {!serviceCategoryLoading && (
                        <Field
                          name="category"
                          value={contentState?.category?._id}
                          select
                          defaultValue=""
                          component={TextField}
                          onChange={(e) => {
                            setFieldValue('category', e.target.value);
                          }}
                          sx={{ width: '95%' }}
                          label="Select Value"
                          disabled
                        >
                          <MenuItem value="">Not Selected</MenuItem>
                          {servicesCategory?.map((item) => {
                            return (
                              <MenuItem
                                id={item._id}
                                selected={item._id == id}
                                key={item._id}
                                value={item._id}
                                label={item.name}
                              >
                                {item.name}
                              </MenuItem>
                            );
                          })}
                        </Field>
                      )}
                      {errors.category && touched.category ? (
                        <div className="error-color">{errors.category}</div>
                      ) : null}
                      <p className="helper-text-style">Cannot Change Category.</p>
                      <FormLabel id="content.shortDescription">Short Description</FormLabel>
                      <RichTextEditor
                        name="content.shortDescription"
                        setFieldValue={setFieldValue}
                        uploadImg={'/uploads/'}
                        response="path"
                        value={values.content.shortDescription}
                      />
                      <div>
                        <Divider>
                          <Chip
                            label="Long Description"
                            color="primary"
                            variant="filled"
                            sx={{ mt: 2 }}
                          />
                        </Divider>
                        <FieldArray
                          name="content.longDescription"
                          render={({ push, remove, insert }) =>
                            values.content.longDescription?.length ? (
                              values.content.longDescription?.map((items, idx) => (
                                <Box my={2} key={idx} id={idx}>
                                  <Box display="flex" alignItems="center">
                                    <Typography variant="h5">Description {idx + 1}:</Typography>
                                    <MoreOptions faqs={values.content.longDescription}>
                                      <MenuItem
                                        onClick={() =>
                                          insert(idx + 1, {
                                            img: {
                                              src: '',
                                              width: 'auto',
                                              height: 'auto',
                                              position: 'left'
                                            },
                                            text: ''
                                          })
                                        }
                                      >
                                        <ListItemIcon>
                                          <Iconify icon="material-symbols:add" width={17} />
                                        </ListItemIcon>
                                        Insert Description Below
                                      </MenuItem>
                                      <MenuItem onClick={() => remove(idx)}>
                                        <ListItemIcon>
                                          <Iconify icon="ic:baseline-remove" width={17} />
                                        </ListItemIcon>
                                        Remove Description
                                      </MenuItem>
                                    </MoreOptions>
                                  </Box>
                                  <Grid container>
                                    <Grid item lg={6} md={6} sm={8} xs={8} mt={1}>
                                      <FormLabel id={`content.longDescription[${idx}].img.src`}>
                                        Upload Image
                                      </FormLabel>
                                      <SectionImg
                                        imgType=""
                                        setValue={setFieldValue}
                                        name={`content.longDescription[${idx}].img.src`}
                                      />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={6} lg={6} mt={2}>
                                      <FormLabel id="image">
                                        Preview (Current Image){' '}
                                        {items.img?.src && (
                                          <IconButton
                                            aria-label="delete"
                                            color="error"
                                            onClick={() => setImageDialogOpen(idx + items.img?.src)}
                                          >
                                            <Iconify icon="material-symbols:delete-sharp" />
                                          </IconButton>
                                        )}
                                      </FormLabel>
                                      <img
                                        src={`${items.img?.src?.length<300? process.env.REACT_APP_NODE_ROOT + "/uploads/images/categories/" + items.img?.src : items.img?.src}`}
                                        alt={pageState?.title}
                                        width="200"
                                        height="200"
                                      />
                                      <ImageDeleteDialog
                                        open={idx + items.img?.src == imageDialogOpen}
                                        setOpen={setImageDialogOpen}
                                        imageName={items.img?.src}
                                        
                                        content={values.content || {}}
                                        index={idx}
                                        setFieldValue={setFieldValue}
                                      />
                                    </Grid>
                                  </Grid>
                                  <Grid container>
                                    <Grid item lg={3} md={3} sm={3} xs={3} mt={1}>
                                      <FormLabel id={`content.longDescription[${idx}].img.alt`}>
                                        Image Alt Text
                                      </FormLabel>
                                      <Field
                                        type="text"
                                        defaultValue=""
                                        id={`content.longDescription[${idx}].img.alt`}
                                        name={`content.longDescription[${idx}].img.alt`}
                                        className="input-style"
                                        placeholder="Image alternate text"
                                        required
                                      />
                                    </Grid>
                                    <Grid item lg={3} md={3} sm={3} xs={3} mt={1}>
                                      <FormLabel id={`content.longDescription[${idx}].img.width`}>
                                        Image Width
                                      </FormLabel>
                                      <Field
                                        type="text"
                                        defaultValue=""
                                        id={`content.longDescription[${idx}].img.width`}
                                        name={`content.longDescription[${idx}].img.width`}
                                        className="input-style"
                                        placeholder="e.g: 300px"
                                        required
                                      />
                                    </Grid>
                                    <Grid item lg={3} md={3} sm={3} xs={3} mt={1}>
                                      <FormLabel id={`content.longDescription[${idx}].img.height`}>
                                        Image Height
                                      </FormLabel>
                                      <Field
                                        type="text"
                                        defaultValue=""
                                        id={`content.longDescription[${idx}].img.height`}
                                        name={`content.longDescription[${idx}].img.height`}
                                        className="input-style"
                                        placeholder="e.g: auto OR 300px"
                                        required
                                      />
                                    </Grid>
                                    <Grid item lg={3} md={3} sm={3} xs={3} mt={1}>
                                      <FormLabel
                                        id={`content.longDescription[${idx}].img.position`}
                                      >
                                        Image Position
                                      </FormLabel>
                                      <Field
                                        style={{ marginTop: '10px' }}
                                        name={`content.longDescription[${idx}].img.position`}
                                        value={items.img.position}
                                        select
                                        component={TextField}
                                        onChange={(e) => {
                                          setFieldValue(
                                            `content.longDescription[${idx}].img.position`,
                                            e.target.value
                                          );
                                        }}
                                        sx={{ width: '100%' }}
                                        label="Select Value"
                                        defaultValue=""
                                        required
                                      >
                                        <MenuItem value="">Not Selected</MenuItem>
                                        <MenuItem value="left">Left</MenuItem>
                                        <MenuItem value="right">Right</MenuItem>
                                      </Field>
                                    </Grid>
                                    <Grid item lg={12} md={12} sm={12} xs={12} mt={1}>
                                      <FormLabel id={`content.longDescription[${idx}].text`}>
                                        Enter Content
                                      </FormLabel>
                                      <RichTextEditor
                                        name={`content.longDescription[${idx}].text`}
                                        setFieldValue={setFieldValue}
                                        uploadImg={'/uploads/'}
                                        response="path"
                                        value={items.text}
                                      />
                                    </Grid>
                                  </Grid>
                                </Box>
                              ))
                            ) : (
                              <Button
                                type="button"
                                variant="contained"
                                color="secondary"
                                sx={{ my: 2 }}
                                onClick={() =>
                                  push({
                                    img: {
                                      src: '',
                                      width: 'auto',
                                      height: 'auto',
                                      position: 'left'
                                    },
                                    text: ''
                                  })
                                }
                              >
                                Add new Description
                              </Button>
                            )
                          }
                        />
                      </div>
                      {/* FAQ's for the Service */}
                      <div>
                        <Divider>
                          <Chip
                            label="Frequently Asked Questions"
                            color="primary"
                            variant="filled"
                            sx={{ my: 2 }}
                          />
                        </Divider>
                        <FieldArray
                          name="content.faqs"
                          render={({ push, insert, remove }) => (
                            <div className="row">
                              {values.content.faqs?.length > 0 ? (
                                values.content.faqs?.map((items, idx) => (
                                  <Box key={idx}>
                                    <Box display="flex" alignItems="center">
                                      <Typography variant="h5">Question {idx + 1}:</Typography>
                                      <MoreOptions faqs={values.content.faqs}>
                                        <MenuItem
                                          onClick={() =>
                                            insert(idx + 1, {
                                              title: '',
                                              description: ''
                                            })
                                          }
                                        >
                                          <ListItemIcon>
                                            <Iconify icon="material-symbols:add" width={17} />
                                          </ListItemIcon>
                                          Insert Below
                                        </MenuItem>
                                        <MenuItem onClick={() => remove(idx)}>
                                          <ListItemIcon>
                                            <Iconify icon="ic:baseline-remove" width={17} />
                                          </ListItemIcon>
                                          Remove
                                        </MenuItem>
                                      </MoreOptions>
                                    </Box>
                                    <div className="input-section">
                                      <FormLabel htmlFor={`content.faqs[${idx}].title`}>
                                        Question
                                      </FormLabel>
                                      <Field
                                        defaultValue=""
                                        id={`content.faqs[${idx}].title`}
                                        name={`content.faqs[${idx}].title`}
                                        type="text"
                                        placeholder="Frequently Asked Questions"
                                        className="input-style"
                                      />
                                      <ErrorMessage
                                        name={`content.faqs[${idx}].title`}
                                        component="div"
                                        className="error-color"
                                      />
                                    </div>
                                    <div className="input-section">
                                      <FormLabel htmlFor={`content.faqs[${idx}].description`}>
                                        Answer
                                      </FormLabel>
                                      <RichTextEditor
                                        setFieldValue={setFieldValue}
                                        uploadImg={'/uploads/'}
                                        response="path"
                                        name={`content.faqs[${idx}].description`}
                                        value={items.description}
                                      />
                                      <ErrorMessage
                                        name={`faqs[${idx}].description`}
                                        component="div"
                                        className="error-color"
                                      />
                                    </div>
                                  </Box>
                                ))
                              ) : (
                                <Button
                                  type="button"
                                  variant="contained"
                                  color="secondary"
                                  sx={{ my: 2 }}
                                  onClick={() =>
                                    push({
                                      title: '',
                                      description: ''
                                    })
                                  }
                                >
                                  Add new
                                </Button>
                              )}
                            </div>
                          )}
                        />
                      </div>
                      <FormLabel id="active">Status</FormLabel>
                      <Grid container>
                        <Grid item lg={5} md={5} xs={10} sm={10}>
                          <FormControlLabel
                            control={<Field component={Switch} type="checkbox" name="active" />}
                            label={values.active === true ? 'Active' : 'In-Active'}
                          />
                        </Grid>
                      </Grid>
                    </Stack>
                    <LoadingButton
                      type="submit"
                      variant="contained"
                      size="large"
                      sx={{ mt: 2 }}
                      loading={loading}
                    >
                      Submit
                    </LoadingButton>

                    <CustomButton
                      btnText="Cancel"
                      variant="contained"
                      color="error"
                      link="/dashboard/service-content"
                      size="large"
                      style={{ mt: 2, ml: 1 }}
                    />
                  </Form>
                )}
              </Formik>
            </CardContent>
          </Card>
        )}
      </Grid>
    </Grid>
  );
}

export function MoreOptions(props) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  React.useMemo(() => {
    setAnchorEl(null);

    return () => setAnchorEl(null);
  }, [props.faqs]);

  return (
    <div>
      <IconButton
        aria-label="more"
        id="long-button"
        aria-controls={open ? 'long-menu' : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup="true"
        onClick={handleClick}
      >
        <Iconify icon="ic:round-more-horiz" />
      </IconButton>
      <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
        {props.children}
      </Menu>
    </div>
  );
}
