import React from 'react';
// import { useDispatch } from 'react-redux';
import { sentenceCase } from 'change-case';
//
import Box from '@mui/material/Box';
import { Typography, Stack, CardContent, Card, Grid, Button } from '@mui/material';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
//
import Label from 'src/components/Label';
import ActionButtonFooter from './ActionButton';
import { Link } from 'react-router-dom';
//
import { deleteFooterMenu } from 'src/Redux/actions/footerActions';

export default function MenuTable(props) {
  const { menus } = props;
  return (
    <TableContainer
      component={Paper}
      sx={{
        boxShadow: 5
      }}
    >
      <Table aria-label="collapsible table">
        {menus ? (
          <TableHead>
            <TableRow key={menus.footer.countrySlug}>
              <TableCell sx={{ fontWeight: '700' }}>Menus</TableCell>
              <TableCell sx={{ fontWeight: '700' }}>Country</TableCell>
              <TableCell sx={{ fontWeight: '700' }}>Heading 1</TableCell>
              <TableCell sx={{ fontWeight: '700' }}>Heading 2</TableCell>
              <TableCell sx={{ fontWeight: '700' }}>Copy Right</TableCell>
              <TableCell sx={{ fontWeight: '700' }}>Action</TableCell>
            </TableRow>
          </TableHead>
        ) : (
          <Stack sx={{ m: '20px 0', textAlign: 'center' }}>
            <Typography>NO DATA</Typography>
          </Stack>
        )}
        <TableBody>
          <Row row={menus} />
        </TableBody>
      </Table>
    </TableContainer>
  );
}

function Row(props) {
  const { row } = props;

  const [open, setOpen] = React.useState(true);
  // Footer Cols
  const servicesColumn = row.footer?.servicesColumn;
  const policiesColumn = row.footer?.policiesColumn;
  const contactColumn = row.footer?.contactColumn;
  const innerCardStyle = { boxShadow: 3, borderRadius: 2, border: '1px solid #929eaa6b' };

  return (
    <React.Fragment>
      {Object.keys(row.footer)?.length ? (
        <TableRow sx={{ '& > *': { borderBottom: 'unset' } }} key={row.footer.countrySlug}>
          <TableCell>
            <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </TableCell>
          <TableCell>{row?.countryName}</TableCell>
          <TableCell>{`${row.footer.ownershipText?.substring(0, 10)}...`}</TableCell>
          <TableCell>{row.footer.servicesColumn?.columnTitle}</TableCell>
          <TableCell>{`${row.footer.copyrightBar?.copyrightText?.substring(0, 10)}...`}</TableCell>
          <TableCell>
            <Link
              to={`/dashboard/footer-content/edit-footer-content/${row.countrySlug}`}
              className="anc-dec"
            >
              <Button size="small" variant="contained">
                Edit
              </Button>
            </Link>
            <ActionButtonFooter
              id={row.countrySlug}
              name="Footer Content"
              action={deleteFooterMenu}
              link={`/dashboard/footer-content/edit-footer-content/${row.countrySlug}`}
              style={{ ml: 0.5 }}
            />
          </TableCell>
        </TableRow>
      ) : (
        ''
      )}

      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={12}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            {Object.keys(row?.footer)?.length ? (
              <Box sx={{ m: 1 }} key={row.footer.countrySlug}>
                <Typography mt={2} sx={{ textAlign: 'center' }} variant="h5">
                  Footer Content
                </Typography>
                <Grid
                  container
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  spacing={1}
                >
                  <Grid item lg={6} md={6} xs={10} sm={10} mt={2}>
                    <Box sx={innerCardStyle}>
                      <Card sx={{ maxWidth: '100%' }}>
                        <CardContent>
                          <Label variant="ghost" color={'success'}>
                            {sentenceCase('Ownership Column')}
                          </Label>
                          <Typography variant="body2" mt={1} mb={2} sx={{ fontSize: 16 }}>
                            <strong>Ownership Text: </strong>
                            {`${row.footer.ownershipText?.substring(0, 35)}...`}
                          </Typography>
                          <Typography variant="body2" mt={1} mb={2} sx={{ fontSize: 16 }}>
                            <strong>Link: </strong>
                            <a href={row.footer.instagramLink} target="_blank" className="anc-dec">
                              Instagram
                            </a>
                          </Typography>
                          <Typography variant="body2" mt={1} mb={2} sx={{ fontSize: 16 }}>
                            <strong> Link: </strong>
                            <a href={row.footer.facebookLink} target="_blank" className="anc-dec">
                              Facebook
                            </a>
                          </Typography>
                          <Typography variant="body2" mt={1} mb={2} sx={{ fontSize: 16 }}>
                            <strong> Link: </strong>
                            <a href={row.footer.twitterLink} target="_blank" className="anc-dec">
                              Twitter
                            </a>
                          </Typography>
                          <Typography variant="body2" mt={1} sx={{ fontSize: 16, mb: '5px' }}>
                            <strong> Link: </strong>
                            <a href={row.footer.linkedinLink} target="_blank" className="anc-dec">
                              Linkedin
                            </a>
                          </Typography>
                        </CardContent>
                      </Card>
                    </Box>
                  </Grid>
                  <Grid item lg={6} md={6} xs={10} sm={10} mt={2}>
                    <Box sx={innerCardStyle}>
                      <Card sx={{ maxWidth: '100%' }}>
                        <CardContent>
                          <Label variant="ghost" color={'error'}>
                            {sentenceCase(servicesColumn?.columnTitle)}
                          </Label>
                          <Typography variant="h6" mt={2} mb={1} sx={{ fontSize: 16 }}>
                            Column Text with Links:
                          </Typography>
                          {servicesColumn.columnLinks?.map((item, idx) => (
                            <Typography
                              key={item.columnLink + idx}
                              variant="body2"
                              mt={1}
                              mb={1}
                              sx={{ fontSize: 16 }}
                            >
                              <strong>Order: {item.order} - </strong>
                              <a href={item.columnLink} className="anc-dec">
                                {item.columnLinkText}
                              </a>
                            </Typography>
                          ))}
                        </CardContent>
                      </Card>
                    </Box>
                  </Grid>
                  <Grid item lg={6} md={6} xs={10} sm={10} mt={1}>
                    <Box sx={innerCardStyle}>
                      <Card sx={{ maxWidth: '100%' }}>
                        <CardContent>
                          <Label variant="ghost" color={'warning'}>
                            {sentenceCase(policiesColumn.columnTitle)}
                          </Label>
                          <Typography variant="h6" mt={2} mb={2} sx={{ fontSize: 16 }}>
                            Column Text with Links:
                          </Typography>
                          {policiesColumn.columnLinks?.map((item, idx) => (
                            <Typography
                              key={item.columnLink + idx}
                              variant="body2"
                              mt={1}
                              mb={1}
                              sx={{ fontSize: 16 }}
                            >
                              <strong>Order: {item.order} - </strong>
                              <a href={item.columnLink} className="anc-dec">
                                {item.columnLinkText}
                              </a>
                            </Typography>
                          ))}
                        </CardContent>
                      </Card>
                    </Box>
                  </Grid>
                  <Grid item lg={6} md={6} xs={10} sm={10} mt={1}>
                    <Box sx={innerCardStyle}>
                      <Card sx={{ maxWidth: '100%' }}>
                        <CardContent>
                          <Label variant="ghost" color={'info'}>
                            {sentenceCase(contactColumn.columnTitle)}
                          </Label>
                          <Typography variant="h6" mt={2} mb={2} sx={{ fontSize: 16 }}>
                            Column Text with Links:
                          </Typography>
                          {contactColumn.columnLinks?.map((item, idx) => (
                            <Typography
                              key={item.columnLink + idx}
                              variant="body2"
                              mt={1}
                              mb={1}
                              sx={{ fontSize: 16 }}
                            >
                              <strong>Order: {item.order} - </strong>
                              <a href={item.columnLink} className="anc-dec">
                                {`${item.columnLinkText.substring(0, 36)}...`}
                              </a>
                            </Typography>
                          ))}
                        </CardContent>
                      </Card>
                    </Box>
                  </Grid>
                  <Grid item lg={6} md={6} xs={10} sm={10} mt={1} mb={2}>
                    <Box sx={innerCardStyle}>
                      <Card sx={{ maxWidth: '100%' }}>
                        <CardContent>
                          <Label variant="ghost" color={'success'}>
                            {sentenceCase('Copyright Text')}
                          </Label>
                          <Typography variant="h6" mt={2} mb={2} sx={{ fontSize: 16 }}>
                            Column Text with Links:
                          </Typography>

                          <Typography variant="body2" mt={1} mb={1} sx={{ fontSize: 16 }}>
                            <strong>Copyrights Text: </strong>
                            {`${row.footer.copyrightBar.copyrightText.substring(0, 50)}...`}
                          </Typography>
                          <Typography variant="body2" mt={1} mb={1} sx={{ fontSize: 16 }}>
                            <strong>Copyrights Text 2: </strong>
                            {`${row.footer.copyrightBar.copyrightText2.substring(0, 50)}...`}
                          </Typography>
                          <Typography variant="body2" mt={1} mb={1} sx={{ fontSize: 16 }}>
                            <strong>Sitemap: </strong>
                            <a href={row.footer.copyrightBar.siteMapLinkText} className="anc-dec">
                              Link
                            </a>
                          </Typography>
                        </CardContent>
                      </Card>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            ) : (
              <Typography mt={2} ml={2} mb={2} variant="h5" align="center">
                No Page Content
              </Typography>
            )}
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}
