import { MenuItem, FormLabel } from '@mui/material';
import { Field } from 'formik';
import { TextField } from 'formik-mui';

const AllCountriesSelect = (props) => {
  return (
    <div className="input-section">
      <FormLabel id="country">Select Country {props.requiredField}</FormLabel>
      <br />
      <Field
        name={props?.name}
        value={props?.value}
        select
        defaultValue=""
        // onChange={props.change}
        component={TextField}
        sx={{ width: '95%', marginTop: '6px' }}
        label="Select Country"
        required
      >
        <MenuItem value="">Not Selected</MenuItem>
        {props.countries?.map((item, index) => {
          // if (item.active == true)
          return (
            <MenuItem key={index} value={item._id} label={item.countryName}>
              {item.countryName}
            </MenuItem>
          );
        })}
      </Field>
    </div>
  );
};
export default AllCountriesSelect;
