import React, { useState } from 'react';
import moment from 'moment';
// MUI Components
import { Container, Stack, Typography } from '@mui/material';
// Custom Components
import Page from 'src/components/Page';
// import Loader from 'src/components/Loader';
import CustomButton from 'src/components/Button';
import ActionButton from 'src/components/ActionButton';
import { deleteService } from 'src/Redux/actions/packageServiceActions';
import { getServicesWithCategories } from 'src/api/api';
import ServicesTable from './components/ServicesTable';
import Loader from 'src/components/Loader';

function AllServices() {
  // Fetch Data from API
  // Searchable Filters
  const [loading, setLoading] = React.useState(false);
  const [rows, setRows] = useState([]);

  // Get All Services
  React.useEffect(() => {
    setLoading(true);
    const promise = getServicesWithCategories();
    Promise.resolve(promise).then((data) => {
      if (data.success) {
        const services = data?.services;
        if (services && services?.length) {
          setRows(data?.services);
        }
        setLoading(false);
      } else {
        setRows([]);
        setLoading(false);
      }
    });
  }, []);

  return (
    <Page title="Dashboard Pages | Package Services">
      <Container>
        <Stack direction="row" justifyContent="space-between" mb={3}>
          <Typography variant="h4" gutterBottom>
            All Services
          </Typography>
          <CustomButton
            btnText="Add Service"
            icon="ic:round-create"
            variant="contained"
            color="info"
            link="/dashboard/services/add-service"
          />
        </Stack>
        {loading ? <Loader /> : <ServicesTable data={rows} />}
      </Container>
    </Page>
  );
}

export default AllServices;
