import * as React from 'react';
import { useDispatch } from 'react-redux';
//
import { styled, alpha } from '@mui/material/styles';
import {
  Button,
  Menu,
  MenuItem,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from '@mui/material';
// MUI ICONS
import EditIcon from '@mui/icons-material/Edit';
import LocalMallIcon from '@mui/icons-material/LocalMall';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import EditOrderForm from './EditOrderForm';
import { placeOrder } from 'src/Redux/actions/ordersActions';

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right'
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right'
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color: theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '4px 0'
    },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5)
      },
      '&:active': {
        backgroundColor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity)
      }
    }
  }
}));

export default function OrderActionButton(props) {
  const { orderData, items, item } = props;
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const [modalOpen, setModalOpen] = React.useState(false);
  const [orderModal, setOrderModal] = React.useState(false);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleClose = () => {
    setModalOpen(false);
  };

  const handleClickOpen = () => {
    setModalOpen(true);
  };
  const handleOpenOrder = () => {
    setOrderModal(true);
  };
  const handleCloseOrder = () => {
    setOrderModal(false);
  };
  return (
    <div>
      <Button
        id="demo-customized-button"
        aria-controls={open ? 'demo-customized-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        variant="contained"
        disableElevation
        onClick={handleClick}
        endIcon={<KeyboardArrowDownIcon />}
      >
        Action
      </Button>
      <StyledMenu
        id="demo-customized-menu"
        MenuListProps={{
          'aria-labelledby': 'demo-customized-button'
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleMenuClose}
      >
        <MenuItem disableRipple onClick={handleClickOpen}>
          <EditIcon />
          Edit
        </MenuItem>
        <MenuItem onClick={handleOpenOrder} disableRipple>
          <LocalMallIcon />
          Place Order
        </MenuItem>

        {/* Edit Order Item */}
        <Dialog
          open={modalOpen}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">Edit Post Details</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <EditOrderForm
                orderData={orderData}
                item={props?.item}
                orderItem={items}
                handleClose={handleClose}
                urlId={props.urlId}
              />
            </DialogContentText>
          </DialogContent>
        </Dialog>
        {/*  */}
        <Dialog
          open={orderModal}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <PlaceOrderDialog
            handleCloseOrder={handleCloseOrder}
            orderData={orderData}
            items={items}
            item={item}
            urlId={props?.urlId}
          />
        </Dialog>
      </StyledMenu>
    </div>
  );
}

const PlaceOrderDialog = (props) => {
  const dispatch = useDispatch();
  const { orderData, items, item } = props;

  // Prepare data for Place Order
  const placeOrderData = {
    userEmail: orderData.userEmail,
    orderItems: [{ ...items }]
  };
  placeOrderData.orderItems[0].orderItemPosts = [{ ...item }];

  const handlePlaceOrder = () => {
    // console.log('Data', placeOrderData);
    dispatch(placeOrder(placeOrderData, props?.urlId));
  };
  return (
    <>
      <DialogTitle id="alert-dialog-title">Place Order</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Are you sure you want to Place Order?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handlePlaceOrder}>YES</Button>
        <Button onClick={props.handleCloseOrder} autoFocus>
          NO
        </Button>
      </DialogActions>
    </>
  );
};
