import { TextField } from 'formik-mui';
import { Field } from 'formik';
//
import { MenuItem } from '@mui/material';

const CategorySelect = (props) => {
  return (
    <Field
      name={props?.name}
      value={props?.value}
      select
      component={TextField}
      sx={{ width: '60%' }}
      label="Select Value"
    >
      <MenuItem value="">Not Selected</MenuItem>
      {props.categories?.map((item) => {
        return (
          <MenuItem value={item._id} label={item.title} key={item._id}>
            {item.title}
          </MenuItem>
        );
      })}
    </Field>
  );
};
export default CategorySelect;
