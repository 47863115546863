import { toast } from 'react-toastify';
import * as types from '../constants/actionTypes';
import { request } from '../helpers/axiosRequest';
import { logUserActivity } from './activityLogsActions';

// Add Menu
export const addMenu = (data, navigate, setLoading) => async (dispatch) => {
  try {
    dispatch({ type: types.ADD_MENU_ATTEMPT });
    const res = await request({
      url: `${process.env.REACT_APP_API_URL}/menu/add-menu`,
      auth: true,
      method: 'POST',
      data
    });
    toast.success('Menu Added Successsully', {
      position: 'top-center',
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      draggable: true,
      progress: undefined
    });
    dispatch({
      type: types.ADD_MENU_SUCCESS,
      payload: { ...data }
    });
    dispatch(logUserActivity(`ADDED_MENU`));
    setTimeout(() => {
      navigate('/dashboard/menus');
    }, 1000);
  } catch (err) {
    dispatch({ type: types.ADD_MENU_FAIL, payload: err });
    toast.error('Unable to add Menu.', {
      position: 'top-center',
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      draggable: true,
      progress: undefined
    });
    setLoading(false);
    throw err;
  }
};

// Get All Menus
export const allMenus = () => async (dispatch) => {
  dispatch({ type: types.GET_MENUS_ATTEMPT });
  try {
    const res = await request({
      url: `${process.env.REACT_APP_API_URL}/menu/get-all-menus`,
      auth: true,
      method: 'POST'
    });
    dispatch({
      type: types.GET_MENUS_SUCCESS,
      payload: res.data
    });
  } catch (err) {
    dispatch({ type: types.GET_MENUS_FAIL, payload: err });
    throw err;
  }
};

// Get All Menus
export const getCountryMenus = (slug) => async (dispatch) => {
  dispatch({ type: types.GET_COUNTRY_MENUS_ATTEMPT });
  try {
    const res = await request({
      url: `${process.env.REACT_APP_API_URL}/menu/get-all-by-country`,
      auth: true,
      method: 'POST',
      data: { country: slug }
    });
    dispatch({
      type: types.GET_COUNTRY_MENUS_SUCCESS,
      payload: res.data
    });
  } catch (err) {
    dispatch({ type: types.GET_COUNTRY_MENUS_FAIL, payload: err });
    throw err;
  }
};

// Get Single Menu
export const singleMenu = (id) => async (dispatch) => {
  dispatch({ type: types.SINGLE_MENU_ATTEMPT });
  try {
    const res = await request({
      url: `${process.env.REACT_APP_API_URL}/menu/get-menu`,
      auth: true,
      method: 'POST',
      data: { id: id }
    });
    dispatch({
      type: types.SINGLE_MENU_SUCCESS,
      payload: res.data
    });
  } catch (err) {
    dispatch({ type: types.SINGLE_MENU_FAIL, payload: err });
    throw err;
  }
};

export const getSubMenu = (id) => async (dispatch) => {
  dispatch({ type: types.SINGLE_MENU_ATTEMPT });
  try {
    const res = await request({
      url: `${process.env.REACT_APP_API_URL}/menu/get-sub-menu`,
      auth: true,
      method: 'POST',
      data: { parent: id }
    });
    dispatch({
      type: types.SET_SUB_MENU,
      payload: res.data.subMenus
    });
  } catch (err) {
    throw err;
  }
};

// Update Menu
export const updateMenu = (data, navigate) => async (dispatch) => {
  try {
    dispatch({ type: types.UPDATE_MENU_ATTEMPT });
    const res = await request({
      url: `${process.env.REACT_APP_API_URL}/menu/update-menu`,
      auth: true,
      method: 'PUT',
      data
    });
    toast.success('Menu Updated Successsully', {
      position: 'top-center',
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      draggable: true,
      progress: undefined
    });
    dispatch({
      type: types.UPDATE_MENU_SUCCESS,
      payload: { ...data }
    });
    dispatch(logUserActivity(`UPDATED_MENU`));
    setTimeout(() => {
      navigate('/dashboard/menus');
    }, 1000);
  } catch (err) {
    dispatch({ type: types.UPDATE_MENU_FAIL, payload: err });
    throw err;
  }
};

// Delete Menu
export const deleteMenu = (id) => async (dispatch) => {
  try {
    dispatch({ type: types.DELETE_MENU_ATTEMPT });
    const res = await request({
      method: 'DELETE',
      auth: true,
      url: `${process.env.REACT_APP_API_URL}/menu/delete-menu`,
      data: { id: id }
    });

    toast.success('Menu Deleted Successsully', {
      position: 'top-center',
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      draggable: true,
      progress: undefined
    });
    dispatch(logUserActivity(`DELETED_MENU`));
    setTimeout(() => {
      window.location.href = '/dashboard/menus';
    }, 2000);

    dispatch({ type: types.DELETE_MENU_SUCCESS, payload: {} });
  } catch (err) {
    dispatch({ type: types.DELETE_MENU_FAIL, payload: err });
    throw new Error(err);
  }
};

// Delete Menu
export const deleteSubMenu = (id) => async (dispatch) => {
  try {
    dispatch({ type: types.DELETE_MENU_ATTEMPT });
    const res = await request({
      method: 'DELETE',
      auth: true,
      url: `${process.env.REACT_APP_API_URL}/menu/delete-menu`,
      data: { id: id }
    });

    toast.success(res.data.message, {
      position: 'top-center',
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      draggable: true,
      progress: undefined
    });
    dispatch(logUserActivity(`DELETED_SUBMENU`));
  } catch (err) {
    throw new Error(err);
  }
};
