import { sentenceCase } from 'change-case';
// material
import { Stack, Container, Typography, Grid } from '@mui/material';
// Section Card
import Box from '@mui/material/Box';
import Label from 'src/components/Label';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Chip from '@mui/material/Chip';
import ContentPasteIcon from '@mui/icons-material/ContentPaste';
//
import SectionImg from 'src/assets/images/section-img.jpg';
import ActionButton from 'src/components/ActionButton';
import { deletePage } from 'src/Redux/actions/pageSectionActions';

export default function SectionContent(props) {
  const pageData = props.data?.page;
  const cardStyle = { boxShadow: 1, borderRadius: '8px', border: '1px solid #929eaa6b' };
  const boxDisplay = {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between'
  };
  return (
    <Container sx={{ boxShadow: 1, borderRadius: 1, border: '1px solid #929eaa6b' }}>
      <Stack sx={boxDisplay} pt={3} mb={3}>
        <Typography variant="h6" gutterBottom>
          Section Details
        </Typography>
        {Object.values(pageData)?.length ? (
          <ActionButton
            link={`/dashboard/home-page-content/edit-page/${props.data?.countrySlug}`}
            name="Home Page"
            id={pageData?._id}
            action={deletePage}
          />
        ) : (
          ''
        )}
      </Stack>
      {Object.values(pageData)?.length ? (
        <Box sx={{ m: 1 }} key={props?.index}>
          <Stack sx={boxDisplay}>
            <Chip
              color="error"
              variant="outlined"
              label={pageData?.pageName ? pageData.pageName : 'Page Name'}
              deleteIcon={<ContentPasteIcon />}
            />
            <Chip
              color="success"
              variant="outlined"
              label={props.data?.countryName}
              deleteIcon={<ContentPasteIcon />}
            />
          </Stack>
          <Grid container display="flex" alignItems="center" justifyContent="center" spacing={1}>
            <Grid item lg={6} md={6} xs={10} sm={10} mt={2}>
              <Box sx={cardStyle}>
                <CardContent>
                  <Label variant="ghost" color={'success'}>
                    {sentenceCase('Page Meta Data')}
                  </Label>
                  <Typography variant="body2" mt={2} mb={2} sx={{ fontSize: 16 }}>
                    <strong> Page Name: </strong>
                    {pageData?.pageName}
                  </Typography>
                  <Typography variant="body2" mt={1} mb={2} sx={{ fontSize: 16 }}>
                    <strong> Page Slug: </strong>
                    {pageData?.pageSlug}
                  </Typography>

                  <Typography variant="body2" mt={1} mb={2} sx={{ fontSize: 16 }}>
                    <strong>Meta Title: </strong>
                    {`${pageData?.metaTitle?.substring(0, 20)}...`}
                  </Typography>
                  <Typography variant="body2" mt={1} mb={2} sx={{ fontSize: 16 }}>
                    <strong>Meta Description: </strong>
                    {`${pageData?.metaDescription?.substring(0, 20)}...`}
                  </Typography>
                  <Typography variant="body2" mt={1} mb={2} sx={{ fontSize: 16 }}>
                    <strong> Canonical Link: </strong>
                    {`${pageData?.canonicalLink?.substring(0, 20)}...`}
                  </Typography>
                </CardContent>
              </Box>
            </Grid>
            <Grid item lg={6} md={6} xs={10} sm={10} mt={2}>
              <Box sx={cardStyle}>
                <CardContent>
                  <CardMedia
                    component="img"
                    height="117"
                    image={
                      pageData.heroSection?.heroImg
                        ? `${process.env.REACT_APP_NODE_ROOT}/uploads/images/${pageData.heroSection?.heroImg}`
                        : SectionImg
                    }
                    alt="Title"
                  />
                  <Label variant="ghost" color={'error'}>
                    {sentenceCase('Hero Section')}
                  </Label>
                  <Typography variant="body2" mt={1} mb={1} sx={{ fontSize: 16 }}>
                    <strong>1st Heading: </strong>
                    {`${pageData.heroSection?.heading1.substring(0, 27)}...`}
                  </Typography>
                  <Typography variant="body2" mt={1} mb={1} sx={{ fontSize: 16 }}>
                    <strong>2nd Heading: </strong>
                    {`${pageData.heroSection.heading2?.substring(0, 27)}...`}
                  </Typography>
                  <Typography variant="body2" mt={1} mb={1} sx={{ fontSize: 16 }}>
                    <strong>3rd Heading: </strong>
                    {`${pageData.heroSection.heading3?.substring(0, 27)}...`}
                  </Typography>
                </CardContent>
              </Box>
            </Grid>

            <Grid item lg={12} md={12} xs={12} sm={12} mt={1}>
              <Box>
                <CardContent>
                  <Grid item lg={4} md={6} xs={8} sm={8} m="auto">
                    <Box sx={cardStyle} p={2}>
                      <Label variant="ghost" color={'warning'}>
                        {sentenceCase(pageData.smGrowth.sectionName)}
                      </Label>
                      <Typography variant="body2" mt={2} mb={2} sx={{ fontSize: 16 }}>
                        <strong> SM Heading:</strong>
                        {`${pageData.smGrowth.smHeading?.substring(0, 15)}...`}
                      </Typography>
                      <Typography variant="body2" mt={2} mb={2} sx={{ fontSize: 16 }}>
                        <strong> SM Description 1:</strong>{' '}
                        {`${pageData.smGrowth.smDescription1?.substring(0, 20)}...`}
                      </Typography>
                      <Typography variant="body2" mt={2} mb={2} sx={{ fontSize: 16 }}>
                        <strong> SM Description 2:</strong>{' '}
                        {`${pageData.smGrowth.smDescription2?.substring(0, 23)}...`}
                      </Typography>
                    </Box>
                  </Grid>
                  {/* SM Section */}
                  <Grid container mt={2}>
                    {pageData.smGrowth.smSection.data?.map((item, idx) => {
                      return (
                        <Grid
                          item
                          lg={4}
                          md={6}
                          xs={10}
                          sm={10}
                          p={0.5}
                          m="auto"
                          key={idx}
                          id={idx}
                        >
                          <Box sx={cardStyle}>
                            <CardContent>
                              <Label variant="ghost" color={'warning'}>
                                {sentenceCase('SM Column ' + (idx + 1))}
                              </Label>
                              <Typography variant="body2" mt={2} mb={2} sx={{ fontSize: 16 }}>
                                <strong> Heading:</strong>
                                {`${item.heading?.substring(0, 20)}...`}
                              </Typography>
                              <Typography variant="body2" mt={2} mb={2} sx={{ fontSize: 16 }}>
                                <strong> Description:</strong>
                                {`${item.description?.substring(0, 20)}...`}
                              </Typography>
                              <Typography variant="body2" mt={2} mb={2} sx={{ fontSize: 16 }}>
                                <strong> Link:</strong>
                                {`${item.btnLink?.substring(0, 20)}...`}
                              </Typography>
                            </CardContent>
                          </Box>
                        </Grid>
                      );
                    })}
                  </Grid>
                  <Grid container mt={2}>
                    <Grid item lg={4} md={6} xs={10} sm={10} p={0.5} m="auto">
                      <Box sx={cardStyle}>
                        <CardContent>
                          <Label variant="ghost" color={'success'}>
                            {sentenceCase('Values Column')}
                          </Label>
                          {pageData.valuesSection.data?.map((item, idx) => {
                            return (
                              <Typography
                                variant="body2"
                                mt={2}
                                mb={2}
                                sx={{ fontSize: 16 }}
                                key={idx}
                                id={idx}
                              >
                                <strong> Heading {idx + 1}:</strong>
                                {`${item.valuesHeading?.substring(0, 12)}...`}
                              </Typography>
                            );
                          })}
                        </CardContent>
                      </Box>
                    </Grid>
                  </Grid>
                  <Grid container mt={2}>
                    {pageData.buySection.data?.map((item, idx) => {
                      return (
                        <Grid
                          item
                          lg={6}
                          md={6}
                          xs={10}
                          sm={10}
                          p={0.5}
                          m="auto"
                          key={idx}
                          id={idx}
                        >
                          <Box sx={cardStyle}>
                            <CardContent>
                              <CardMedia
                                component="img"
                                height="120"
                                image={
                                  item?.buyImg
                                    ? `${process.env.REACT_APP_NODE_ROOT}/uploads/images/${item?.buyImg}`
                                    : SectionImg
                                }
                                alt="Title"
                              />
                              <Label variant="ghost" color={'error'}>
                                {sentenceCase('Buy Column ' + (idx + 1))}
                              </Label>
                              <Typography variant="body2" mt={2} mb={2} sx={{ fontSize: 16 }}>
                                <strong> Heading:</strong> {item.heading}
                              </Typography>
                              <Typography variant="body2" mt={2} mb={2} sx={{ fontSize: 16 }}>
                                <strong> Description:</strong> {item.description}
                              </Typography>
                              <Typography variant="body2" mt={2} mb={2} sx={{ fontSize: 16 }}>
                                <strong> Link:</strong> {item.btnLink}
                              </Typography>
                            </CardContent>
                          </Box>
                        </Grid>
                      );
                    })}
                  </Grid>
                </CardContent>
              </Box>
            </Grid>
          </Grid>
        </Box>
      ) : (
        <Typography mt={3} ml={2} mb={5} variant="h5" align="center">
          No Page Content
        </Typography>
      )}
    </Container>
  );
}
