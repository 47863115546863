import { toast } from 'react-toastify';
import axios from 'axios';
import * as types from '../constants/actionTypes';
import { request } from '../helpers/axiosRequest';
import { logUserActivity } from './activityLogsActions';

export const API = axios.create({ baseURL: process.env.REACT_APP_API_URL });

// Page Actions
// Create Page Section
export const addPageSection = (data, navigate) => async (dispatch) => {
  try {
    dispatch({ type: types.ADD_PAGE_SECTION_ATTEMPT });
    const res = await request({
      url: `${process.env.REACT_APP_API_URL}/section/add-page-section`,
      auth: true,
      method: 'POST',
      data
    });
    toast.success('Section Added Successsully');
    dispatch({
      type: types.ADD_PAGE_SECTION_SUCCESS,
      payload: { ...data }
    });
    dispatch(logUserActivity(`ADDED_PAGE_SECTION`));
    setTimeout(() => {
      navigate('/dashboard/why-us');
    }, 2000);
  } catch (error) {
    dispatch({ type: types.ADD_PAGE_SECTION_FAIL, payload: error });
    toast.error('Unable to add Section', {
      position: 'top-center',
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      draggable: true,
      progress: undefined
    });
    throw error;
  }
};

// Create Page
export const addPage = (data, navigate) => async (dispatch) => {
  try {
    dispatch({ type: types.ADD_PAGE_ATTEMPT });
    const res = await request({
      url: `${process.env.REACT_APP_API_URL}/page/add-page`,
      auth: true,
      method: 'POST',
      data
    });
    toast.success('Page Added Successsully', {
      position: 'top-center',
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      draggable: true,
      progress: undefined
    });
    dispatch({
      type: types.ADD_PAGE_SUCCESS,
      payload: { ...data }
    });
    dispatch(logUserActivity(`ADDED_PAGE`));
    setTimeout(() => {
      navigate('/dashboard/home-page-content');
    }, 2000);
  } catch (error) {
    dispatch({ type: types.ADD_PAGE_FAIL, payload: error });
    toast.error('Unable to add Page', {
      position: 'top-center',
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      draggable: true,
      progress: undefined
    });
    throw error;
  }
};

// Get All Page Menus
export const allPageContent = () => async (dispatch) => {
  dispatch({ type: types.GET_PAGE_CONTENT_ATTEMPT });
  try {
    const res = await request({
      url: `${process.env.REACT_APP_API_URL}/page/get-all-content`,
      auth: true,
      method: 'POST'
    });
    dispatch({
      type: types.GET_PAGE_CONTENT_SUCCESS,
      payload: res.data
    });
  } catch (err) {
    dispatch({ type: types.GET_PAGE_CONTENT_FAIL, payload: err });
    throw err;
  }
};

// Update Page Section
export const updatePage = (data, navigate, to) => async (dispatch) => {
  try {
    dispatch({ type: types.UPDATE_PAGE_SECTION });
    const res = await request({
      url: `${process.env.REACT_APP_API_URL}/page/update-by-slug`,
      auth: true,
      method: 'PUT',
      data
    });
    toast.success('Page Updated Successsully', {
      position: 'top-center',
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      draggable: true,
      progress: undefined
    });
    dispatch({
      type: types.UPDATE_PAGE_SECTION_SUCCESS,
      payload: { ...data }
    });
    dispatch(logUserActivity(`UPDATED_PAGE`));
    setTimeout(() => {
      navigate(to);
    }, 2000);
  } catch (err) {
    dispatch({ type: types.UPDATE_PAGE_SECTION_FAIL, payload: err });
    throw err;
  }
};

// Page
// Get Page By Slug
export const allPageSections = (slug) => async (dispatch) => {
  dispatch({ type: types.GET_PAGE_SECTION });
  try {
    const res = await request({
      url: `${process.env.REACT_APP_API_URL}/page/get-by-slug/`,
      auth: true,
      method: 'POST',
      data: { slug: slug }
    });

    dispatch({
      type: types.GET_PAGE_SECTION_SUCCESS,
      payload: res.data
    });
  } catch (err) {
    dispatch({ type: types.GET_PAGE_SECTION_FAIL, payload: err });
    throw err;
  }
};

// Get Single Section
export const singlePageSection = (id) => async (dispatch) => {
  dispatch({ type: types.SINGLE_PAGE_SECTION });
  try {
    const res = await request({
      url: `${process.env.REACT_APP_API_URL}/section/get-by-id/${id}`,
      auth: true,
      method: 'GET'
    });

    dispatch({
      type: types.SINGLE_PAGE_SECTION_SUCCESS,
      payload: res.data
    });
  } catch (err) {
    dispatch({ type: types.SINGLE_PAGE_SECTION_FAIL, payload: err });
    throw err;
  }
};

// Update Page Section
export const updatePageSection = (data, id) => async (dispatch) => {
  try {
    dispatch({ type: types.UPDATE_PAGE_SECTION });
    const res = await request({
      url: `${process.env.REACT_APP_API_URL}/section/update-page-section/${id}`,
      auth: true,
      method: 'PUT',
      data
    });
    toast.success('Section Updated Successsully', {
      position: 'top-center',
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      draggable: true,
      progress: undefined
    });
    dispatch(logUserActivity(`UPDATED_PAGE_SECTION`));
    dispatch({
      type: types.UPDATE_PAGE_SECTION_SUCCESS,
      payload: { ...data }
    });
  } catch (err) {
    dispatch({ type: types.UPDATE_PAGE_SECTION_FAIL, payload: err });
    throw err;
  }
};

// For Page Content
export const getPageById = (id) => async (dispatch) => {
  try {
    const res = await request({
      url: `${process.env.REACT_APP_API_URL}/page/get-by-id/${id}`,
      auth: true,
      method: 'GET'
    });
    dispatch({
      type: types.GET_PAGED_DATA,
      payload: { ...res.data }
    });
  } catch (error) {
    dispatch({ type: types.ADD_PAGE_SECTION_FAIL, payload: error });
    throw error;
  }
};

// Get page Content By Slug
export const singlePageContent = (slug) => async (dispatch) => {
  dispatch({ type: types.SINGLE_PAGE_CONTENT_ATTEMPT });
  try {
    const res = await request({
      url: `${process.env.REACT_APP_API_URL}/page/get-country-slug`,
      auth: true,
      method: 'POST',
      data: { country: slug }
    });
    dispatch({
      type: types.SINGLE_PAGE_CONTENT_SUCCESS,
      payload: res.data
    });
  } catch (err) {
    dispatch({ type: types.SINGLE_PAGE_CONTENT_FAIL, payload: err });
    throw err;
  }
};

// Update page
export const updatePageContent = (data, navigate) => async (dispatch) => {
  try {
    const res = await request({
      url: `${process.env.REACT_APP_API_URL}/page/update-page-slug`,
      auth: true,
      method: 'PUT',
      data: data
    });
    if (res.data.success) {
      toast.success(res.data.message, {
        position: 'top-center',
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        draggable: true,
        progress: undefined
      });
      dispatch(logUserActivity(`UPDATED_PAGE_CONTENT`));
      setTimeout(() => {
        navigate('/dashboard/home-page-content');
      }, 1000);
    }
  } catch (error) {
    toast.error(error.message, {
      position: 'top-center',
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      draggable: true,
      progress: undefined
    });
  }
};

// Delete Page
export const deletePage = (id) => async (dispatch) => {
  try {
    dispatch({ type: types.DELETE_MENU_ATTEMPT });
    const res = await request({
      method: 'DELETE',
      auth: true,
      url: `${process.env.REACT_APP_API_URL}/page/delete-by-id`,
      data: { id }
    });

    toast.success('Page Deleted Successsully', {
      position: 'top-center',
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      draggable: true,
      progress: undefined
    });
    dispatch(logUserActivity(`DELETED_PAGE`));
    setTimeout(() => {
      window.location.href = '/dashboard/home-page-content';
    }, 2000);

    dispatch({ type: types.DELETE_MENU_SUCCESS, payload: {} });
  } catch (err) {
    dispatch({ type: types.DELETE_MENU_FAIL, payload: err });
    throw new Error(err);
  }
};
