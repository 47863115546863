import * as types from '../constants/actionTypes';

const initialState = {
  activities: [],
  loading: true,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case types.ADD_ACTIVITY_SUCCESS:
      return {
        ...state,
        activities: [...state.activities, action.payload],
        loading: false,
      };
    case types.GET_ACTIVITIES_SUCCESS:
      return {
        ...state,
        activities: action.payload,
        loading: false,
      };
    case types.ADD_ACTIVITY_ATTEMPT:
    case types.GET_ACTIVITIES_ATTEMPT:
      return {
        ...state,
        loading: true,
      };
    case types.ADD_ACTIVITY_FAIL:
    case types.GET_ACTIVITIES_FAIL:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
}
