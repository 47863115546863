import React, { useEffect, useState } from 'react';
import { Link as RouterLink, useParams, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { sentenceCase } from 'change-case';
// material
import { Grid, Container, Stack, Typography, Button, Box, Card, CardContent } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
// import Accordion from '@mui/material/Accordion';
// import AccordionSummary from '@mui/material/AccordionSummary';
// import AccordionDetails from '@mui/material/AccordionDetails';
// import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
// components
import Page from 'src/components/Page';
import Loader from 'src/components/Loader';
import Label from 'src/components/Label';
//
import { singleOrder } from 'src/Redux/actions/ordersActions';
import withRole from 'src/components/HOC/withRole';
import OrderItemsTable from './OrderItemsTable';

const OrderDetails = (props) => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // for comments
  const [comments, setComments] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const pageState = useSelector((state) => state.orders.items);
  const currency = pageState.order?.orderItems[0].currency;
  // For User Role
  const userRole = props.user?.userRole;
  const [state, setState] = useState({});

  const [pageSections, setPageSection] = useState({});
  const formData = pageState;

  // For Order Details
  useEffect(() => {
    setPageSection({
      ...formData
    });
  }, [pageState.order]);

  useEffect(() => {
    const fetchData = () => {
      try {
        setLoading(true);
        dispatch(singleOrder(id));
        setLoading(false);
      } catch (error) {
        setLoading(true);
      }
    };
    fetchData();
    const type = userRole == '1' || userRole == '4' || userRole == '5' ? null : navigate('/dashboard/app');
    return () => {
      setState({}); // To Avoid Memory Leak
      return type;
    };
  }, []);

  return (
    <Page title="Dashboard: Pages | Order Details">
      <Container>
        <Stack direction="row" justifyContent="space-between" mb={2}>
          <Typography variant="h4" gutterBottom>
            Order Detail
          </Typography>
          <Button
            variant="contained"
            color="secondary"
            component={RouterLink}
            to="/dashboard/orders"
            startIcon={<ArrowBackIcon />}
          >
            Go Back to Orders
          </Button>
        </Stack>
        {loading && <Loader />}
        {!loading && pageState.order && (
          <Grid container display="flex" alignItems="start" justifyContent="start" spacing={1}>
            <Grid item lg={6} md={6} xs={12} sm={12} mt={2}>
              <Box
                sx={{ boxShadow: 1, borderRadius: '16px', border: '1px solid #929eaa6b' }}
                key={pageState.order?._id}
              >
                <Card sx={{ maxWidth: '100%' }}>
                  <CardContent>
                    <Label variant="ghost" color="warning">
                      Order Data
                    </Label>
                    <Typography variant="body2" mt={1} mb={2} sx={{ fontSize: 17 }}>
                      <strong>OrderID:</strong> {pageState.order.orderId}{' '}
                      <Button
                        variant="outlined"
                        size="small"
                        onClick={(e) => {
                          navigator.clipboard.writeText(pageState.order.orderId);
                          e.target.textContent = 'Copied';
                          setTimeout(() => {
                            e.target.textContent = 'Copy';
                          }, 3000);
                        }}
                      >
                        Copy
                      </Button>
                    </Typography>
                    <Typography variant="body2" mt={1} mb={2} sx={{ fontSize: 17 }}>
                      <strong>Email:</strong> {pageState.order.userEmail}
                    </Typography>
                    <strong>Payment Status:</strong>
                    <Label
                      variant="ghost"
                      sx={{ ml: 1 }}
                      color={
                        (pageState.order.paymentStatus === 'initiated' && 'warning') || 'success'
                      }
                    >
                      {sentenceCase(pageState.order.paymentStatus)}
                    </Label>
                    <Typography variant="body2" mt={2} mb={2} sx={{ fontSize: 17 }}>
                      <strong>Payment IntentId:</strong> {pageState.order.paymentIntentId}
                    </Typography>
                    <Typography variant="body2" mt={2} mb={2} sx={{ fontSize: 17 }}>
                      <strong>Risk Score:</strong> {pageState.order.stripeRiskScore}
                    </Typography>
                    {userRole !== '4' && (
                      <>
                        <Typography variant="body2" mt={3} mb={2} sx={{ fontSize: 17 }}>
                          <strong> Amount Paid: </strong>
                          {currency}
                          {pageState.order.amountPaid}
                        </Typography>
                        <Typography variant="body2" mt={3} mb={2} sx={{ fontSize: 17 }}>
                          <strong> Discount Percent: </strong>
                          {pageState.order.promoDiscountPercent}%
                        </Typography>
                      </>
                    )}
                    {/* Promo details? */}
                    <Typography variant="body2" mt={3} mb={2} sx={{ fontSize: 17 }}>
                      <strong> Promo Code: </strong>
                      {pageState.order.promo?.promoCode}
                    </Typography>
                    <Typography variant="body2" mt={3} mb={2} sx={{ fontSize: 17 }}>
                      <strong> Tracking Id: </strong>
                      <Button>
                        <a
                          href={`https://followerscart.com/track-order/${pageState.order.orderId}/`}
                          target="_blank"
                          style={{ textDecoration: 'none' }}
                        >
                          Track Now
                        </a>
                      </Button>
                    </Typography>
                    <Typography variant="body2" mt={3} mb={2} sx={{ fontSize: 17 }}>
                      <strong> Order Date: </strong>
                      {moment(pageState.order.orderDate).format('MMMM Do, YYYY')}
                    </Typography>
                  </CardContent>
                </Card>
              </Box>
            </Grid>
          </Grid>
        )}
        {pageState.order?.orderItems.length > 0 && (
          <Typography variant="h4" gutterBottom mt={3}>
            Order Items
          </Typography>
        )}
        <Grid container display="flex">
          <Box sx={{ borderRadius: 1, border: '1px solid #929eaa6b', width: '100%' }}>
            <OrderItemsTable
              userRole={userRole}
              loading={loading}
              orderData={pageState?.order}
              items={pageState?.order?.orderItems}
              urlId={pageState?.order?._id}
            />
          </Box>
        </Grid>
      </Container>
    </Page>
  );
};
export default withRole(OrderDetails);
