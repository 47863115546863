import React, { useState } from 'react';
import moment from 'moment';
import { Link, useNavigate } from 'react-router-dom';
import { sentenceCase } from 'change-case';
//
import { Stack, Typography, Box } from '@mui/material';
import Tabs, { tabsClasses } from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
//
import Label from 'src/components/Label';
import Page from 'src/components/Page';
import CustomTable from 'src/components/CustomTable';
import withRole from 'src/components/HOC/withRole';
import { getOrders } from 'src/api/api'; // Orders
import { getCurrencySymbol } from 'src/utils/formatCurrency';
import PaginationTable from 'src/components/PaginationTable';

function excludeItems(columns) {
  return columns.filter(
    (column) =>
      column.field !== 'currency' &&
      column.field !== 'amountPaid' &&
      column.field !== 'promoDiscountPercent'
  );
}

function Orders(props) {
  const navigate = useNavigate();
  // Fetch Data from API
  // Searchable Filters
  const [loading, setLoading] = React.useState(false);
  // const [platform, setPlatform] = useState([]);
  // Tabs
  const [tabValue, setTabValue] = React.useState(0);
  // For User Role
  const userRole = props.user?.userRole;
  // const [state, setState] = useState({});

  // Get All Orders
  React.useEffect(() => {
  //   setLoading(true);
  //   const promise = getOrders();
  //   Promise.resolve(promise).then((data) => {
  //     if (data.success) {
  //       const orders = data?.orders;
  //       if (orders && orders?.length) {
  //         setPlatform(data?.orders);
  //         // setRows(data?.orders);
  //       }
  //       setLoading(false);
  //     } else {
  //       setPlatform([]);
  //       // setRows([]);
  //       setLoading(false);
  //     }
  //   });
    const type = userRole == '1' || userRole == '4' || userRole == '5' ? null : navigate('/dashboard/app');
    return () => {
      // setState({}); // To Avoid Memory Leak
      return type;
    };
  }, []);

  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`
    };
  }

  // Table Columns
  const columns = [
    {
      field: 'orderId',
      headerName: 'Order ID',
      width: 200,
      renderCell: (params) => (
        <Link to={`/dashboard/orders/order-details/${params.value}`} className="order-details-link">
          {params.value.substring(0, 18).concat('...')}
        </Link>
      )
    },
    {
      field: 'userEmail',
      headerName: 'User Email',
      width: 150
    },
    {
      field: 'currency',
      headerName: 'Currency',
      width: 100,
      renderCell: (params) => getCurrencySymbol(params.value)
    },
    {
      field: 'amountPaid',
      headerName: 'Amout Paid',
      width: 100,
      renderCell: (params) => <>{params.value}</>
    },

    {
      field: 'paymentStatus',
      headerName: 'Payment Status',
      width: 130,
      renderCell: (rowData) => (
        <Label variant="ghost" color={(rowData.value === 'pending' && 'error') || 'success'}>
          {sentenceCase(rowData.value)}
        </Label>
      )
    },
    {
      field: 'promoDiscountPercent',
      headerName: 'Discount',
      width: 100,
      renderCell: (value) => <>{value.row.promoDiscountPercent}%</>
    },
    {
      field: 'orderDate',
      headerName: 'Order Date',
      width: 160,
      renderCell: (value) => moment(value.row.orderDate).format('MMMM Do, YYYY')
    }
  ];
  const columnList = userRole !== '4' ? columns : excludeItems(columns);
  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  return (
    <Page title={props.title ? 'Dashboard' : 'Dashboard: Orders'}>
      <Box p={props.title ? 0 : 3}>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={2}>
          <Typography variant="h4" gutterBottom>
            All Orders
          </Typography>
        </Stack>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs
            value={tabValue}
            onChange={handleTabChange}
            variant="scrollable"
            // scrollButtons
            aria-label="visible arrows tabs example"
            sx={{
              [`& .${tabsClasses.scrollButtons}`]: {
                '&.Mui-disabled': { opacity: '0.3' }
              }
            }}
          >
            <Tab label="Completed" {...a11yProps(0)} />
            {userRole !== '4' && <Tab label="Initiated" {...a11yProps(1)} />}
            {userRole !== '4' && <Tab label="Test" {...a11yProps(1)} />}
          </Tabs>
        </Box>
        <TabPanel value={tabValue} index={0}>
          <OrderTabPanel
            // platform={platform}
            match="succeeded"
            columns={columnList}
            loading={loading}
          />
        </TabPanel>
        {userRole !== '4' && (
          <TabPanel value={tabValue} index={1}>
            <OrderTabPanel
              // platform={platform}
              match="initiated"
              columns={columnList}
              loading={loading}
            />
          </TabPanel>
        )}
        {userRole !== '4' && (
          <TabPanel value={tabValue} index={2}>
            <OrderTabPanel
              // platform={platform}
              match="testing"
              columns={columnList}
              loading={loading}
            />
          </TabPanel>
        )}
      </Box>
    </Page>
  );
}
export default withRole(Orders);

const OrderTabPanel = ({ match, columns, loading }) => {
  // const [rows, setRows] = React.useState(platform);

  return (
    <PaginationTable
      searchPlaceholder="Search..."
      tableHeading={`for Orders ${match}`}
      match={match}
      // platform={platform.filter((row) => row.paymentStatus === match)}
      // setRows={setRows}
      // rows={rows.filter((row) => row.paymentStatus === match)}
      // rowId={(row) => row._id}
      columns={columns}
      loading={loading}
      // data={platform?.length ? platform?.length : 'No Data'} // For No Data
    />
  );
};
