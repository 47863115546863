import React, { useState } from 'react';
// MUI Components
import { DataGrid } from '@mui/x-data-grid';
import { Stack, Typography, IconButton } from '@mui/material';
import TextField from '@mui/material/TextField';
import ClearIcon from '@mui/icons-material/Clear';
import SearchIcon from '@mui/icons-material/Search';
// Components
import Loader from 'src/components/Loader';

export default function CustomTable(props) {
  // For Search Text
  const [searchText, setSearchText] = useState('');

  // Search Functions
  function escapeRegExp(value) {
    return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
  }

  const requestSearch = (searchValue) => {
    const searchRegex = new RegExp(escapeRegExp(searchValue), 'i');
    const filteredRows = props.platform.filter((row) => {
      return Object.keys(row).some((field) => {
        return searchRegex.test(row[field]?.toString());
      });
    });
    props?.setRows(filteredRows);
  };

  return (
    <>
      {props?.loading && <Loader data={props.data ? props.data : ''} />}
      {!props?.loading && (
        <div style={{ width: '100%' }}>
          <Stack direction="row" alignItems="center" justifyContent="space-between" mb={1}>
            <Typography variant="h4" gutterBottom>
              {props.pageTitle ? props.pageTitle : ''}
            </Typography>
            <TextField
              variant="outlined"
              value={searchText}
              onChange={(e) => {
                setSearchText(e.target.value);
                requestSearch(e.target.value);
              }}
              placeholder={props.searchPlaceholder ? props.searchPlaceholder : 'Search...'}
              InputProps={{
                startAdornment: <SearchIcon fontSize="small" color="action" />,
                endAdornment: (
                  <IconButton
                    title="Clear"
                    aria-label="Clear"
                    size="small"
                    style={{
                      visibility: searchText ? 'visible' : 'hidden',
                      borderRadius: '57%',
                      paddingRight: '1px',
                      margin: '0',
                      fontSize: '1.25rem'
                    }}
                    onClick={(e) => {
                      setSearchText('');
                      props.setRows(props.platform);
                    }}
                  >
                    <ClearIcon fontSize="small" color="action" />
                  </IconButton>
                )
              }}
              sx={{
                width: { xs: 1, sm: 'auto' },
                m: (theme) => theme.spacing(1, 0.5, 1.5),
                '& .MuiSvgIcon-root': {
                  mr: 0.5
                },
                '& .MuiInput-underline:before': {
                  borderBottom: 1,
                  borderColor: 'divider'
                }
              }}
            />
          </Stack>
          <Typography variant="h5" gutterBottom>
            Data Table {props.tableHeading ? props.tableHeading : ''}
          </Typography>
          <Stack
            sx={{
              boxShadow: 3,
              borderRadius: 1,
              border: '1px solid #929eaa6b',
              width: '100%',
              mt: 2
            }}
          >
            <DataGrid
              getRowId={props.rowId}
              rows={props.rows}
              columns={props.columns}
              rowsPerPageOptions={[20]}
              pageSize={20}
              autoHeight={true}
              disableSelectionOnClick
              disableColumnMenu
              checkboxSelection={false}
            />
          </Stack>
        </div>
      )}
    </>
  );
}
