import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import * as Yup from 'yup';
import { Form, Formik, Field, FieldArray, ErrorMessage } from 'formik';
import { TextField, Switch } from 'formik-mui';
// material
import { Stack, Typography, Grid, FormLabel, MenuItem, Button } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import FormControlLabel from '@mui/material/FormControlLabel';
import CircularProgress from '@mui/material/CircularProgress';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
//
import CustomButton from 'src/components/Button';
import MenuDeleteDialog from './MenuDeleteDialog';
//
import { singleMenu, updateMenu, deleteSubMenu, getSubMenu } from 'src/Redux/actions/menuActions';
import { allCountries } from 'src/Redux/actions/countryActions';

export default function AddMenu() {
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);

  // Get Sinle Menu
  let menuState = useSelector((state) => state.menus);
  const [menuLoading, setMenuLoading] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);

  const { subMenu } = menuState;
  menuState = menuState.items;

  // All Countries
  const [countryLoading, setCountryLoading] = useState(false);
  const [countries, setCountries] = useState([]);
  const pageState = useSelector((state) => state.countries.items);

  // Get All Countries
  useEffect(() => {
    setCountries(pageState?.countries);
  }, [pageState.countries]);

  useEffect(() => {
    const fetchData = () => {
      setCountryLoading(true);
      try {
        dispatch(allCountries(setCountryLoading));
      } catch (error) {
        setCountryLoading(false);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = () => {
      try {
        setMenuLoading(true);
        setTimeout(() => {
          dispatch(singleMenu(id));
          dispatch(getSubMenu(id));
          setMenuLoading(false);
        }, 1000);
      } catch (error) {
        setMenuLoading(true);
      }
    };
    fetchData();
  }, []);

  const removeSubMenu = async (remove, index, id) => {
    if (id) {
      setDeleteDialogOpen(true);
    } else {
      remove(index);
    }
  };

  // Validation Schema
  const MenuSchema = Yup.object().shape({
    title: Yup.string().required('Menu Title is required'),
    link: Yup.string().required('Menu Link is required'),
    country: Yup.string().required('Menu country is required'),
    order: Yup.string().required('Menu order is required')
  });

  return (
    <Grid container justifyContent="center">
      <Grid item lg={10} md={10} xs={10} sm={10}>
        <Card sx={{ minWidth: 275, boxShadow: 3, borderRadius: 1, border: '1px solid #929eaa6b' }}>
          <CardContent>
            <Formik
              enableReinitialize={true}
              initialValues={{
                id: id,
                title: menuState.menu ? menuState.menu.title : '',
                link: menuState.menu ? menuState.menu.link : '',
                parent: menuState.menu ? menuState.menu.parent : '',
                country: menuState.menu ? menuState.menu.country : '',
                order: menuState.menu ? menuState.menu.order : '',
                active: menuState.menu ? menuState.menu.active : false,
                subMenu
              }}
              validationSchema={MenuSchema}
              onSubmit={(values) => {
                dispatch(updateMenu(values, navigate));
                setLoading(true);
                setTimeout(() => {
                  setLoading(false);
                }, 1000);
              }}
            >
              {({ errors, touched, values }) => (
                <Form autoComplete="off" style={{ padding: 'inherit' }}>
                  <Typography variant="h4" gutterBottom>
                    Edit Menu
                  </Typography>
                  <Stack spacing={1} mt={2}>
                    <FormLabel id="country">Country</FormLabel>
                    {countryLoading && <CircularProgress size={30} />}
                    {!countryLoading && (
                      <Field
                        name="country"
                        select
                        component={TextField}
                        sx={{ width: '95%' }}
                        label="Select Value"
                        disabled
                      >
                        <MenuItem value="">Not Selected</MenuItem>
                        {countries?.map((item) => {
                          if (item.active == true)
                            return (
                              <MenuItem
                                key={item._id}
                                value={item.countrySlug}
                                label={item.countryName}
                              >
                                {item.countryName}
                              </MenuItem>
                            );
                        })}
                      </Field>
                    )}
                    <p className="helper-text-style">Cannot change Country.</p>
                    {errors.country && touched.country ? (
                      <div className="error-color">{errors.country}</div>
                    ) : null}
                    <FormLabel id="title">Title</FormLabel>
                    <Field
                      type="text"
                      id="title"
                      name="title"
                      className="input-style"
                      placeholder="Menu Title"
                    />
                    {errors.title && touched.title ? (
                      <div className="error-color">{errors.title}</div>
                    ) : null}
                    <FormLabel id="link">Link</FormLabel>
                    <Field
                      type="text"
                      id="link"
                      name="link"
                      className="input-style"
                      placeholder="Menu Link"
                    />
                    {errors.link && touched.link ? (
                      <div className="error-color">{errors.link}</div>
                    ) : null}
                    <FormLabel id="order">Order</FormLabel>
                    <Field
                      type="text"
                      id="order"
                      name="order"
                      className="input-style"
                      placeholder="Menu order"
                    />
                    {errors.order && touched.order ? (
                      <div className="error-color">{errors.order}</div>
                    ) : null}
                    <Grid>
                      <Grid item lg={5} md={5} xs={10} sm={10}>
                        <FormControlLabel
                          control={<Field component={Switch} type="checkbox" name="active" />}
                          label={values.active === true ? 'Active' : 'In-Active'}
                        />
                      </Grid>
                    </Grid>
                    <Typography variant="h6" gutterBottom mt={1}>
                      Sub Menu
                    </Typography>
                    <FieldArray
                      name="subMenu"
                      render={(arrayHelpers) => (
                        <div>
                          {values.subMenu && values.subMenu.length <= 0 && (
                            <p>
                              <i> No sub Menu</i>
                            </p>
                          )}
                          {values.subMenu && values.subMenu.length > 0 ? (
                            values.subMenu
                              ?.sort((a, b) => a.order - b.order)
                              ?.map((sm, idx) => (
                                <div className="row" style={{ marginLeft: 20 }} key={idx}>
                                  <div className="row">
                                    <div className="col button-style">
                                      <div className="input-section">
                                        <FormLabel htmlFor={`subMenu[${idx}].title`}>
                                          Title
                                        </FormLabel>
                                        <Field
                                          id={`subMenu[${idx}].title`}
                                          name={`subMenu[${idx}].title`}
                                          type="text"
                                          placeholder="Menu Text"
                                          className="input-style"
                                          required
                                        />
                                        <ErrorMessage
                                          name={`subMenu[${idx}].title`}
                                          component="div"
                                          className="error-color"
                                        />
                                      </div>
                                      <div className="input-section">
                                        <FormLabel htmlFor={`subMenu[${idx}].link`}>Link</FormLabel>
                                        <Field
                                          id={`subMenu[${idx}].link`}
                                          name={`subMenu[${idx}].link`}
                                          type="text"
                                          placeholder="e.g. blogs"
                                          className="input-style"
                                        />
                                        <ErrorMessage
                                          name={`subMenu[${idx}].link`}
                                          component="div"
                                          className="error-color"
                                        />
                                      </div>
                                      <div className="input-section">
                                        <FormLabel htmlFor={`subMenu[${idx}].order`}>
                                          Order of Appearance
                                        </FormLabel>
                                        <Field
                                          id={`subMenu[${idx}].order`}
                                          name={`subMenu[${idx}].order`}
                                          type="number"
                                          min="1"
                                          placeholder="Order of appearance"
                                          className="input-style"
                                        />
                                        <ErrorMessage
                                          name={`subMenu[${idx}].order`}
                                          component="div"
                                          className="error-color"
                                        />
                                      </div>
                                      <Grid>
                                        <Grid item lg={5} md={5} xs={10} sm={10}>
                                          <FormControlLabel
                                            control={
                                              <Field
                                                component={Switch}
                                                type="checkbox"
                                                name={`subMenu[${idx}].active`}
                                              />
                                            }
                                            label={
                                              values.subMenu[idx].active === true
                                                ? 'Active'
                                                : 'In-Active'
                                            }
                                          />
                                        </Grid>
                                      </Grid>
                                    </div>
                                    <div className="col button-style">
                                      <div>
                                        <Button
                                          type="button"
                                          variant="contained"
                                          color="secondary"
                                          sx={{ mt: 2, mb: 2 }}
                                          onClick={() =>
                                            arrayHelpers.push({
                                              title: '',
                                              link: '',
                                              order: 1,
                                              active: true
                                            })
                                          }
                                        >
                                          Add More
                                        </Button>

                                        {
                                          <Button
                                            type="button"
                                            sx={{ mt: 2, ml: 2, mb: 2 }}
                                            variant="contained"
                                            color="error"
                                            onClick={() =>
                                              removeSubMenu(arrayHelpers.remove, idx, sm?._id)
                                            }
                                            className="home-section-delete-button"
                                          >
                                            Remove Menu
                                          </Button>
                                        }
                                        <MenuDeleteDialog
                                          modalOpen={deleteDialogOpen}
                                          setModalOpen={setDeleteDialogOpen}
                                          id={sm?._id}
                                          action={deleteSubMenu}
                                          removeIndex={arrayHelpers.remove}
                                          index={idx}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              ))
                          ) : (
                            <Button
                              type="button"
                              variant="contained"
                              color="secondary"
                              sx={{ mt: 2, mb: 2 }}
                              onClick={() =>
                                arrayHelpers.push({
                                  title: '',
                                  link: '',
                                  order: 1,
                                  active: true
                                })
                              }
                            >
                              Add New
                            </Button>
                          )}
                        </div>
                      )}
                    />
                  </Stack>
                  <Grid container>
                    <Grid item xs={5} sm={5} md={3} lg={2} mt={3}>
                      <LoadingButton
                        fullWidth
                        size="large"
                        type="submit"
                        variant="contained"
                        loading={loading}
                      >
                        Submit
                      </LoadingButton>
                    </Grid>
                    <Grid item xs={5} sm={5} md={3} lg={2} ml={2} mt={3}>
                      <CustomButton
                        btnText="Cancel"
                        variant="contained"
                        color="error"
                        link="/dashboard/menus"
                        size="large"
                      />
                    </Grid>
                  </Grid>
                </Form>
              )}
            </Formik>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
}
