import * as types from '../constants/actionTypes';

const initialState = {
  items: [],
  loading: true,
  menus: {},
  menu: {},
  subMenu: []
};

export default function (state = initialState, action) {
  switch (action.type) {
    case types.ADD_MENU_SUCCESS:
      return {
        ...state,
        items: action.payload,
        menus: action.payload,
        loading: false
      };
    case types.GET_MENUS_SUCCESS:
      return {
        ...state,
        items: action.payload,
        menus: action.payload,
        loading: false
      };
    case types.GET_COUNTRY_MENUS_SUCCESS:
      return {
        ...state,
        items: action.payload,
        menus: action.payload,
        loading: false
      };
    case types.SINGLE_MENU_SUCCESS:
      return {
        ...state,
        items: action.payload,
        menu: action.payload,
        loading: false
      };
    case types.SET_SUB_MENU:
      return {
        ...state,
        subMenu: action.payload
      };
    case types.DELETE_MENU_SUCCESS:
      return {
        ...state,
        items: action.payload,
        menus: action.payload,
        loading: false
      };
    default:
      return state;
  }
}
