import { useEffect, useState } from 'react';
// @mui
import { Grid, Container, Typography, CircularProgress } from '@mui/material';

// components
import Page from '../components/Page';
// sections
import { AppWidgetSummary } from '../sections/@dashboard/app';
//
import Blogs from './Blogs/Blogs';
// API's
import { getBlogs, getReviews, getUsers, getFaqsContent, getWebhookData } from 'src/api/api'; // Orders
import withRole from 'src/components/HOC/withRole';
import StripeWebhook from './StripeSetting/components/StripeWebhook';

// ----------------------------------------------------------------------

function DashboardApp(props) {
  // Check User Role
  const userRole = props.user.userRole;

  const [faqs, setFaqs] = useState([]);
  const [faqsLoading, setFaqsLoading] = useState(false);
  // Get all Users
  const [users, setUsers] = useState([]);
  const [usersLoading, setUsersLoading] = useState(false);
  // Get all Testimonials
  const [testimonials, setTestimonials] = useState([]);
  const [testLoading, setTestLoading] = useState(false);
  // Get all Blogs
  const [blogs, setBlogs] = useState([]);
  const [blogsLoading, setBlogsLoading] = useState(false);
  // Get all Webhook data
  const [webhookData, setWebhookData] = useState([]);
  const [webhookLoading, setWebhookLoading] = useState(false);

  // Get ALL Faqs
  useEffect(() => {
    setFaqsLoading(true);
    const promise = getFaqsContent();
    Promise.resolve(promise).then((data) => {
      if (data.success) {
        const content = data?.data;
        if (data) {
          setFaqs(content);
        }
        setFaqsLoading(false);
      } else {
        setFaqs([]);
        setFaqsLoading(false);
      }
    });
  }, []);

  // Get Users Data
  useEffect(() => {
    setUsersLoading(true);
    const promise = getUsers();
    Promise.resolve(promise).then((data) => {
      if (data.success) {
        const users = data?.users;
        if (users && users?.length) {
          setUsers(data?.users);
        }
        setUsersLoading(false);
      } else {
        setUsers([]);
        setUsersLoading(false);
      }
    });
  }, []);

  // Get Reviews Data
  useEffect(() => {
    setTestLoading(true);
    const promise = getReviews();
    Promise.resolve(promise).then((data) => {
      if (data.success) {
        const reviews = data?.reviews;
        if (reviews && reviews?.length) {
          setTestimonials(data?.reviews);
        }
        setTestLoading(false);
      } else {
        setTestimonials([]);
        setTestLoading(false);
      }
    });
  }, []);

  // Get Blogs Data
  useEffect(() => {
    setBlogsLoading(true);
    const promise = getBlogs();
    Promise.resolve(promise).then((data) => {
      if (data.success) {
        const posts = data?.posts;
        if (posts && posts?.length) {
          setBlogs(data?.posts);
        }
        setBlogsLoading(false);
      } else {
        setBlogs([]);
        setBlogsLoading(false);
      }
    });
  }, []);

  // Get Webhook Data
  useEffect(() => {
    setWebhookLoading(true);
    console.log("in fetch webhook: ",)
    const promise = getWebhookData();
    Promise.resolve(promise).then((data) => {
      if (data.success) {
        const webhook = data?.data;
        if (webhook && webhook?.length) {
          setWebhookData(data);
        }
        setWebhookLoading(false);
      } else {
        setWebhookData([]);
        setWebhookLoading(false);
      }
    });
  }, []);

  return (
    <Page title="Dashboard">
      <Container maxWidth="xl">
        <Typography variant="h4" sx={{ mb: 5 }}>
          Hi, Welcome Back
        </Typography>

        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary
              title="Total User Count"
              total={users?.length}
              loading={usersLoading}
              color="info"
              icon={'mdi:account-group'}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary
              title="Testimonials"
              total={testimonials?.length}
              loading={testLoading}
              color="warning"
              icon={'ic:baseline-reviews'}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary
              title="Total Blogs"
              total={blogs?.length}
              loading={blogsLoading}
              color="error"
              icon={'dashicons:welcome-write-blog'}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary
              title="Total Faq's"
              total={faqs?.length}
              loading={faqsLoading}
              icon={'clarity:shopping-cart-solid'}
            />
          </Grid>
          {webhookLoading ? (
            <Grid item xs={12} sm={12} md={12}>
              <CircularProgress size={30} />
            </Grid>
          ) : (
            <Grid item xs={12} sm={12} md={12}>
              {userRole === 1 ? (
                <StripeWebhook data={webhookData} webhookLoading={webhookLoading} />
              ) : userRole === 2 ? (
                <Blogs userRole={userRole} title="Dashboard" />
              ) : ''
            }
            </Grid>
          )}
        </Grid>
      </Container>
    </Page>
  );
}
export default withRole(DashboardApp);
