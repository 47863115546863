import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import { Form, Formik, Field } from 'formik';
import { Switch } from 'formik-mui';
// material
import { Stack, Typography, Grid, FormLabel, TextField } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import FormControlLabel from '@mui/material/FormControlLabel';
//
import CustomButton from 'src/components/Button';
import RichTextEditor from 'src/components/RichTextEditor';
import CategorySelect from './CategorySelect';
// ----------------------------------------------------------------------
import { getBlogCategories } from 'src/api/api';
import { addBlog, API } from 'src/Redux/actions/blogsActions'; // Actions
import { createImageFile } from 'src/Redux/helpers/generalHelper'; // Create Image File
//
const validTypes = [
  'image/jpeg',
  'image/jpg',
  'image/png',
  'image/svg+xml',
  'image/gif',
  'image/webp'
]; // Image Validation

export default function CreateBlogForm() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  // Loading
  const [loading, setLoading] = useState(false);
  const [thumbnail, setThumbnail] = useState('');

  const user = useSelector((state) => state.auth.items?.user) || null;

  // Get all Categories
  const [categories, setCategories] = useState([]);
  const [categoryLoading, setCategoryLoading] = useState(false);

  React.useEffect(() => {
    setCategoryLoading(true);
    const promise = getBlogCategories();
    Promise.resolve(promise).then((data) => {
      if (data.success) {
        const categories = data?.categories;
        if (categories && categories?.length) {
          setCategories(data?.categories);
          setCategoryLoading(false);
        }
        setCategoryLoading(false);
      } else {
        setCategories([]);
        setCategoryLoading(false);
      }
    });
  }, []);

  // Validation Schema
  const BlogSchema = Yup.object().shape({
    title: Yup.string().required('Blog Title is required'),
    slug: Yup.string().required('Blog Slug is required'),
    body: Yup.string().required('Blog Description is required'),
    postImg: Yup.mixed().required('Blog Image is required'),
    createdAt: Yup.date().required('Blog Date is required')
  });

  const validate = (value) => {
    let errorMessage;
    if (!/^[a-zA-Z0-9.-]+$/i.test(value)) {
      errorMessage = 'Slug should only contain alphanumeric chatarters, dots(.) or hyphens (-)';
    }
    return errorMessage;
  };

  return (
    <Grid container justifyContent="center">
      <Grid item lg={10} md={10} xs={10} sm={10}>
        <Card sx={{ minWidth: 275, boxShadow: 3, borderRadius: 1, border: '1px solid #929eaa6b' }}>
          <CardContent>
            <Formik
              enableReinitialize={true}
              initialValues={{
                title: '',
                slug: '',
                categoryId: '',
                body: '',
                postImg: '',
                // postThumbnail: '',
                metaTitle: '',
                metaDescription: '',
                canonicalLink: '',
                featured: false,
                active: true
              }}
              validationSchema={BlogSchema}
              onSubmit={async (values) => {
                setLoading(true);
                const formData = new FormData();
                formData.append('userId', user._id);

                Object.entries(values).map((item) => {
                  const key = item[0];
                  const value = item[1];
                  formData.append(key, value);
                });
                dispatch(addBlog(formData, navigate, setLoading));
              }}
            >
              {({ errors, touched, values, setFieldValue }) => (
                <Form autoComplete="off" style={{ padding: 'inherit' }}>
                  <Typography variant="h4" gutterBottom>
                    Add New Blog
                  </Typography>
                  <Stack spacing={1} mt={2}>
                    <FormLabel id="title">Title</FormLabel>
                    <Field
                      type="text"
                      id="title"
                      name="title"
                      className="input-style"
                      placeholder="Blog Title"
                      onChange={(e) => {
                        const value = e.target.value;
                        setFieldValue('title', value);
                        const slug = value.replace(/[\W_]/g, '-').toLowerCase().trim();
                        setFieldValue('slug', slug.endsWith('-') ? slug.slice(0, -1) : slug);
                      }}
                    />
                    {errors.title && touched.title ? (
                      <div className="error-color">{errors.title}</div>
                    ) : null}
                    <FormLabel id="slug">Slug</FormLabel>
                    <Field
                      type="text"
                      id="slug"
                      name="slug"
                      className="input-style"
                      placeholder="Blog Slug"
                      validate={validate}
                    />
                    {errors.slug && touched.slug ? (
                      <div className="error-color">{errors.slug}</div>
                    ) : null}

                    <FormLabel id="body">Description</FormLabel>
                    <RichTextEditor
                      name="body"
                      value={values?.body}
                      setFieldValue={setFieldValue}
                    />
                    {errors.body && touched.body ? (
                      <div className="error-color">{errors.body}</div>
                    ) : null}
                    <FormLabel id="categoryId">Blog Category</FormLabel>
                    {categoryLoading ? (
                      'Loading...'
                    ) : (
                      <CategorySelect name="categoryId" categories={categories} />
                    )}

                    <FormLabel id="metaTitle">Meta Title</FormLabel>
                    <Field
                      type="text"
                      id="metaTitle"
                      name="metaTitle"
                      className="input-style"
                      placeholder="Blog Description"
                    />
                    {errors.metaTitle && touched.metaTitle ? (
                      <div className="error-color">{errors.metaTitle}</div>
                    ) : null}
                    <FormLabel id="metaDescription">Meta Description</FormLabel>
                    <Field
                      type="text"
                      id="metaDescription"
                      name="metaDescription"
                      className="input-style"
                      placeholder="Blog Description"
                    />
                    {errors.metaDescription && touched.metaDescription ? (
                      <div className="error-color">{errors.metaDescription}</div>
                    ) : null}
                    <FormLabel id="canonicalLink">Canonical Link</FormLabel>
                    <Field
                      type="text"
                      id="canonicalLink"
                      name="canonicalLink"
                      className="input-style"
                      placeholder="Blog Canonical Link"
                    />
                    {errors.canonicalLink && touched.canonicalLink ? (
                      <div className="error-color">{errors.canonicalLink}</div>
                    ) : null}
                    <FormLabel id="postImg">Post Image</FormLabel>
                    {thumbnail && <img src={thumbnail} width={400} alt="featured imaged preview" />}
                    <TextField
                      sx={{
                        mt: 1
                      }}
                      className="blog-post-image"
                      id="postImg"
                      name="postImg"
                      type="file"
                      helperText="Required Image Dimensions: 800 x 500"
                      FormHelperTextProps={{
                        className: 'helper-text-style'
                      }}
                      onChange={(e) => {
                        const image = e.target.files[0];
                        if (!validTypes.includes(image.type)) {
                          toast.error(
                            'Please select an image, allowed file types are .jpg, .jpeg, .png & .webp'
                          );
                          return false;
                        }
                        const fileReader = new FileReader();
                        fileReader.onload = () => {
                          if (fileReader.readyState === 2) {
                            setFieldValue('postImg', image);
                            setThumbnail(fileReader.result);
                          }
                        };
                        fileReader.readAsDataURL(image);
                      }}
                    />
                    {errors.postImg && touched.postImg ? (
                      <div className="error-color">{errors.postImg}</div>
                    ) : null}
                    <FormLabel id="createdAt">Created At</FormLabel>
                    <Grid>
                      <Grid item lg={3} md={5} xs={10} sm={10}>
                        <Field
                          type="date"
                          id="createdAt"
                          name="createdAt"
                          className="input-style"
                          placeholder="Blog Date"
                        />
                        {errors.createdAt && touched.createdAt ? (
                          <div className="error-color">{errors.createdAt}</div>
                        ) : null}
                      </Grid>
                    </Grid>
                    <FormLabel id="featured">Featured</FormLabel>
                    <Grid>
                      <Grid item lg={5} md={5} xs={10} sm={10}>
                        <FormControlLabel
                          control={<Field component={Switch} type="checkbox" name="featured" />}
                          label={values.featured === true ? 'Featured' : 'Non-Featured'}
                        />
                      </Grid>
                    </Grid>
                    <FormLabel id="active">Status</FormLabel>
                    <Grid>
                      <Grid item lg={5} md={5} xs={10} sm={10}>
                        <FormControlLabel
                          control={<Field component={Switch} type="checkbox" name="active" />}
                          label={values.active === true ? 'Active' : 'In-Active'}
                        />
                      </Grid>
                    </Grid>
                  </Stack>
                  <Grid container>
                    <Grid item xs={5} sm={5} md={3} lg={2} mt={3}>
                      <LoadingButton
                        fullWidth
                        size="large"
                        type="submit"
                        variant="contained"
                        loading={loading}
                      >
                        Submit
                      </LoadingButton>
                    </Grid>
                    <Grid item xs={5} sm={5} md={3} lg={2} ml={2} mt={3}>
                      <CustomButton
                        btnText="Cancel"
                        variant="contained"
                        color="error"
                        link="/dashboard/blogs"
                        size="large"
                      />
                    </Grid>
                  </Grid>
                </Form>
              )}
            </Formik>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
}
