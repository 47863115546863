import { toast } from 'react-toastify';
import axios from 'axios';
import * as types from '../constants/actionTypes';
import { request } from '../helpers/axiosRequest';
import { logUserActivity } from './activityLogsActions';

// Add Footer Menu
export const addFooterMenu = (data, navigate) => async (dispatch) => {
  try {
    dispatch({ type: types.ADD_FOOTER_MENU_ATTEMPT });
    const res = await request({
      url: `${process.env.REACT_APP_API_URL}/footer/add-content`,
      auth: true,
      method: 'POST',
      data
    });
    toast.success('Footer Menu Added Successsully', {
      position: 'top-center',
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      draggable: true,
      progress: undefined
    });
    dispatch({
      type: types.ADD_FOOTER_MENU_SUCCESS,
      payload: { ...data }
    });
    dispatch(logUserActivity(`ADDED_FOOTER_MENU`));
    setTimeout(() => {
      navigate('/dashboard/footer-content');
    }, 1000);
  } catch (err) {
    dispatch({ type: types.ADD_FOOTER_MENU_FAIL, payload: err });
    toast.error('Unable to add Footer Menu.', {
      position: 'top-center',
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      draggable: true,
      progress: undefined
    });
    throw err;
  }
};

// Get All Footer Menus
export const allFooterMenus = () => async (dispatch) => {
  dispatch({ type: types.GET_FOOTER_MENUS_ATTEMPT });
  try {
    const res = await request({
      url: `${process.env.REACT_APP_API_URL}/footer/get-all-content`,
      auth: true,
      method: 'GET'
    });
    dispatch({
      type: types.GET_FOOTER_MENUS_SUCCESS,
      payload: res.data
    });
  } catch (err) {
    dispatch({ type: types.GET_FOOTER_MENUS_FAIL, payload: err });
    throw err;
  }
};

// Get All Menus
export const getCountryMenus = (slug) => async (dispatch) => {
  dispatch({ type: types.GET_COUNTRY_MENUS_ATTEMPT });
  try {
    const res = await request({
      url: `${process.env.REACT_APP_API_URL}/menu/get-all-by-country`,
      auth: true,
      method: 'POST',
      data: { country: slug }
    });
    dispatch({
      type: types.GET_COUNTRY_MENUS_SUCCESS,
      payload: res.data
    });
  } catch (err) {
    dispatch({ type: types.GET_COUNTRY_MENUS_FAIL, payload: err });
    throw err;
  }
};

// Get Single Menu
export const singleFooterMenu = (slug) => async (dispatch) => {
  dispatch({ type: types.SINGLE_FOOTER_MENU_ATTEMPT });
  try {
    const res = await request({
      url: `${process.env.REACT_APP_API_URL}/footer/get-content`,
      auth: true,
      method: 'POST',
      data: { country: slug }
    });
    dispatch({
      type: types.SINGLE_FOOTER_MENU_SUCCESS,
      payload: res.data?.footer || {}
    });
  } catch (err) {
    dispatch({ type: types.SINGLE_FOOTER_MENU_FAIL, payload: err });
    throw err;
  }
};

// Update Menu
export const updateFooterMenu = (data, navigate) => async (dispatch) => {
  try {
    dispatch({ type: types.UPDATE_MENU_ATTEMPT });
    const res = await request({
      url: `${process.env.REACT_APP_API_URL}/footer/update-content/${data.country}`,
      auth: true,
      method: 'PUT',
      data
    });
    toast.success('Footer Updated Successsully', {
      position: 'top-center',
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      draggable: true,
      progress: undefined
    });
    dispatch(logUserActivity(`UPDATED_FOOTER_MENU`));
    dispatch({
      type: types.UPDATE_MENU_SUCCESS,
      payload: { ...data }
    });
    setTimeout(() => {
      navigate('/dashboard/footer-content');
    }, 1000);
  } catch (err) {
    dispatch({ type: types.UPDATE_MENU_FAIL, payload: err });
    throw err;
  }
};

// Delete Menu
export const deleteFooterMenu = (countrySlug) => async (dispatch) => {
  try {
    dispatch({ type: types.DELETE_MENU_ATTEMPT });
    const res = await request({
      method: 'DELETE',
      auth: true,
      url: `${process.env.REACT_APP_API_URL}/footer/delete-content`,
      data: { country: countrySlug }
    });

    toast.success('Footer Menu Deleted Successsully', {
      position: 'top-center',
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      draggable: true,
      progress: undefined
    });
    dispatch(logUserActivity(`DELETED_FOOTER_MENU`));
    setTimeout(() => {
      window.location.href = '/dashboard/footer-content';
    }, 2000);

    dispatch({ type: types.DELETE_MENU_SUCCESS, payload: {} });
  } catch (err) {
    dispatch({ type: types.DELETE_MENU_FAIL, payload: err });
    throw new Error(err);
  }
};
