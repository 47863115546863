// Logout
export const LOGOUT = 'LOGOUT';

// Countries
// Create Country
export const ADD_COUNTRY_ATTEMPT = 'ADD_COUNTRY_ATTEMPT';
export const ADD_COUNTRY_SUCCESS = 'ADD_COUNTRY_SUCCESS';
export const ADD_COUNTRY_FAIL = 'ADD_COUNTRY_FAIL';

// Single Country
export const SINGLE_COUNTRY_ATTEMPT = 'SINGLE_COUNTRY_ATTEMPT';
export const SINGLE_COUNTRY_SUCCESS = 'SINGLE_COUNTRY_SUCCESS';
export const SINGLE_COUNTRY_FAIL = 'SINGLE_COUNTRY_FAIL';

// Get All Country
export const GET_COUNTRIES_ATTEMPT = 'GET_COUNTRIES_ATTEMPT';
export const GET_COUNTRIES_SUCCESS = 'GET_COUNTRIES_SUCCESS';
export const GET_COUNTRIES_FAIL = 'GET_COUNTRIES_FAIL';

// Update Country
export const UPDATE_COUNTRY_ATTEMPT = 'UPDATE_COUNTRY_ATTEMPT';
export const UPDATE_COUNTRY_SUCCESS = 'UPDATE_COUNTRY_SUCCESS';
export const UPDATE_COUNTRY_FAIL = 'UPDATE_COUNTRY_FAIL';

// Delete Country
export const DELETE_COUNTRY_ATTEMPT = 'DELETE_COUNTRY_ATTEMPT';
export const DELETE_COUNTRY_SUCCESS = 'DELETE_COUNTRY_SUCCESS';
export const DELETE_COUNTRY_FAIL = 'DELETE_COUNTRY_FAIL';

// Menus
// Create Menu
export const ADD_MENU_ATTEMPT = 'ADD_MENU_ATTEMPT';
export const ADD_MENU_SUCCESS = 'ADD_MENU_SUCCESS';
export const ADD_MENU_FAIL = 'ADD_MENU_FAIL';

// Single Menu
export const SINGLE_MENU_ATTEMPT = 'SINGLE_MENU_ATTEMPT';
export const SINGLE_MENU_SUCCESS = 'SINGLE_MENU_SUCCESS';
export const SINGLE_MENU_FAIL = 'SINGLE_MENU_FAIL';
export const SET_SUB_MENU = 'SET_SUB_MENU';

// Get All Menu
export const GET_MENUS_ATTEMPT = 'GET_MENUS_ATTEMPT';
export const GET_MENUS_SUCCESS = 'GET_MENUS_SUCCESS';
export const GET_MENUS_FAIL = 'GET_MENUS_FAIL';

// Get All Menus by Country
export const GET_COUNTRY_MENUS_ATTEMPT = 'GET_COUNTRY_MENUS_ATTEMPT';
export const GET_COUNTRY_MENUS_SUCCESS = 'GET_COUNTRY_MENUS_SUCCESS';
export const GET_COUNTRY_MENUS_FAIL = 'GET_COUNTRY_MENUS_FAIL';
// Update Menu
export const UPDATE_MENU_ATTEMPT = 'UPDATE_MENU_ATTEMPT';
export const UPDATE_MENU_SUCCESS = 'UPDATE_MENU_SUCCESS';
export const UPDATE_MENU_FAIL = 'UPDATE_MENU_FAIL';

// Delete Menu
export const DELETE_MENU_ATTEMPT = 'DELETE_MENU_ATTEMPT';
export const DELETE_MENU_SUCCESS = 'DELETE_MENU_SUCCESS';
export const DELETE_MENU_FAIL = 'DELETE_MENU_FAIL';

// Footer Menus
// Create Footer Menu
export const ADD_FOOTER_MENU_ATTEMPT = 'ADD_FOOTER_MENU_ATTEMPT';
export const ADD_FOOTER_MENU_SUCCESS = 'ADD_FOOTER_MENU_SUCCESS';
export const ADD_FOOTER_MENU_FAIL = 'ADD_FOOTER_MENU_FAIL';

// Single Footer Menu
export const SINGLE_FOOTER_MENU_ATTEMPT = 'SINGLE_FOOTER_MENU_ATTEMPT';
export const SINGLE_FOOTER_MENU_SUCCESS = 'SINGLE_FOOTER_MENU_SUCCESS';
export const SINGLE_FOOTER_MENU_FAIL = 'SINGLE_FOOTER_MENU_FAIL';

// Get All Footer Menu
export const GET_FOOTER_MENUS_ATTEMPT = 'GET_FOOTER_MENUS_ATTEMPT';
export const GET_FOOTER_MENUS_SUCCESS = 'GET_FOOTER_MENUS_SUCCESS';
export const GET_FOOTER_MENUS_FAIL = 'GET_FOOTER_MENUS_FAIL';

// Update Footer Menu
export const UPDATE_FOOTER_MENU_ATTEMPT = 'UPDATE_FOOTER_MENU_ATTEMPT';
export const UPDATE_FOOTER_MENU_SUCCESS = 'UPDATE_FOOTER_MENU_SUCCESS';
export const UPDATE_FOOTER_MENU_FAIL = 'UPDATE_FOOTER_MENU_FAIL';

// Delete Footer Menu
export const DELETE_FOOTER_MENU_ATTEMPT = 'DELETE_FOOTER_MENU_ATTEMPT';
export const DELETE_FOOTER_MENU_SUCCESS = 'DELETE_FOOTER_MENU_SUCCESS';
export const DELETE_FOOTER_MENU_FAIL = 'DELETE_FOOTER_MENU_FAIL';

// Create Category Content
export const CREATE_SERVICES_CATEGORY_ATTEMPT = 'CREATE_SERVICES_CATEGORY_ATTEMPT';
export const CREATE_SERVICES_CATEGORY_SUCCESS = 'CREATE_SERVICES_CATEGORY_SUCCESS';
export const CREATE_SERVICES_CATEGORY_FAIL = 'CREATE_SERVICES_CATEGORY_FAIL';

// Package Service
// Create Package Service
export const ADD_PACKAGE_SERVICE_ATTEMPT = 'ADD_PACKAGE_SERVICE_ATTEMPT';
export const ADD_PACKAGE_SERVICE_SUCCESS = 'ADD_PACKAGE_SERVICE_SUCCESS';
export const ADD_PACKAGE_SERVICE_FAIL = 'ADD_PACKAGE_SERVICE_FAIL';

// Single Package Service
export const SINGLE_PACKAGE_SERVICE_ATTEMPT = 'SINGLE_PACKAGE_SERVICE_ATTEMPT';
export const SINGLE_PACKAGE_SERVICE_SUCCESS = 'SINGLE_PACKAGE_SERVICE_SUCCESS';
export const SINGLE_PACKAGE_SERVICE_FAIL = 'SINGLE_PACKAGE_SERVICE_FAIL';

// Get All Package Service
export const GET_PACKAGE_SERVICES_ATTEMPT = 'GET_PACKAGE_SERVICES_ATTEMPT';
export const GET_PACKAGE_SERVICES_SUCCESS = 'GET_PACKAGE_SERVICES_SUCCESS';
export const GET_PACKAGE_SERVICES_FAIL = 'GET_PACKAGE_SERVICES_FAIL';

// Update Package Service
export const UPDATE_PACKAGE_SERVICE_ATTEMPT = 'UPDATE_PACKAGE_SERVICE_ATTEMPT';
export const UPDATE_PACKAGE_SERVICE_SUCCESS = 'UPDATE_PACKAGE_SERVICE_SUCCESS';
export const UPDATE_PACKAGE_SERVICE_FAIL = 'UPDATE_PACKAGE_SERVICE_FAIL';

// Delete Package Service
export const DELETE_PACKAGE_SERVICE_ATTEMPT = 'DELETE_PACKAGE_SERVICE_ATTEMPT';
export const DELETE_PACKAGE_SERVICE_SUCCESS = 'DELETE_PACKAGE_SERVICE_SUCCESS';
export const DELETE_PACKAGE_SERVICE_FAIL = 'DELETE_PACKAGE_SERVICE_FAIL';

// Single Service Content
// Create Single Service
export const ADD_SINGLE_SERVICE_ATTEMPT = 'ADD_SINGLE_SERVICE_ATTEMPT';
export const ADD_SINGLE_SERVICE_SUCCESS = 'ADD_SINGLE_SERVICE_SUCCESS';
export const ADD_SINGLE_SERVICE_FAIL = 'ADD_SINGLE_SERVICE_FAIL';

// Single Service
export const ALL_SINGLE_SERVICES_ATTEMPT = 'ALL_SINGLE_SERVICES_ATTEMPT';
export const ALL_SINGLE_SERVICES_SUCCESS = 'ALL_SINGLE_SERVICES_SUCCESS';
export const ALL_SINGLE_SERVICES_FAIL = 'ALL_SINGLE_SERVICES_FAIL';

// Get Single Services
export const GET_SINGLE_SERVICE_ATTEMPT = 'GET_SINGLE_SERVICE_ATTEMPT';
export const GET_SINGLE_SERVICE_SUCCESS = 'GET_SINGLE_SERVICE_SUCCESS';
export const GET_SINGLE_SERVICE_FAIL = 'GET_SINGLE_SERVICE_FAIL';

// Update Single Service
export const UPDATE_SINGLE_SERVICE_ATTEMPT = 'UPDATE_SINGLE_SERVICE_ATTEMPT';
export const UPDATE_SINGLE_SERVICE_SUCCESS = 'UPDATE_SINGLE_SERVICE_SUCCESS';
export const UPDATE_SINGLE_SERVICE_FAIL = 'UPDATE_SINGLE_SERVICE_FAIL';

// Delete Single Service
export const DELETE_SINGLE_SERVICE_ATTEMPT = 'DELETE_SINGLE_SERVICE_ATTEMPT';
export const DELETE_SINGLE_SERVICE_SUCCESS = 'DELETE_SINGLE_SERVICE_SUCCESS';
export const DELETE_SINGLE_SERVICE_FAIL = 'DELETE_SINGLE_SERVICE_FAIL';

// Blogs
// Create Blog
export const CREATE_BLOG_ATTEMPT = 'CREATE_BLOG_ATTEMPT';
export const CREATE_BLOG_SUCCESS = 'CREATE_BLOG_SUCCESS';
export const CREATE_BLOG_FAIL = 'CREATE_BLOG_FAIL';

// ALL BLOGS FEATURED
export const GET_ALL_BLOGS_ATTEMPT = 'GET_ALL_BLOGS_ATTEMPT';
export const GET_ALL_BLOGS_SUCCESS = 'GET_ALL_BLOGS_SUCCESS';
export const GET_ALL_BLOGS_FAIL = 'GET_ALL_BLOGS_FAIL';

// ALL BLOGS ACTIVE
export const ALL_ACTIVE_BLOGS_ATTEMPT = 'ALL_ACTIVE_BLOGS_ATTEMPT';
export const ALL_ACTIVE_BLOGS_SUCCESS = 'ALL_ACTIVE_BLOGS_SUCCESS';
export const ALL_ACTIVE_BLOGS_FAIL = 'ALL_ACTIVE_BLOGS_FAIL';

// Single Blog
export const SINGLE_BLOG_ATTEMPT = 'SINGLE_BLOG_ATTEMPT';
export const SINGLE_BLOG_SUCCESS = 'SINGLE_BLOG_SUCCESS';
export const SINGLE_BLOG_FAIL = 'SINGLE_BLOG_FAIL';

// Update Blog
export const UPDATE_BLOG_ATTEMPT = 'UPDATE_BLOG_ATTEMPT';
export const UPDATE_BLOG_SUCCESS = 'UPDATE_BLOG_SUCCESS';
export const UPDATE_BLOG_FAIL = 'UPDATE_BLOG_FAIL';

// Delete Blog
export const DELETE_BLOG_ATTEMPT = 'DELETE_BLOG_ATTEMPT';
export const DELETE_BLOG_SUCCESS = 'DELETE_BLOG_SUCCESS';
export const DELETE_BLOG_FAIL = 'DELETE_BLOG_FAIL';

// Blogs Categories
// Create Blog Categorey
export const CREATE_BLOG_CATEGORY_ATTEMPT = 'CREATE_BLOG_CATEGORY_ATTEMPT';
export const CREATE_BLOG_CATEGORY_SUCCESS = 'CREATE_BLOG_CATEGORY_SUCCESS';
export const CREATE_BLOG_CATEGORY_FAIL = 'CREATE_BLOG_CATEGORY_FAIL';

// Single Blog Categorey
export const SINGLE_BLOG_CATEGORY_ATTEMPT = 'SINGLE_BLOG_CATEGORY_ATTEMPT';
export const SINGLE_BLOG_CATEGORY_SUCCESS = 'SINGLE_BLOG_CATEGORY_SUCCESS';
export const SINGLE_BLOG_CATEGORY_FAIL = 'SINGLE_BLOG_CATEGORY_FAIL';

// Single Blog Categorey
export const UPDATE_BLOG_CATEGORY_ATTEMPT = 'UPDATE_BLOG_CATEGORY_ATTEMPT';
export const UPDATE_BLOG_CATEGORY_SUCCESS = 'UPDATE_BLOG_CATEGORY_SUCCESS';
export const UPDATE_BLOG_CATEGORY_FAIL = 'UPDATE_BLOG_CATEGORY_FAIL';

// Delete Blog Categorey
export const DELETE_BLOG_CATEGORY_ATTEMPT = 'DELETE_BLOG_CATEGORY_ATTEMPT';
export const DELETE_BLOG_CATEGORY_SUCCESS = 'DELETE_BLOG_CATEGORY_SUCCESS';
export const DELETE_BLOG_CATEGORY_FAIL = 'DELETE_BLOG_CATEGORY_FAIL';

// For Stripe Settings
// Create Stripe Setting
export const CREATE_STRIPE_SETTING_ATTEMPT = 'CREATE_STRIPE_SETTING_ATTEMPT';
export const CREATE_STRIPE_SETTING_SUCCESS = 'CREATE_STRIPE_SETTING_SUCCESS';
export const CREATE_STRIPE_SETTING_FAIL = 'CREATE_STRIPE_SETTING_FAIL';

// Single Stripe Setting
export const SINGLE_STRIPE_SETTING_ATTEMPT = 'SINGLE_STRIPE_SETTING_ATTEMPT';
export const SINGLE_STRIPE_SETTING_SUCCESS = 'SINGLE_STRIPE_SETTING_SUCCESS';
export const SINGLE_STRIPE_SETTING_FAIL = 'SINGLE_STRIPE_SETTING_FAIL';

// Get All Stripe Settings
export const GET_STRIPE_SETTINGS_ATTEMPT = 'GET_STRIPE_SETTINGS_ATTEMPT';
export const GET_STRIPE_SETTINGS_SUCCESS = 'GET_STRIPE_SETTINGS_SUCCESS';
export const GET_STRIPE_SETTINGS_FAIL = 'GET_STRIPE_SETTINGS_FAIL';

// Update Stripe Setting
export const UPDATE_STRIPE_SETTING_ATTEMPT = 'UPDATE_STRIPE_SETTING_ATTEMPT';
export const UPDATE_STRIPE_SETTING_SUCCESS = 'UPDATE_STRIPE_SETTING_SUCCESS';
export const UPDATE_STRIPE_SETTING_FAIL = 'UPDATE_STRIPE_SETTING_FAIL';

// Delete Stripe Setting
export const DELETE_STRIPE_SETTING_ATTEMPT = 'DELETE_STRIPE_SETTING_ATTEMPT';
export const DELETE_STRIPE_SETTING_SUCCESS = 'DELETE_STRIPE_SETTING_SUCCESS';
export const DELETE_STRIPE_SETTING_FAIL = 'DELETE_STRIPE_SETTING_FAIL';

// For Blocklist
// Create Blocklist
export const CREATE_BLOCKLIST_ATTEMPT = 'CREATE_BLOCKLIST_ATTEMPT';
export const CREATE_BLOCKLIST_SUCCESS = 'CREATE_BLOCKLIST_SUCCESS';
export const CREATE_BLOCKLIST_FAIL = 'CREATE_BLOCKLIST_FAIL';

// Single Blocklist
export const SINGLE_BLOCKLIST_ATTEMPT = 'SINGLE_BLOCKLIST_ATTEMPT';
export const SINGLE_BLOCKLIST_SUCCESS = 'SINGLE_BLOCKLIST_SUCCESS';
export const SINGLE_BLOCKLIST_FAIL = 'SINGLE_BLOCKLIST_FAIL';

// Update Blocklist
export const UPDATE_BLOCKLIST_ATTEMPT = 'UPDATE_BLOCKLIST_ATTEMPT';
export const UPDATE_BLOCKLIST_SUCCESS = 'UPDATE_BLOCKLIST_SUCCESS';
export const UPDATE_BLOCKLIST_FAIL = 'UPDATE_BLOCKLIST_FAIL';

// Delete Blocklist
export const DELETE_BLOCKLIST_ATTEMPT = 'DELETE_BLOCKLIST_ATTEMPT';
export const DELETE_BLOCKLIST_SUCCESS = 'DELETE_BLOCKLIST_SUCCESS';
export const DELETE_BLOCKLIST_FAIL = 'DELETE_BLOCKLIST_FAIL';

// For Coupons
// Create Coupon
export const CREATE_COUPON_ATTEMPT = 'CREATE_COUPON_ATTEMPT';
export const CREATE_COUPON_SUCCESS = 'CREATE_COUPON_SUCCESS';
export const CREATE_COUPON_FAIL = 'CREATE_COUPON_FAIL';

// Single Coupon
export const SINGLE_COUPON_ATTEMPT = 'SINGLE_COUPON_ATTEMPT';
export const SINGLE_COUPON_SUCCESS = 'SINGLE_COUPON_SUCCESS';
export const SINGLE_COUPON_FAIL = 'SINGLE_COUPON_FAIL';

// Update Coupon
export const UPDATE_COUPON_ATTEMPT = 'UPDATE_COUPON_ATTEMPT';
export const UPDATE_COUPON_SUCCESS = 'UPDATE_COUPON_SUCCESS';
export const UPDATE_COUPON_FAIL = 'UPDATE_COUPON_FAIL';

// Delete Coupon
export const DELETE_COUPON_ATTEMPT = 'DELETE_COUPON_ATTEMPT';
export const DELETE_COUPON_SUCCESS = 'DELETE_COUPON_SUCCESS';
export const DELETE_COUPON_FAIL = 'DELETE_COUPON_FAIL';

// Reviews
// Create Review
export const CREATE_REVIEW_ATTEMPT = 'CREATE_REVIEW_ATTEMPT';
export const CREATE_REVIEW_SUCCESS = 'CREATE_REVIEW_SUCCESS';
export const CREATE_REVIEW_FAIL = 'CREATE_REVIEW_FAIL';

// Single Review
export const SINGLE_REVIEW_ATTEMPT = 'SINGLE_REVIEW_ATTEMPT';
export const SINGLE_REVIEW_SUCCESS = 'SINGLE_REVIEW_SUCCESS';
export const SINGLE_REVIEW_FAIL = 'SINGLE_REVIEW_FAIL';

// Get All Review
export const GET_REVIEWS_ATTEMPT = 'GET_REVIEWS_ATTEMPT';
export const GET_REVIEWS_SUCCESS = 'GET_REVIEWS_SUCCESS';
export const GET_REVIEWS_FAIL = 'GET_REVIEWS_FAIL';

// Update Review
export const UPDATE_REVIEW_ATTEMPT = 'UPDATE_REVIEW_ATTEMPT';
export const UPDATE_REVIEW_SUCCESS = 'UPDATE_REVIEW_SUCCESS';
export const UPDATE_REVIEW_FAIL = 'UPDATE_REVIEW_FAIL';

// Delete Review
export const DELETE_REVIEW_ATTEMPT = 'DELETE_REVIEW_ATTEMPT';
export const DELETE_REVIEW_SUCCESS = 'DELETE_REVIEW_SUCCESS';
export const DELETE_REVIEW_FAIL = 'DELETE_REVIEW_FAIL';

// Auth Action
export const IS_AUTH = 'IS_AUTH';

// Login User
export const LOGIN_USER_ATTEMPT = 'LOGIN_USER_ATTEMPT';
export const LOGIN_USER_FAIL = 'LOGIN_USER_FAIL';
export const LOGIN_USER_SUCCESS = 'LOGIN_USER_SUCCESS';

// Create User
export const CREATE_USER_ATTEMPT = 'CREATE_USER_ATTEMPT';
export const CREATE_USER_FAIL = 'CREATE_USER_FAIL';
export const CREATE_USER_SUCCESS = 'CREATE_USER_SUCCESS';

// Single User
export const SINGLE_USER_ATTEMPT = 'SINGLE_USER_ATTEMPT';
export const SINGLE_USER_FAIL = 'SINGLE_USER_FAIL';
export const SINGLE_USER_SUCCESS = 'SINGLE_USER_SUCCESS';

// Update User
export const UPDATE_USER_ATTEMPT = 'UPDATE_USER_ATTEMPT';
export const UPDATE_USER_FAIL = 'UPDATE_USER_FAIL';
export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS';

// GET ALL USERS
export const GET_ALL_USERS_ATTEMPT = 'GET_ALL_USERS_ATTEMPT';
export const GET_ALL_USERS_FAIL = 'GET_ALL_USERS_FAIL';
export const GET_ALL_USERS_SUCCESS = 'GET_ALL_USERS_SUCCESS';

// DELETE USER
export const DELETE_USER_ATTEMPT = 'DELETE_USER_ATTEMPT';
export const DELETE_USER_FAIL = 'DELETE_USER_FAIL';
export const DELETE_USER_SUCCESS = 'DELETE_USER_SUCCESS';

// FORGET PASSWORD
export const FORGET_PASSWORD_ATTEMPT = 'FORGET_PASSWORD_ATTEMPT';
export const FORGET_PASSWORD_FAIL = 'FORGET_PASSWORD_FAIL';
export const FORGET_PASSWORD_SUCCESS = 'FORGET_PASSWORD_SUCCESS';

// User Roles
// Create User Role
export const CREATE_USER_ROLE_ATTEMPT = 'CREATE_USER_ROLE_ATTEMPT';
export const CREATE_USER_ROLE_SUCCESS = 'CREATE_USER_ROLE_SUCCESS';
export const CREATE_USER_ROLE_FAIL = 'CREATE_USER_ROLE_FAIL';

// Single User Role
export const SINGLE_USER_ROLE_ATTEMPT = 'SINGLE_USER_ROLE_ATTEMPT';
export const SINGLE_USER_ROLE_SUCCESS = 'SINGLE_USER_ROLE_SUCCESS';
export const SINGLE_USER_ROLE_FAIL = 'SINGLE_USER_ROLE_FAIL';

// Get All User Role
export const GET_USER_ROLES_ATTEMPT = 'GET_USER_ROLES_ATTEMPT';
export const GET_USER_ROLES_SUCCESS = 'GET_USER_ROLES_SUCCESS';
export const GET_USER_ROLES_FAIL = 'GET_USER_ROLES_FAIL';

// Update User Role
export const UPDATE_USER_ROLE_ATTEMPT = 'UPDATE_USER_ROLE_ATTEMPT';
export const UPDATE_USER_ROLE_SUCCESS = 'UPDATE_USER_ROLE_SUCCESS';
export const UPDATE_USER_ROLE_FAIL = 'UPDATE_USER_ROLE_FAIL';

// Delete User Role
export const DELETE_USER_ROLE_ATTEMPT = 'DELETE_USER_ROLE_ATTEMPT';
export const DELETE_USER_ROLE_SUCCESS = 'DELETE_USER_ROLE_SUCCESS';
export const DELETE_USER_ROLE_FAIL = 'DELETE_USER_ROLE_FAIL';

// Faqs
// Create Faq
export const CREATE_FAQS_ATTEMPT = 'CREATE_FAQS_ATTEMPT';
export const CREATE_FAQS_SUCCESS = 'CREATE_FAQS_SUCCESS';
export const CREATE_FAQS_FAIL = 'CREATE_FAQS_FAIL';

// Single Faq
export const SINGLE_FAQS_ATTEMPT = 'SINGLE_FAQS_ATTEMPT';
export const SINGLE_FAQS_SUCCESS = 'SINGLE_FAQS_SUCCESS';
export const SINGLE_FAQS_FAIL = 'SINGLE_FAQS_FAIL';

// Get All Faqs
export const GET_FAQS_ATTEMPT = 'GET_FAQS_ATTEMPT';
export const GET_FAQS_SUCCESS = 'GET_FAQS_SUCCESS';
export const GET_FAQS_FAIL = 'GET_FAQS_FAIL';

// Update Faq
export const UPDATE_FAQS_ATTEMPT = 'UPDATE_FAQS_ATTEMPT';
export const UPDATE_FAQS_SUCCESS = 'UPDATE_FAQS_SUCCESS';
export const UPDATE_FAQS_FAIL = 'UPDATE_FAQS_FAIL';

// Delete Faq
export const DELETE_FAQS_ATTEMPT = 'DELETE_FAQS_ATTEMPT';
export const DELETE_FAQS_SUCCESS = 'DELETE_FAQS_SUCCESS';
export const DELETE_FAQS_FAIL = 'DELETE_FAQS_FAIL';

// For Orders
// Update Order Item
export const UPDATE_ORDER_ITEM_ATTEMPT = 'UPDATE_ORDER_ITEM_ATTEMPT';
export const UPDATE_ORDER_ITEM_SUCCESS = 'UPDATE_ORDER_ITEM_SUCCESS';
export const UPDATE_ORDER_ITEM_FAIL = 'UPDATE_ORDER_ITEM_FAIL';

// Place Order
export const UPDATE_PLACE_ORDER_ATTEMPT = 'UPDATE_PLACE_ORDER_ATTEMPT';
export const UPDATE_PLACE_ORDER_SUCCESS = 'UPDATE_PLACE_ORDER_SUCCESS';
export const UPDATE_PLACE_ORDER_FAIL = 'UPDATE_PLACE_ORDER_FAIL';

// Single Order
export const SINGLE_ORDER_ATTEMPT = 'SINGLE_ORDER_ATTEMPT';
export const SINGLE_ORDER_SUCCESS = 'SINGLE_ORDER_SUCCESS';
export const SINGLE_ORDER_FAIL = 'SINGLE_ORDER_FAIL';

// Get All Order
export const GET_ORDERS_ATTEMPT = 'GET_ORDERS_ATTEMPT';
export const GET_ORDERS_SUCCESS = 'GET_ORDERS_SUCCESS';
export const GET_ORDERS_FAIL = 'GET_ORDERS_FAIL';

// Categories Action
export const GET_CATEGORY_ATTEMPT = 'GET_CATEGORY_ATTEMPT';
export const GET_CATEGORY_FAIL = 'GET_CATEGORY_FAIL';
export const GET_CATEGORY_SUCCESS = 'GET_CATEGORY_SUCCESS';

// Add Category
export const CREATE_CATEGORY_ATTEMPT = 'CREATE_CATEGORY_ATTEMPT';
export const CREATE_CATEGORY_FAIL = 'CREATE_CATEGORY_FAIL';
export const CREATE_CATEGORY_SUCCESS = 'CREATE_CATEGORY_SUCCESS';

// Add Category
export const SINGLE_CATEGORY_ATTEMPT = 'SINGLE_CATEGORY_ATTEMPT';
export const SINGLE_CATEGORY_FAIL = 'SINGLE_CATEGORY_FAIL';
export const SINGLE_CATEGORY_SUCCESS = 'SINGLE_CATEGORY_SUCCESS';

// Update Category
export const UPDATE_CATEGORY_ATTEMPT = 'UPDATE_CATEGORY_ATTEMPT';
export const UPDATE_CATEGORY_FAIL = 'UPDATE_CATEGORY_FAIL';
export const UPDATE_CATEGORY_SUCCESS = 'UPDATE_CATEGORY_SUCCESS';

// Update Category
export const DELETE_CATEGORY_ATTEMPT = 'DELETE_CATEGORY_ATTEMPT';
export const DELETE_CATEGORY_FAIL = 'DELETE_CATEGORY_FAIL';
export const DELETE_CATEGORY_SUCCESS = 'DELETE_CATEGORY_SUCCESS';

// Category Content
export const DELETE_CATEGORY_DETAILS_ATTEMPT = 'DELETE_CATEGORY_DETAILS_ATTEMPT';
export const DELETE_CATEGORY_DETAILS_SUCCESS = 'DELETE_CATEGORY_DETAILS_SUCCESS';
export const DELETE_CATEGORY_DETAILS_FAIL = 'DELETE_CATEGORY_DETAILS_FAIL';

// Category Content
export const DELETE_CATEGORY_CONTENT_ATTEMPT = 'DELETE_CATEGORY_CONTENT_ATTEMPT';
export const DELETE_CATEGORY_CONTENT_SUCCESS = 'DELETE_CATEGORY_CONTENT_SUCCESS';
export const DELETE_CATEGORY_CONTENT_FAIL = 'DELETE_CATEGORY_CONTENT_FAIL';

// Packages
// Add Package
export const CREATE_PACKAGE_ATTEMPT = 'CREATE_PACKAGE_ATTEMPT';
export const CREATE_PACKAGE_FAIL = 'CREATE_PACKAGE_FAIL';
export const CREATE_PACKAGE_SUCCESS = 'CREATE_PACKAGE_SUCCESS';

// Add Category
export const SINGLE_PACKAGE_ATTEMPT = 'SINGLE_PACKAGE_ATTEMPT';
export const SINGLE_PACKAGE_FAIL = 'SINGLE_PACKAGE_FAIL';
export const SINGLE_PACKAGE_SUCCESS = 'SINGLE_PACKAGE_SUCCESS';

// Update Package
export const UPDATE_PACAKGE_ATTEMPT = 'UPDATE_PACAKGE_ATTEMPT';
export const UPDATE_PACAKGE_FAIL = 'UPDATE_PACAKGE_FAIL';
export const UPDATE_PACAKGE_SUCCESS = 'UPDATE_PACAKGE_SUCCESS';

// Delete Package
export const DELETE_PACAKGE_ATTEMPT = 'DELETE_PACAKGE_ATTEMPT';
export const DELETE_PACAKGE_FAIL = 'DELETE_PACAKGE_FAIL';
export const DELETE_PACAKGE_SUCCESS = 'DELETE_PACAKGE_SUCCESS';

// Delete Package Details
export const DELETE_PACKAGE_DETAILS_ATTEMPT = 'DELETE_PACKAGE_DETAILS_ATTEMPT';
export const DELETE_PACKAGE_DETAILS_FAIL = 'DELETE_PACKAGE_DETAILS_FAIL';
export const DELETE_PACKAGE_DETAILS_SUCCESS = 'DELETE_PACKAGE_DETAILS_SUCCESS';

// Pages
// Add Page Section
export const ADD_PAGE_ATTEMPT = 'ADD_PAGE_ATTEMPT';
export const ADD_PAGE_FAIL = 'ADD_PAGE_FAIL';
export const ADD_PAGE_SUCCESS = 'ADD_PAGE_SUCCESS';

// Get all pages content
export const GET_PAGE_CONTENT_ATTEMPT = 'GET_PAGE_CONTENT_ATTEMPT';
export const GET_PAGE_CONTENT_FAIL = 'GET_PAGE_CONTENT_FAIL';
export const GET_PAGE_CONTENT_SUCCESS = 'GET_PAGE_CONTENT_SUCCESS';

// Get service content by country
export const GET_SERVICE_ATTEMPT = 'GET_SERVICE_ATTEMPT';
export const GET_SERVICE_FAIL = 'GET_SERVICE_FAIL';
export const GET_SERVICE_SUCCESS = 'GET_SERVICE_SUCCESS';

// Add Page Section
export const ADD_PAGE_SECTION_ATTEMPT = 'ADD_PAGE_SECTION_ATTEMPT';
export const ADD_PAGE_SECTION_FAIL = 'ADD_PAGE_SECTION_FAIL';
export const ADD_PAGE_SECTION_SUCCESS = 'ADD_PAGE_SECTION_SUCCESS';

// All Page Sections
export const GET_PAGE_SECTION = 'GET_PAGE_SECTION';
export const GET_PAGE_SECTION_FAIL = 'GET_PAGE_SECTION_FAIL';
export const GET_PAGE_SECTION_SUCCESS = 'GET_PAGE_SECTION_SUCCESS';

// Single Page Section
export const SINGLE_PAGE_SECTION = 'SINGLE_PAGE_SECTION';
export const SINGLE_PAGE_SECTION_FAIL = 'SINGLE_PAGE_SECTION_FAIL';
export const SINGLE_PAGE_SECTION_SUCCESS = 'SINGLE_PAGE_SECTION_SUCCESS';

// Update Page Section
export const UPDATE_PAGE_SECTION = 'UPDATE_PAGE_SECTION';
export const UPDATE_PAGE_SECTION_FAIL = 'UPDATE_PAGE_SECTION_FAIL';
export const UPDATE_PAGE_SECTION_SUCCESS = 'UPDATE_PAGE_SECTION_SUCCESS';

// Edit Main Page
export const GET_PAGED_DATA = 'GET_PAGED_DATA';
export const UPDATE_PAGE = 'UPDATE_PAGE';

// Single Page Content - Slug
export const SINGLE_PAGE_CONTENT_ATTEMPT = 'SINGLE_PAGE_CONTENT_ATTEMPT';
export const SINGLE_PAGE_CONTENT_FAIL = 'SINGLE_PAGE_CONTENT_FAIL';
export const SINGLE_PAGE_CONTENT_SUCCESS = 'SINGLE_PAGE_CONTENT_SUCCESS';

// Update activity log
export const ADD_ACTIVITY_ATTEMPT = 'ADD_ACTIVITY_ATTEMPT';
export const ADD_ACTIVITY_SUCCESS = 'ADD_ACTIVITY_SUCCESS';
export const ADD_ACTIVITY_FAIL = 'ADD_ACTIVITY_FAIL';

// Get activity log
export const GET_ACTIVITIES_ATTEMPT = 'GET_ACTIVITIES_ATTEMPT';
export const GET_ACTIVITIES_SUCCESS = 'GET_ACTIVITIES_SUCCESS';
export const GET_ACTIVITIES_FAIL = 'GET_ACTIVITIES_FAIL';