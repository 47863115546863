import { toast } from 'react-toastify';
import axios from 'axios';
import * as types from '../constants/actionTypes';
import { request } from '../helpers/axiosRequest';
import { logUserActivity } from './activityLogsActions';

export const API = axios.create({ baseURL: process.env.REACT_APP_API_URL });

// Add Package Service
export const addService = (data, navigate) => async (dispatch) => {
  try {
    dispatch({ type: types.ADD_PACKAGE_SERVICE_ATTEMPT });
    const res = await request({
      url: `${process.env.REACT_APP_API_URL}/service/add-new`,
      auth: true,
      method: 'POST',
      data
    });
    toast.success('Service Added Successsully', {
      position: 'top-center',
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      draggable: true,
      progress: undefined
    });
    dispatch({
      type: types.ADD_PACKAGE_SERVICE_SUCCESS,
      payload: { ...data }
    });
    dispatch(logUserActivity(`ADDED_SERVICE`));
    setTimeout(() => {
      navigate('/dashboard/services');
    }, 1000);
  } catch (err) {
    dispatch({ type: types.ADD_PACKAGE_SERVICE_FAIL, payload: err });
    toast.error('Unable to Add Service.', {
      position: 'top-center',
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      draggable: true,
      progress: undefined
    });
    throw err;
  }
};

// Get Single Package Service
export const singleService = (serviceId) => async (dispatch) => {
  dispatch({ type: types.SINGLE_PACKAGE_SERVICE_ATTEMPT });
  try {
    const res = await request({
      url: `${process.env.REACT_APP_API_URL}/service/get-by-id`,
      auth: true,
      method: 'POST',
      data: { id: serviceId }
    });
    dispatch({
      type: types.SINGLE_PACKAGE_SERVICE_SUCCESS,
      payload: res.data
    });
  } catch (err) {
    dispatch({ type: types.SINGLE_PACKAGE_SERVICE_FAIL, payload: err });
    throw err;
  }
};

// Update Package Service
export const updateService = (data, navigate) => async (dispatch) => {
  try {
    dispatch({ type: types.UPDATE_PACKAGE_SERVICE_ATTEMPT });
    const res = await request({
      url: `${process.env.REACT_APP_API_URL}/service/update-by-id`,
      auth: true,
      method: 'PUT',
      data
    });

    dispatch({
      type: types.UPDATE_PACKAGE_SERVICE_SUCCESS,
      payload: { ...data }
    });
    toast.success('Service Updated Successsully', {
      position: 'top-center',
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      draggable: true,
      progress: undefined
    });
    dispatch(logUserActivity(`UPDATED_SERVICE`));
    setTimeout(() => {
      navigate('/dashboard/services');
    }, 1000);
  } catch (err) {
    dispatch({ type: types.UPDATE_PACKAGE_SERVICE_FAIL, payload: err });
    toast.error('Unable to Update Service.', {
      position: 'top-center',
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      draggable: true,
      progress: undefined
    });
    throw err;
  }
};

// Delete Package Service
export const deleteService = (id) => async (dispatch) => {
  try {
    dispatch({ type: types.DELETE_PACKAGE_SERVICE_ATTEMPT });
    const res = await request({
      method: 'POST',
      auth: true,
      url: `${process.env.REACT_APP_API_URL}/service/delete-by-id`,
      data: { id: id }
    });

    toast.success('Service Deleted Successsully', {
      position: 'top-center',
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      draggable: true,
      progress: undefined
    });
    dispatch(logUserActivity(`DELETED_SERVICE`));
    setTimeout(() => {
      window.location.href = '/dashboard/services';
    }, 1000);

    dispatch({ type: types.DELETE_PACKAGE_SERVICE_SUCCESS, payload: {} });
  } catch (err) {
    dispatch({ type: types.DELETE_PACKAGE_SERVICE_FAIL, payload: err });
    toast.error('Unable to Delete Service', {
      position: 'top-center',
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      draggable: true,
      progress: undefined
    });
    throw new Error(err);
  }
};
