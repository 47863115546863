import { combineReducers } from 'redux';
import countryReducer from './countryReducer';
import authReducer from './authReducer';
import footerReducer from './footerReducer';
import stripeSettingReducer from './stripeSettingReducer';
import reviewsReducer from './reviewsReducer';
import blogsReducer from './blogsReducer';
import menuReducer from './menuReducers';
import ordersReducer from './ordersReducer';
import packageServiceReducer from './packageServiceReducer';
import couponsReducer from './couponsReducer';
import singleServiceReducer from './singleServiceReducer';
import faqsReducers from './faqsReducers';
import categoryReducer from './categoryReducer';
import packagesReducer from './packagesReducer';
import pageSectionReducer from './pageSectionReducer';
import blocklistReducer from './blocklistReducer';
import activityLogReducer from './activityLogReducer';

export default combineReducers({
  countries: countryReducer,
  menus: menuReducer,
  auth: authReducer,
  reviews: reviewsReducer,
  categories: categoryReducer,
  packages: packagesReducer,
  orders: ordersReducer,
  blogs: blogsReducer,
  footerContent: footerReducer,
  stripeSetting: stripeSettingReducer,
  services: packageServiceReducer,
  singleService: singleServiceReducer,
  coupons: couponsReducer,
  user: blocklistReducer,
  faqs: faqsReducers,
  pageSections: pageSectionReducer,
  activityLog: activityLogReducer
});
