import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
//
import * as Yup from 'yup';
import { Formik, Field, Form, ErrorMessage, FieldArray } from 'formik';
import { TextField } from 'formik-mui';
// material
import Chip from '@mui/material/Chip';
import {
  Typography,
  Container,
  Grid,
  FormLabel,
  Button,
  CircularProgress,
  MenuItem
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
// ----------------------------------------------------------------------
import RichTextEditor from 'src/components/RichTextEditor';
import { createFaq } from 'src/Redux/actions/faqsActions';
import { getCountries, getServices } from 'src/api/api';

export default function AddFaq() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  // Get all services
  const [servicesLoading, setServicesLoading] = useState(false);
  const [services, setServices] = useState([]);
  // Get all Countries
  const [countryLoading, setCountryLoading] = useState(false);
  const [countries, setCountries] = useState([]);

  // Get ALL Country
  useEffect(() => {
    setCountryLoading(true);
    const promise = getCountries();
    Promise.resolve(promise).then((data) => {
      if (data.success) {
        const countries = data?.countries;
        if (countries && countries?.length) {
          setCountries(data?.countries);
        }
        setCountryLoading(false);
      } else {
        setCountries([]);
        setCountryLoading(false);
      }
    });
  }, []);
  // Get ALL Services
  useEffect(() => {
    setServicesLoading(true);
    const promise = getServices();
    Promise.resolve(promise).then((data) => {
      if (data.success) {
        const services = data?.services;
        if (services && services?.length) {
          setServices(data?.services);
        }
        setServicesLoading(false);
      } else {
        setServices([]);
        setServicesLoading(false);
      }
    });
  }, []);

  // Validation Schema for From
  const FaqSchema = Yup.object().shape({
    mainHeading: Yup.string().required('Heading is required')
  });

  return (
    <Container>
      <Grid container justifyContent="center">
        <Grid item lg={10} md={10} xs={10} sm={10}>
          <Card
            sx={{ minWidth: 275, boxShadow: 3, borderRadius: 1, border: '1px solid #929eaa6b' }}
          >
            <CardContent>
              <Formik
                enableReinitialize
                initialValues={{
                  metaData: {
                    metaTitle: '',
                    metaDescription: '',
                    canonicalLink: ''
                  },
                  mainHeading: '',
                  mainParagraph: '',
                  country: '',
                  faqs: [{ title: '', description: '', serviceId: '' }]
                }}
                // validationSchema={FaqSchema}
                onSubmit={(values) => {
                  dispatch(createFaq(values, navigate));
                  setLoading(true);
                  setTimeout(() => {
                    setLoading(false);
                  }, 1000);
                }}
                render={({ values, setFieldValue }) => (
                  <Form autoComplete="off" style={{ padding: 'inherit' }}>
                    <Typography variant="h4" gutterBottom>
                      Add FAQ's
                    </Typography>
                    <br />
                    <Chip color="success" variant="outlined" label="Meta Data" />
                    <br />
                    <br />
                    <FormLabel htmlFor="metaData.metaTitle"> Meta Title </FormLabel>
                    <Field
                      id="metaData.metaTitle"
                      name="metaData.metaTitle"
                      type="text"
                      placeholder="Enter Meta Title"
                      className="input-style"
                    />
                    <FormLabel htmlFor="metaData.metaDescription"> Meta Description</FormLabel>
                    <Field
                      id="metaData.metaDescription"
                      name="metaData.metaDescription"
                      type="text"
                      placeholder="Enter Meta Description"
                      className="input-style"
                    />
                    <FormLabel htmlFor="metaData.canonicalLink"> Canonical Link</FormLabel>
                    <Field
                      id="metaData.canonicalLink"
                      name="metaData.canonicalLink"
                      type="text"
                      placeholder="Enter Canonical Link"
                      className="input-style"
                    />
                    <br />
                    <Chip color="success" variant="outlined" label="Page Data" />
                    <br />
                    <br />
                    <FormLabel htmlFor="mainHeading">Main Heading </FormLabel>
                    <Field
                      id="mainHeading"
                      name="mainHeading"
                      type="text"
                      placeholder="Frequently Asked Questions"
                      className="input-style"
                    />
                    <ErrorMessage name="mainHeading" component="div" className="error-color" />
                    <FormLabel htmlFor="mainParagraph"> Main Paragraph </FormLabel>
                    <Field
                      id="mainParagraph"
                      name="mainParagraph"
                      type="text"
                      placeholder="Paragraph"
                      className="input-style"
                    />
                    <ErrorMessage name="mainParagraph" component="div" className="error-color" />
                    <div className="input-section">
                      <FormLabel id="country">Country</FormLabel>
                      {countryLoading && <CircularProgress size={30} />}
                      {!countryLoading && (
                        <Field
                          name="country"
                          select
                          component={TextField}
                          sx={{ width: '95%' }}
                          label="Select Value"
                        >
                          <MenuItem value="">Not Selected</MenuItem>
                          {countries?.map((item) => {
                            if (item.active == true)
                              return (
                                <MenuItem
                                  key={item._id}
                                  value={item.countrySlug}
                                  label={item.countryName}
                                >
                                  {item.countryName}
                                </MenuItem>
                              );
                          })}
                        </Field>
                      )}
                    </div>

                    <div>
                      {values.faqs?.length &&
                        values.faqs?.map((items, idx) => {
                          return (
                            <FieldArray name="faqs">
                              {({ push, remove }) => (
                                <div className="row" key={idx} id={idx}>
                                  <div className="col button-style">
                                    <div>
                                      {idx !== 0 && (
                                        <Button
                                          type="button"
                                          sx={{ mt: 2, mb: 2 }}
                                          variant="contained"
                                          color="error"
                                          onClick={() => remove(idx)}
                                          className="home-section-delete-button"
                                        >
                                          Delete Column
                                        </Button>
                                      )}
                                      <Button
                                        type="button"
                                        variant="contained"
                                        color="secondary"
                                        sx={{ mt: 2, ml: idx !== 0 && 1, mb: 2 }}
                                        onClick={() =>
                                          push({
                                            title: '',
                                            description: ''
                                          })
                                        }
                                      >
                                        Add Column
                                      </Button>
                                    </div>
                                  </div>
                                  <Typography variant="h5">Question: {idx + 1}</Typography>
                                  <div className="input-section">
                                    <FormLabel id={`faqs[${idx}].serviceId`}>Service</FormLabel>
                                    {servicesLoading && <CircularProgress size={30} />}
                                    {!servicesLoading && (
                                      <Field
                                        name={`faqs[${idx}].serviceId`}
                                        select
                                        component={TextField}
                                        sx={{ width: '95%' }}
                                        label="Select Value"
                                      >
                                        <MenuItem value="">Not Selected</MenuItem>
                                        {services?.map((item) => {
                                          if (item.active == true)
                                            return (
                                              <MenuItem
                                                key={item._id}
                                                value={item._id}
                                                label={item.countryName}
                                              >
                                                {item.name}
                                              </MenuItem>
                                            );
                                        })}
                                      </Field>
                                    )}
                                  </div>
                                  <div className="input-section">
                                    <FormLabel htmlFor={`faqs[${idx}].title`}> Title </FormLabel>
                                    <Field
                                      id={`faqs[${idx}].title`}
                                      name={`faqs[${idx}].title`}
                                      type="text"
                                      placeholder="Frequently Asked Questions"
                                      className="input-style"
                                    />
                                    <ErrorMessage
                                      name={`faqs[${idx}].title`}
                                      component="div"
                                      className="error-color"
                                    />
                                  </div>
                                  <div className="input-section" style={{ marginBottom: '10px' }}>
                                    <FormLabel htmlFor={`faqs[${idx}].description`}>
                                      {' '}
                                      Description{' '}
                                    </FormLabel>
                                    <RichTextEditor
                                      setFieldValue={setFieldValue}
                                      name={`faqs[${idx}].description`}
                                    />
                                    <ErrorMessage
                                      name={`faqs[${idx}].description`}
                                      component="div"
                                      className="error-color"
                                    />
                                  </div>
                                </div>
                              )}
                            </FieldArray>
                          );
                        })}
                    </div>

                    <Grid container>
                      <Grid item xs={4} sm={4} md={3} lg={2} mt={3}>
                        <LoadingButton
                          fullWidth
                          size="large"
                          type="submit"
                          variant="contained"
                          loading={loading}
                        >
                          Submit
                        </LoadingButton>
                      </Grid>
                      <Grid item xs={4} sm={4} md={3} lg={2} ml={2} mt={3}>
                        <Button
                          fullWidth
                          size="large"
                          variant="contained"
                          color="error"
                          component={RouterLink}
                          to="/dashboard/pages/faqs"
                        >
                          Cancel
                        </Button>
                      </Grid>
                    </Grid>
                  </Form>
                )}
              />
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
}
