import React from 'react';
//
import ContentPasteIcon from '@mui/icons-material/ContentPaste';
import { Box, Typography, Chip, Grid } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
//
import FaqsAccordions from './FaqsAccordions';
import ActionButton from 'src/components/ActionButton';
import { deleteFaq } from 'src/Redux/actions/faqsActions';

export default function MenuTable(props) {
  const { data, menuId } = props;

  return (
    <>
      {Object.keys(data.faqsContent)?.length ? (
        <Grid container spacing={2}>
          <Grid item lg={12} md={12} xs={12} mt={2}>
            <Grid container>
              <Grid
                item
                lg={11}
                md={11}
                xs={11}
                mt={2}
                sx={{ display: 'flex', justifyContent: 'end' }}
              >
                <ActionButton
                  id={menuId}
                  action={deleteFaq}
                  link={`/dashboard/pages/faqs/edit-faq/${menuId}`}
                  name="Faqs Content"
                />
              </Grid>
              <Grid item lg={12} md={12} xs={12} mt={2} textAlign="center">
                <Typography variant="h4">{data.faqsContent.mainHeading}</Typography>
                <Typography variant="p">{data.faqsContent.mainParagraph}</Typography>
              </Grid>
              <Grid container>
                <Grid lg={6} md={6} xs={10} sm={10} mb={1} item m="auto" mt={2}>
                  <Box
                    sx={{
                      maxWidth: '100%',
                      borderRadius: '8px',
                      border: '1px solid #929eaa6b',
                      p: '20px 0 15px 30px'
                    }}
                  >
                    <Chip
                      color="success"
                      variant="outlined"
                      label="Page Meta Data"
                      deleteIcon={<ContentPasteIcon />}
                    />

                    <Typography variant="body2" gutterBottom mt={1}>
                      <strong>Page Name:</strong> FAQ's
                    </Typography>
                    <Typography variant="body2" gutterBottom>
                      <strong>Meta Title:</strong> {data.faqsContent.metaData?.metaTitle}
                    </Typography>
                    <Typography variant="body2" gutterBottom>
                      <strong>Meta Description:</strong>{' '}
                      {data.faqsContent.metaData?.metaDescription}
                    </Typography>
                    <Typography variant="body2" gutterBottom>
                      <strong>Canonical Link:</strong> {data.faqsContent.metaData?.canonicalLink}
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
              <Grid item lg={12} md={12} xs={12} mt={2} textAlign="center">
                <FaqsAccordions faqs={data.faqsContent?.faqs} />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      ) : (
        <Typography mt={2} ml={2} mb={2} variant="h5" align="center">
          No Page Content
        </Typography>
      )}
    </>
  );
}
