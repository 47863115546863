import * as types from '../constants/actionTypes';

const initialState = {
  items: [],
  loading: true,
  countries: {}
};

export default function (state = initialState, action) {
  switch (action.type) {
    case types.ADD_COUNTRY_SUCCESS:
      return {
        ...state,
        items: action.payload,
        countries: action.payload,
        loading: false
      };
    case types.GET_COUNTRIES_SUCCESS:
      return {
        ...state,
        items: action.payload,
        countries: action.payload,
        loading: false
      };
    case types.SINGLE_COUNTRY_SUCCESS:
      return {
        ...state,
        items: action.payload,
        countries: action.payload,
        loading: false
      };
    case types.UPDATE_COUNTRY_SUCCESS:
      return {
        ...state,
        items: action.payload,
        countries: action.payload,
        loading: false
      };
    case types.DELETE_COUNTRY_SUCCESS:
      return {
        ...state,
        items: action.payload,
        countries: action.payload,
        loading: false
      };
    default:
      return state;
  }
}
