import axios from 'axios';

axios.defaults.withCredentials = true;

export const API = axios.create({ baseURL: process.env.REACT_APP_API_URL });

// Get All Countries
export const getCountries = async (reqData) => {
  const url = 'country/get-all';
  return handleRequest(url, 'post', reqData);
};

// Get Blogs
export const getBlogs = async (reqData) => {
  const url = 'blogs/get-all';
  return handleRequest(url, 'get', reqData);
};
// Get Activity logs
export const getActivityLogs = async (reqData) => {
  const url = 'log/activity-log';
  return handleRequest(url, 'get', reqData);
};
// Get webhook data
export const getWebhookData = async (reqData) => {
  const url = 'stripe/get-webhooks';
  return handleRequest(url, 'post', reqData);
};
// Blog Categories
export const getBlogCategories = async (reqData) => {
  const url = 'blogs-category/get-all';
  return handleRequest(url, 'get', reqData);
};

// Get Packages
export const getPackages = async (reqData) => {
  const url = 'package/get-all';
  return handleRequest(url, 'get', reqData);
};

// Get Orders
export const getOrdersByPage= async (reqData) => {
  const url = `orders/get-all-by-page`;
  return handleRequest(url, 'post', reqData);
};
export const getOrders = async (reqData) => {
  const url = 'orders/get-all';
  return handleRequest(url, 'get', reqData);
};
export const deleteOrders = async (reqData) => {
  const url = 'orders/delete';
  return handleRequest(url, 'post', reqData);
};
// Get Stripe Settings List
export const getStripeSettingList = async (reqData) => {
  const url = 'stripe/get-settings';
  return handleRequest(url, 'get', reqData);
};
// Get Stripe Settings for single account
// export const getStripeSettings = async (reqData) => {
//   const url = 'stripe/get-settings';
//   return handleRequest(url, 'post', reqData);
// };
// Get All Users
export const getUsers = async (reqData) => {
  const url = 'users/get-all';
  return handleRequest(url, 'get', reqData);
};
// Get All Roles
export const getUserRoles = async (reqData) => {
  const url = 'users/get-all-roles';
  return handleRequest(url, 'get', reqData);
};
// Get All Services
export const getServices = async (reqData) => {
  const url = 'service/get-all';
  return handleRequest(url, 'get', reqData);
};

// Get Services with Categories
export const getServicesWithCategories = async (reqData) => {
  const url = 'service/get-all-with-categories';
  return handleRequest(url, 'get', reqData);
};

// Delete Category Content image
export const deleteContentImg = async (reqData) => {
  const url = 'category/delete-content-img';
  return handleRequest(url, 'DELETE', reqData);
};

// Get All Pages
export const getPageById = async (reqData) => {
  const url = `page/get-by-id/${reqData}`;
  return handleRequest(url, 'get', reqData);
}; // Get All Pages slug
export const getPageBySlug = async (reqData) => {
  const url = `page/get-by-slug`;
  return handleRequest(url, 'POST', reqData);
};
// Get All Pages
export const getAllStaticPages = async (reqData) => {
  const url = `page/get-all-static-pages`;
  return handleRequest(url, 'POST', reqData);
}
// Get All Faqs
export const getFaqsContent = async (reqData) => {
  const url = `faq/get-all`;
  return handleRequest(url, 'get', reqData);
};
// Get All Reviews
export const getReviews = async (reqData) => {
  const url = `reviews/get-all`;
  return handleRequest(url, 'get', reqData);
};
export const getBlocklist = async (reqData) => {
  const url = `blocklist/get-all`;
  return handleRequest(url, 'get', reqData);
};
// Update Stripe Settings
export const updateStripeSettings = async (reqData) => {
  const url = `${process.env.REACT_APP_API_URL}/stripe/update-settings`;
  return handleRequest(url, 'PUT', reqData);
};

// Add Stripe Settings Webhook
export const addStripeWebhook = async (reqData) => {
  const url = `${process.env.REACT_APP_API_URL}/stripe/add-webhook`;
  return handleRequest(url, 'POST', reqData);
};

// update Stripe Settings Webhook
export const updateStripeWebhook = async (reqData) => {
  const url = `${process.env.REACT_APP_API_URL}/stripe/update-webhook`;
  return handleRequest(url, 'PUT', reqData);
};

export const getRobotsContent = async () => {
  const url = `${process.env.REACT_APP_NODE_ROOT}/robots.txt`;
  try {
    return axios.get(url);
  } catch (error) {
    throw error;
  }
};

export const updateRobotsContent = async (reqData) => {
  const url = `${process.env.REACT_APP_NODE_ROOT}/robots.txt/update`;
  try {
    return axios.post(url, reqData);
  } catch (error) {
    throw error;
  }
};

const handleRequest = async (url, method, reqData = {}) => {
  try {
    const res = await API({
      method: method,
      url: url.endsWith('/') ? url : `${url}/`,
      data: reqData
    });

    return res.data;
  } catch (error) {
    var errMsg = '';
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      console.log(error.response.data);
      console.log(error.response.status);
      errMsg = error.response.data.message;
    } else if (error.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
      // http.ClientRequest in node.js
      console.log(error.request);
    } else {
      errMsg = error.message;
      // Something happened in setting up the request that triggered an Error
      console.log('Error', error.message);
    }
    throw error;
    // return {
    //   success: false,
    //   message: errMsg,
    //   error: error
    // };
  }
};
