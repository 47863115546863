import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
//
import * as Yup from 'yup';
import { Switch } from 'formik-mui';
import { Form, Formik, Field } from 'formik';
// material
import { Stack, Typography, Grid, FormLabel, TextField } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import FormControlLabel from '@mui/material/FormControlLabel';
//
import CategorySelect from './CategorySelect';
import RichTextEditor from 'src/components/RichTextEditor';
import CustomButton from 'src/components/Button';
//
import { getBlogCategories } from 'src/api/api';
import { createImageFile } from 'src/Redux/helpers/generalHelper'; // Create Image File
import { updateBlog, singleBlog, API } from 'src/Redux/actions/blogsActions'; // Actions
// ----------------------------------------------------------------------
const validTypes = [
  'image/jpeg',
  'image/jpg',
  'image/png',
  'image/svg+xml',
  'image/gif',
  'image/webp'
]; // Image Validation

export default function EditBlogForm() {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [thumbnail, setThumbnail] = useState('');
  // Categories
  const [categories, setCategories] = useState([]);
  const [categoryLoading, setCategoryLoading] = useState(false);
  // From Redux
  const pageState = useSelector((state) => state.blogs.items);
  const user = useSelector((state) => state.auth.items?.user) || null;

  useEffect(() => {
    const fetchData = () => {
      try {
        setLoading(true);
        dispatch(singleBlog({ _id: id }));
        setLoading(false);
      } catch (error) {
        setLoading(true);
      }
    };
    fetchData();
  }, []);

  // Get all Categories
  React.useEffect(() => {
    setCategoryLoading(true);
    getBlogCategories().then((data) => {
      if (data.success) {
        const categories = data?.categories;
        if (categories && categories?.length) {
          setCategories(data?.categories);
          setCategoryLoading(false);
        }
        setCategoryLoading(false);
      } else {
        setCategories([]);
        setCategoryLoading(false);
      }
    });
  }, []);

  // Validation Schema
  const BlogSchema = Yup.object().shape({
    title: Yup.string().required('Blog Title is required'),
    slug: Yup.string().required('Blog Slug is required'),
    body: Yup.string().required('Blog body is required'),
    postImg: Yup.string().required('Blog Image is required'),
    createdAt: Yup.date().required('Blog Date is required')
  });

  const validate = (value) => {
    let errorMessage;
    if (!/^[a-zA-Z0-9.-]+$/i.test(value)) {
      errorMessage = 'Slug should only contain alphanumeric chatarters, dots(.) or hyphens (-)';
    }
    return errorMessage;
  };

  return (
    <Grid container justifyContent="center">
      <Grid item lg={10} md={10} xs={10} sm={10}>
        <Card sx={{ minWidth: 275, boxShadow: 3, borderRadius: 1, border: '1px solid #929eaa6b' }}>
          <CardContent>
            <Formik
              enableReinitialize={true}
              initialValues={{
                title: pageState.title ? pageState.title : '',
                slug: pageState.slug ? pageState.slug : '',
                categoryId: pageState.categoryId ? pageState.categoryId : '',
                metaTitle: pageState.metaTitle ? pageState.metaTitle : '',
                metaDescription: pageState.metaDescription ? pageState.metaDescription : '',
                canonicalLink: pageState.canonicalLink ? pageState.canonicalLink : '',
                body: pageState.body ? pageState.body : '',
                postImg: pageState.postImg ? pageState?.postImg : '',
                postThumbnail: pageState.postThumbnail ? pageState.postThumbnail : '',
                createdAt: pageState.createdAt ? pageState.createdAt.slice(0, 10) : '',
                featured: pageState.featured ? pageState.featured : false,
                active: pageState.active ? pageState.active : false
              }}
              validationSchema={BlogSchema}
              onSubmit={async (values) => {
                setLoading(true);
                const formData = new FormData();
                formData.append('_id', pageState._id);
                formData.append('userId', user._id);
                values.createdAt = new Date(values.createdAt).toISOString();

                Object.entries(values).map((item) => {
                  const key = item[0];
                  const value = item[1];
                  formData.append(key, value);
                });

                const newImage = values?.postImg,
                  currImage = pageState?.postImg;
                if (newImage !== currImage) {
                  formData.append('oldImg', pageState?.postImg);
                  formData.append('oldImgThumb', pageState?.postThumbnail);
                }
                dispatch(updateBlog(formData, navigate, setLoading));
              }}
            >
              {({ errors, touched, values, setFieldValue }) => (
                <Form autoComplete="off" style={{ padding: 'inherit' }}>
                  <Typography variant="h4" gutterBottom>
                    Edit Blog
                  </Typography>
                  <Stack spacing={1} mt={2}>
                    <FormLabel id="title">Title</FormLabel>
                    <Field
                      type="text"
                      id="title"
                      name="title"
                      className="input-style"
                      placeholder="Blog Title"
                      onChange={(e) => {
                        const value = e.target.value;
                        setFieldValue('title', value);
                        const slug = value.replace(/[\W_]/g, '-').toLowerCase().trim();
                        setFieldValue('slug', slug.endsWith('-') ? slug.slice(0, -1) : slug);
                      }}
                    />
                    {errors.title && touched.title ? (
                      <div className="error-color">{errors.title}</div>
                    ) : null}
                    <FormLabel id="slug">Slug</FormLabel>
                    <Field
                      type="text"
                      id="slug"
                      name="slug"
                      className="input-style"
                      placeholder="Blog Slug"
                      validate={validate}
                    />
                    {errors.slug && touched.slug ? (
                      <div className="error-color">{errors.slug}</div>
                    ) : null}

                    <FormLabel id="body">Description</FormLabel>
                    <RichTextEditor
                      name="body"
                      value={values?.body}
                      setFieldValue={setFieldValue}
                    />
                    {errors.body && touched.body ? (
                      <div className="error-color">{errors.body}</div>
                    ) : null}
                    <FormLabel id="categoryId">Blog Category</FormLabel>
                    {categoryLoading ? (
                      'Loading...'
                    ) : (
                      <CategorySelect
                        name="categoryId"
                        categories={categories}
                        value={values?.categoryId}
                      />
                    )}

                    <FormLabel id="metaTitle">Meta Title</FormLabel>
                    <Field
                      type="text"
                      id="metaTitle"
                      name="metaTitle"
                      className="input-style"
                      placeholder="Blog Description"
                    />
                    {errors.metaTitle && touched.metaTitle ? (
                      <div className="error-color">{errors.metaTitle}</div>
                    ) : null}
                    <FormLabel id="metaDescription">Meta Description</FormLabel>
                    <Field
                      type="text"
                      id="metaDescription"
                      name="metaDescription"
                      className="input-style"
                      placeholder="Blog Description"
                    />
                    {errors.metaDescription && touched.metaDescription ? (
                      <div className="error-color">{errors.metaDescription}</div>
                    ) : null}
                    <FormLabel id="canonicalLink">Canonical Link</FormLabel>
                    <Field
                      type="text"
                      id="canonicalLink"
                      name="canonicalLink"
                      className="input-style"
                      placeholder="Blog Canonical Link"
                    />
                    {errors.canonicalLink && touched.canonicalLink ? (
                      <div className="error-color">{errors.canonicalLink}</div>
                    ) : null}
                    <Grid container>
                      <Grid xs={12} sm={12} md={12} lg={12} mt={2}>
                        <FormLabel id="image">Preview (Old Image)</FormLabel>
                        <img
                          src={`${process.env.REACT_APP_NODE_ROOT}/uploads/blog_images/${pageState?.postImg}`}
                          alt={pageState?.title}
                          width={200}
                          height={200}
                        />
                      </Grid>
                      <Grid xs={12} sm={12} md={6} lg={6} mt={2}>
                        <FormLabel id="postImg">Upload New Image</FormLabel>
                        {thumbnail && (
                          <img
                            src={thumbnail}
                            width={200}
                            height={200}
                            alt="New blog image preview"
                          />
                        )}
                        <TextField
                          className="blog-post-image"
                          id="postImg"
                          name="postImg"
                          type="file"
                          helperText="Required Image Dimensions: 800 x 500"
                          FormHelperTextProps={{
                            className: 'helper-text-style'
                          }}
                          onChange={(e) => {
                            const image = e.target.files[0];
                            if (!validTypes.includes(image.type)) {
                              toast.error(
                                'Please select an image, allowed file types are .jpg, .jpeg, .png & .webp'
                              );
                              return false;
                            }
                            const fileReader = new FileReader();
                            fileReader.onload = () => {
                              if (fileReader.readyState === 2) {
                                setFieldValue('postImg', image);
                                setThumbnail(fileReader.result);
                              }
                            };
                            fileReader.readAsDataURL(image);
                          }}
                        />
                        {errors.postImg && touched.postImg ? (
                          <div className="error-color">{errors.postImg}</div>
                        ) : null}
                      </Grid>
                    </Grid>
                    <FormLabel id="createdAt">Created At</FormLabel>
                    <Grid>
                      <Grid item lg={3} md={5} xs={10} sm={10}>
                        <Field
                          type="date"
                          id="createdAt"
                          name="createdAt"
                          className="input-style"
                          placeholder="Blog Date"
                        />
                        {errors.createdAt && touched.createdAt ? (
                          <div className="error-color">{errors.createdAt}</div>
                        ) : null}
                      </Grid>
                    </Grid>
                    <FormLabel id="featured">Featured</FormLabel>
                    <Grid>
                      <Grid item lg={5} md={5} xs={10} sm={10}>
                        <FormControlLabel
                          control={<Field component={Switch} type="checkbox" name="featured" />}
                          label={values.featured === true ? 'Featured' : 'Non-Featured'}
                        />
                      </Grid>
                    </Grid>
                    <FormLabel id="active">Status</FormLabel>
                    <Grid>
                      <Grid item lg={5} md={5} xs={10} sm={10}>
                        <FormControlLabel
                          control={<Field component={Switch} type="checkbox" name="active" />}
                          label={values.active === true ? 'Active' : 'In-Active'}
                        />
                      </Grid>
                    </Grid>
                  </Stack>
                  <Grid container>
                    <Grid item xs={5} sm={5} md={3} lg={2} mt={3}>
                      <LoadingButton
                        fullWidth
                        size="large"
                        type="submit"
                        variant="contained"
                        loading={loading}
                      >
                        Submit
                      </LoadingButton>
                    </Grid>
                    <Grid item xs={5} sm={5} md={3} lg={2} ml={2} mt={3}>
                      <CustomButton
                        btnText="Cancel"
                        variant="contained"
                        color="error"
                        link="/dashboard/blogs"
                        size="large"
                      />
                    </Grid>
                  </Grid>
                </Form>
              )}
            </Formik>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
}
