import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
// MUI Components
import { Container, Stack, Typography, Button } from '@mui/material';
// Custom Components
import Page from 'src/components/Page';
import Iconify from 'src/components/Iconify';
import FooterMenuTabs from './components/FooterMenuTabs';

function FooterContent() {
  return (
    <Page title="Dashboard Pages | Footer Content">
      <Container>
        <Stack direction="row" justifyContent="space-between" mb={1}>
          <Typography variant="h4" gutterBottom>
            Footer Content
          </Typography>
          <Button
            variant="contained"
            color="secondary"
            component={RouterLink}
            to="/dashboard/footer-content/add-footer-content"
            startIcon={<Iconify icon="ic:round-create" />}
          >
            Add Footer
          </Button>
        </Stack>
        <FooterMenuTabs />
      </Container>
    </Page>
  );
}

export default FooterContent;
