import { useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import { Formik, Form, Field } from 'formik';
// material
import { Stack, TextField, Typography, Button, Grid, FormLabel, FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import Rating from '@mui/material/Rating';
import { LoadingButton } from '@mui/lab';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
//
import { addReview, API } from 'src/Redux/actions/reviewsActions';
// import { createImageFile } from 'src/Redux/helpers/generalHelper';
//
import RichTextEditor from 'src/components/RichTextEditor';
// ----------------------------------------------------------------------
const validTypes = [
  'image/jpeg',
  'image/jpg',
  'image/png',
  'image/svg+xml',
  'image/gif',
  'image/webp'
]; // Image Validation

export default function AddReview() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [thumbnail, setThumbnail] = useState('');

  // Validation Schema for From
  const ReviewSchema = Yup.object().shape({
    lang: Yup.string().required('Language is required'),
    authorName: Yup.string().required('Author name is required'),
    authorEmail: Yup.string().required('Author email is required'),
    review: Yup.string().required('Review description is required')
  });

  return (
    <Grid container mx="auto" justifyContent="center">
      <Grid lg={8} md={8} xs={10} sm={10}>
        <Card sx={{ minWidth: 275, boxShadow: 3, borderRadius: 1, border: '1px solid #929eaa6b' }}>
          <CardContent>
            <Formik
              enableReinitialize={true}
              initialValues={{
                lang: '',
                authorName: '',
                authorEmail: '',
                authorUrl: '',
                rating: 5,
                profile_picture: '',
                review: ''
              }}
              validationSchema={ReviewSchema}
              onSubmit={async (values) => {
                setLoading(true);
                // Form Data
                const formData = new FormData();
                Object.entries(values).map((item) => {
                  const key = item[0];
                  const value = item[1];
                  formData.append(key, value);
                });
                dispatch(addReview(formData, navigate, setLoading));
              }}
            >
              {({ errors, touched, setFieldValue, values }) => (
                <Form autoComplete="off" style={{ padding: 'inherit' }}>
                  <Typography variant="h4" gutterBottom>
                    Add New Review
                  </Typography>
                  <Stack spacing={1} mt={2}>
                    <FormControl>
                    <FormLabel id="lang">Language</FormLabel>
                      <Field
                        name="lang"
                        id="lang"
                        as={Select}
                        placeholder="Select Language"
                        onChange={(event) => setFieldValue('lang', event.target.value)}
                      >
                        <MenuItem value="us">US</MenuItem>
                        <MenuItem value="it">IT</MenuItem>
                        <MenuItem value="es">ES</MenuItem>
                        <MenuItem value="de">DE</MenuItem>
                        <MenuItem value="au">AU</MenuItem>
                        <MenuItem value="uk">UK</MenuItem>
                        <MenuItem value="ca">CA</MenuItem>
                        <MenuItem value="fr">FR</MenuItem>
                      </Field>
                    </FormControl>
                    <FormLabel id="authorName">Author Name</FormLabel>
                    <Field
                      type="text"
                      id="authorName"
                      name="authorName"
                      className="input-style"
                      placeholder="Enter Author Name"
                    />
                    {errors.authorName && touched.authorName ? (
                      <div className="error-color">{errors.authorName}</div>
                    ) : null}
                    <FormLabel id="authorEmail">Author Email</FormLabel>
                    <Field
                      type="text"
                      id="authorEmail"
                      name="authorEmail"
                      className="input-style"
                      placeholder="Enter Author Email"
                    />
                    {errors.authorEmail && touched.authorEmail ? (
                      <div className="error-color">{errors.authorEmail}</div>
                    ) : null}
                    <FormLabel id="authorUrl">Author URL</FormLabel>
                    <Field
                      type="text"
                      id="authorUrl"
                      name="authorUrl"
                      className="input-style"
                      placeholder="Enter Author URL"
                    />
                    {errors.authorUrl && touched.authorUrl ? (
                      <div className="error-color">{errors.authorUrl}</div>
                    ) : null}
                    <FormLabel id="review">Review Description</FormLabel>
                    <RichTextEditor
                      name="review"
                      value={values?.review}
                      setFieldValue={setFieldValue}
                    />
                    {errors.review && touched.review ? (
                      <div className="error-color">{errors.review}</div>
                    ) : null}
                    <FormLabel id="rating">Rating</FormLabel>
                    <Rating
                      name="simple-controlled"
                      value={values.rating}
                      onChange={(e, newValue) => {
                        setFieldValue('rating', newValue);
                      }}
                    />
                    <Grid container>
                      <Grid xs={6} sm={6} md={6} lg={6} mt={2}>
                        <FormLabel id="profile_picture"> Upload Profile Picture</FormLabel>
                        {thumbnail && (
                          <img
                            alt="new image"
                            src={thumbnail}
                            width={250}
                            height={250}
                            style={{ objectFit: 'contain', objectPosition: 'top center' }}
                          />
                        )}
                        <TextField
                          sx={{ mt: 1 }}
                          id="file"
                          name="profile_picture"
                          type="file"
                          onChange={(e) => {
                            const image = e.target.files[0];
                            if (!validTypes.includes(image.type)) {
                              toast.error(
                                'Please select an image, allowed file types are .jpg, .jpeg & .png.'
                              );
                              return false;
                            }
                            const fileReader = new FileReader();
                            fileReader.onload = () => {
                              if (fileReader.readyState === 2) {
                                setFieldValue('profile_picture', image);
                                setThumbnail(fileReader.result);
                              }
                            };
                            fileReader.readAsDataURL(image);
                          }}
                        />
                        {errors.profile_picture && touched.profile_picture ? (
                          <div className="error-color">{errors.profile_picture}</div>
                        ) : null}
                      </Grid>
                    </Grid>
                  </Stack>
                  <Grid container>
                    <Grid xs={4} sm={4} md={3} lg={2} mt={3}>
                      <LoadingButton
                        fullWidth
                        size="large"
                        type="submit"
                        variant="contained"
                        loading={loading}
                      >
                        Submit
                      </LoadingButton>
                    </Grid>
                    <Grid xs={4} sm={4} md={3} lg={2} ml={2} mt={3}>
                      <Button
                        fullWidth
                        size="large"
                        variant="contained"
                        color="error"
                        component={RouterLink}
                        to="/dashboard/reviews"
                      >
                        Cancel
                      </Button>
                    </Grid>
                  </Grid>
                </Form>
              )}
            </Formik>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
}
