import React from 'react';
import { toast } from 'react-toastify';
//
import { TextField } from '@mui/material';
//
const validTypes = ['image/jpeg', 'image/png', 'image/jpg', 'image/gif', 'image/webp']; // Image Validation
const svgValidTypes = ['image/svg+xml']; // Image Validation

function SectionImg(props) {
  return (
    <div>
      <TextField
        sx={{
          mt: 1
        }}
        className="blog-post-image"
        id={props.name}
        name={props.name}
        type="file"
        helperText="Required Image Dimensions: 800 x 500"
        FormHelperTextProps={{
          className: 'helper-text-style'
        }}
        onChange={(e) => {
          const image = e.target.files[0];
          let svgImg = !props.imgType == '' ? svgValidTypes : validTypes;
          // let imageType = props.imgType ? props.imgType : image.type;
          if (!svgImg.includes(image.type)) {
            toast.error(
              `Please select an image, allowed file types are${
                props.imgType ? ' .svg.' : ' .jpg, .jpeg & .png.'
              }`
            );
            return false;
          }
          let imgLen = image.size <= 2000000;
          if (!imgLen) {
            toast.error('Image Size Should be less than 2Mbs.');
            return false;
          }
          const fileReader = new FileReader();
          fileReader.onload = () => {
            if (fileReader.readyState === 2) {
              props.setValue(props.name, fileReader.result);
            }
          };
          fileReader.readAsDataURL(image);
        }}
      />
    </div>
  );
}

export default SectionImg;
