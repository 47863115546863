import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
// Material
import { Container, Stack, Typography, Button } from '@mui/material';
// components
import Page from 'src/components/Page';
import Loader from 'src/components/Loader';
import { DataGrid } from '@mui/x-data-grid';
import { getAllStaticPages } from 'src/api/api';

function RefundPolicy() {
  const [loading, setLoading] = React.useState(false);
  const pageSlug = '/refund-policy';
  // Get Privacy Policy
  const [refundPolicy, setRefundPolicy] = React.useState([]);
  React.useEffect(async () => {
    setLoading(true);
    await getAllStaticPages({ slug: pageSlug }).then((data) => {
      if (data) {
        setRefundPolicy(data.data);
        setLoading(false);
      } else {
        setRefundPolicy([]);
        setLoading(false);
      }
    });
  }, []);

  // Privacy Table
  const columns = [
    { field: 'pageName', headerName: 'Page Name', width: 120 },
    { field: 'countrySlug', headerName: 'Country', width: 100, renderCell: (params) => params.value.toUpperCase()},
    {
      field: 'pageSlug',
      headerName: 'Page Slug',
      width: 130
    },
    {
      field: 'metaTitle',
      headerName: 'Meta Title',
      width: 140
    },
    {
      field: 'metaDescription',
      headerName: 'Meta Description',
      width: 150
    },

    {
      field: 'content',
      headerName: 'Content',
      width: 150
    },
    {
      field: 'canonicalLink',
      headerName: 'Canonical Link',
      width: 130
    },
    {
      field: 'action',
      headerName: 'Action',
      width: 130,
      renderCell: (rowData) => (
        <Button
          fullWidth
          size="medium"
          variant="contained"
          color="primary"
          component={RouterLink}
          to={`/dashboard/pages/edit-page${rowData.row.pageSlug}/${rowData.row.countrySlug}`}
        >
          Edit
        </Button>
      )
    }
  ];
  return (
    <Page title="Dashboard: Pages | Refund Policy">
      <Container>
        <Stack direction="row" justifyContent="space-between" mb={3}>
          <Typography variant="h4" gutterBottom>
            Refund Policy
          </Typography>
        </Stack>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={1}>
          <Typography variant="h6" gutterBottom>
            Data Table for Refund Policy
          </Typography>
        </Stack>
        {loading ? (
          <Loader />
        ) : (
          <Stack
            sx={{ boxShadow: 3, borderRadius: 1, border: '1px solid #929eaa6b', width: '100%' }}
          >
            <DataGrid
              rows={refundPolicy}
              columns={columns}
              getRowId={(row) => [row?._id]}
              pageSize={10}
              rowsPerPageOptions={[10]}
              disableSelectionOnClick
              // checkboxSelection
              autoHeight={true}
            />
          </Stack>
        )}
      </Container>
    </Page>
  );
}

export default RefundPolicy;
