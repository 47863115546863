import * as React from 'react';
import PropTypes from 'prop-types';
// MUI Components
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Tabs, { tabsClasses } from '@mui/material/Tabs';
//
import Loader from 'src/components/Loader';
import ActionButton from 'src/components/ActionButton';
import { deleteCountry } from 'src/Redux/actions/countryActions';
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`
  };
}

export default function CoutryTabs(props) {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      {props.loading && <Loader />}
      {!props.loading && (
        <Box sx={{ width: '100%', boxShadow: 3, borderRadius: 1, border: '1px solid #929eaa6b' }}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs
              value={value}
              onChange={handleChange}
              variant="scrollable"
              scrollButtons
              aria-label="visible arrows tabs example"
              sx={{
                [`& .${tabsClasses.scrollButtons}`]: {
                  '&.Mui-disabled': { opacity: '0.3' }
                }
              }}
            >
              {!props.loading &&
                props.data?.map((item, index) => (
                  <Tab label={item.countryName} {...a11yProps(index)} key={index} />
                ))}
            </Tabs>
          </Box>
          {!props.loading &&
            props.data?.map((item, index) => (
              <TabPanel value={value} index={index} key={index}>
                <Typography mt={1}>
                  <strong>Country Name: </strong>
                  {item.countryName}
                </Typography>
                <Typography mt={1}>
                  <strong>Country Slug: </strong>
                  {item.countrySlug}
                </Typography>
                <Typography mt={1}>
                  <strong>Currency Symbol: </strong>
                  {item.currencySymbol}
                </Typography>
                <Typography mt={1}>
                  <strong>Currency Code: </strong>
                  {item.currencyCode}
                </Typography>
                <Typography mt={1} mb={2}>
                  <strong>Active: </strong>
                  {item.active == true ? 'Active' : 'In-Active'}
                </Typography>
                <ActionButton
                  id={item.countrySlug}
                  style={{ mt: 2 }}
                  name="country"
                  action={deleteCountry}
                  link={`/dashboard/countries/${item.countrySlug}/edit`}
                />
              </TabPanel>
            ))}
        </Box>
      )}
    </>
  );
}
