import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { Form, Formik, Field, FieldArray } from 'formik';
import { TextField, Switch } from 'formik-mui';
import { toast } from 'react-toastify';
// material
import { Stack, Typography, Grid, FormLabel, MenuItem, Button, Chip } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import FormControlLabel from '@mui/material/FormControlLabel';
import { LoadingButton } from '@mui/lab';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
//
import CustomButton from 'src/components/Button';
//
import { getCountries, getServices } from 'src/api/api';
import { addCategory } from 'src/Redux/actions/categoryActions';
import AllCountriesSelect from 'src/pages/Packages/components/AllCountriesSelect';

export default function AddService() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const requiredField = <span className="text-danger">*</span>;

  //  Loading
  const [loading, setLoading] = useState(false);

  // Get Countries
  const [countryLoading, setCountryLoading] = useState(false);
  const [countryData, setCountryData] = useState([]);

  // Get service by Categories
  const [servicesCategories, setServicesCategories] = useState([]);
  const [serviceCategoriesLoading, setServiceCategoriesLoading] = useState(false);

  // Get All Services by Categories
  React.useEffect(() => {
    setServiceCategoriesLoading(true);
    const promise = getServices();
    Promise.resolve(promise).then((data) => {
      if (data.success) {
        const services = data?.services;
        if (services && services?.length) {
          setServicesCategories(data?.services);
        }
        setServiceCategoriesLoading(false);
      } else {
        setServicesCategories([]);
        setServiceCategoriesLoading(false);
      }
    });
  }, []);

  // Get ALL Countries
  React.useEffect(() => {
    setCountryLoading(true);
    const promise = getCountries();
    Promise.resolve(promise).then((data) => {
      if (data.success) {
        const countries = data?.countries;
        if (countries && countries?.length) {
          setCountryData(data?.countries);
        }
        setCountryLoading(false);
      } else {
        setCountryData([]);
        setCountryLoading(false);
      }
    });
  }, []);

  // Validation Schema
  const ServiceSchema = Yup.object().shape({
    name: Yup.string().required('Name is required'),
    service: Yup.string().required('Service is required'),
    serviceId: Yup.string().required('Service ID is required')
  });

  return (
    <Grid container justifyContent="center">
      <Grid item lg={8} md={8} xs={10} sm={10}>
        <Card sx={{ minWidth: 275, boxShadow: 3, borderRadius: 1, border: '1px solid #929eaa6b' }}>
          <CardContent>
            <Formik
              enableReinitialize={true}
              initialValues={{
                name: '',
                service: '',
                serviceId: '',
                allowMultipleLinks: false,
                active: true,
                details: [
                  {
                    title: '',
                    slug: '',
                    country: '',
                    metaTitle: '',
                    metaDescription: '',
                    canonicalLink: ''
                  }
                ]
              }}
              validationSchema={ServiceSchema}
              onSubmit={(values) => {
                // For Duplicate Countries
                var valueArr = values.details?.map(function (item) {
                  return item.country;
                });
                var isDuplicate = valueArr.some(function (item, idx) {
                  return valueArr.indexOf(item) != idx;
                });
                if (isDuplicate === true) {
                  toast.error('Duplicate Countries Found in this package.');
                  return false;
                }
                dispatch(addCategory(values, navigate));
                setLoading(true);
                setTimeout(() => {
                  setLoading(false);
                }, 1000);
              }}
            >
              {({ errors, touched, values, setFieldValue }) => (
                <Form autoComplete="off" style={{ padding: 'inherit' }}>
                  <Typography variant="h4" gutterBottom>
                    Add New Package Service
                  </Typography>
                  <Stack spacing={1} mt={2}>
                    <FormLabel id="service">Service Name</FormLabel>
                    {serviceCategoriesLoading && <CircularProgress size={30} />}
                    {!serviceCategoriesLoading && (
                      <Field
                        name="service"
                        value={values?.service}
                        select
                        component={TextField}
                        onChange={(e) => {
                          setFieldValue('service', e.target.value);
                        }}
                        sx={{ width: '95%' }}
                        label="Select Value"
                      >
                        <MenuItem value="">Not Selected</MenuItem>
                        {servicesCategories?.map((item) => {
                          return (
                            <MenuItem
                              key={item._id}
                              id={item._id}
                              value={item._id}
                              label={item.name}
                            >
                              {item.name}
                            </MenuItem>
                          );
                        })}
                      </Field>
                    )}
                    <FormLabel id="name">Name</FormLabel>
                    <Field
                      type="text"
                      id="name"
                      name="name"
                      defaultValue=""
                      className="input-style"
                      placeholder="e.g: Instagram Likes"
                    />
                    {errors.name && touched.name ? (
                      <div className="error-color">{errors.name}</div>
                    ) : null}
                    <FormLabel id="serviceId">Service Id</FormLabel>
                    <Field
                      defaultValue=""
                      type="number"
                      id="serviceId"
                      name="serviceId"
                      className="input-style"
                      placeholder="Service ID. e.g: 1"
                    />
                    {errors.serviceId && touched.serviceId ? (
                      <div className="error-color">{errors.serviceId}</div>
                    ) : null}
                    <FormLabel id="allowMultipleLinks">Allow Multiple Links</FormLabel>
                    <Grid>
                      <Grid item lg={5} md={5} xs={10} sm={10}>
                        <FormControlLabel
                          control={
                            <Field component={Switch} type="checkbox" name="allowMultipleLinks" />
                          }
                          label={values.allowMultipleLinks === true ? 'YES' : 'NO'}
                        />
                      </Grid>
                    </Grid>
                    <FormLabel id="active">Active</FormLabel>
                    <Grid>
                      <Grid item lg={5} md={5} xs={10} sm={10}>
                        <FormControlLabel
                          control={<Field component={Switch} type="checkbox" name="active" />}
                          label={values.active === true ? 'ACTIVE' : 'In-ACTIVE'}
                        />
                      </Grid>
                    </Grid>
                    <div>
                      <Chip
                        color="success"
                        variant="outlined"
                        label="Add / Update Categories With Country"
                        sx={{ mt: 1 }}
                      />
                      {values.details?.length &&
                        values.details?.map((items, idx) => {
                          return (
                            <FieldArray name="details" key={idx}>
                              {({ push, remove }) => (
                                <div className="row" key={idx} id={idx}>
                                  <div className="col button-style">
                                    <div>
                                      {!idx == 0 && (
                                        <Button
                                          type="button"
                                          sx={{ mt: 2, mb: 2 }}
                                          variant="contained"
                                          color="error"
                                          onClick={() => remove(idx)}
                                          className="home-section-delete-button"
                                        >
                                          Delete Column
                                        </Button>
                                      )}
                                      <Button
                                        type="button"
                                        variant="contained"
                                        color="secondary"
                                        sx={{ mt: 2, ml: !idx == 0 && 1, mb: 2 }}
                                        onClick={() =>
                                          push({
                                            title: '',
                                            slug: '',
                                            country: '',
                                            metaTitle: '',
                                            metaDescription: '',
                                            canonicalLink: ''
                                          })
                                        }
                                      >
                                        Add Column
                                      </Button>
                                    </div>
                                  </div>
                                  <Grid container>
                                    <Grid item lg={6} md={6} sm={12} xs={12} mt={1}>
                                      <FormLabel id={`details[${idx}].title`}>
                                        Title {requiredField}
                                      </FormLabel>
                                      <Field
                                        type="text"
                                        defaultValue=""
                                        id={`details[${idx}].title`}
                                        name={`details[${idx}].title`}
                                        className="input-style"
                                        placeholder="e.g: Buy Instagram Likes"
                                        required
                                      />
                                    </Grid>
                                    <Grid item lg={6} md={6} sm={12} xs={12} mt={1}>
                                      <FormLabel id={`details[${idx}].slug`}>
                                        Slug {requiredField}
                                      </FormLabel>
                                      <Field
                                        type="text"
                                        defaultValue=""
                                        id={`details[${idx}].slug`}
                                        name={`details[${idx}].slug`}
                                        className="input-style"
                                        placeholder="i.e: buy-instagram-likes"
                                        required
                                      />
                                    </Grid>
                                    <Grid item lg={6} md={6} sm={12} xs={12} mt={1}>
                                      {countryLoading ? (
                                        <CircularProgress size={30} />
                                      ) : (
                                        <AllCountriesSelect
                                          requiredField={requiredField}
                                          countries={countryData}
                                          name={`details[${idx}].country`}
                                        />
                                      )}
                                    </Grid>
                                    <Grid item lg={6} md={6} sm={6} xs={6} mt={1}>
                                      <FormLabel id={`details[${idx}].metaTitle`}>
                                        Meta Title
                                      </FormLabel>
                                      <Field
                                        type="text"
                                        defaultValue=""
                                        id={`details[${idx}].metaTitle`}
                                        name={`details[${idx}].metaTitle`}
                                        className="input-style"
                                        placeholder="Enter Meta Title"
                                      />
                                    </Grid>
                                    <Grid item lg={6} md={6} sm={6} xs={6} mt={1}>
                                      <FormLabel id={`details[${idx}].metaDescription`}>
                                        Meta Description
                                      </FormLabel>
                                      <Field
                                        type="text"
                                        defaultValue=""
                                        id={`details[${idx}].metaDescription`}
                                        name={`details[${idx}].metaDescription`}
                                        className="input-style"
                                        placeholder="Enter Meta Description"
                                      />
                                    </Grid>
                                    <Grid item lg={6} md={6} sm={6} xs={6} mt={1}>
                                      <FormLabel id={`details[${idx}].canonicalLink`}>
                                        Canonical Link
                                      </FormLabel>
                                      <Field
                                        type="text"
                                        defaultValue=""
                                        id={`details[${idx}].canonicalLink`}
                                        name={`details[${idx}].canonicalLink`}
                                        className="input-style"
                                        placeholder="Enter Canonical Link"
                                      />
                                    </Grid>
                                  </Grid>
                                </div>
                              )}
                            </FieldArray>
                          );
                        })}
                    </div>
                  </Stack>
                  <Grid container>
                    <Grid item xs={5} sm={5} md={3} lg={2} mt={3}>
                      <LoadingButton
                        fullWidth
                        size="large"
                        type="submit"
                        variant="contained"
                        loading={loading}
                      >
                        Submit
                      </LoadingButton>
                    </Grid>
                    <Grid item xs={5} sm={5} md={3} lg={2} ml={2} mt={3}>
                      <CustomButton
                        btnText="Cancel"
                        variant="contained"
                        color="error"
                        link="/dashboard/services"
                        size="large"
                      />
                    </Grid>
                  </Grid>
                </Form>
              )}
            </Formik>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
}
