import { useState, useEffect } from 'react';
import * as Yup from 'yup';
import { Link as RouterLink, useNavigate, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Formik, Field, Form, ErrorMessage } from 'formik';
// material
import { Typography, Container, Grid, FormLabel, Button, CircularProgress } from '@mui/material';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
//
import RichTextEditor from 'src/components/RichTextEditor';
import Loader from 'src/components/Loader';
// ----------------------------------------------------------------------
import { updatePage } from 'src/Redux/actions/pageSectionActions';
import { request } from 'src/Redux/helpers/axiosRequest';

//
const pageRoutes = {
  '/': '/home',
  '/about': '/about',
  '/faqs': '/faqs',
  '/contact': '/contact',
  '/privacy-policy': '/privacy-policy',
  '/refund-policy': '/refund-policy'
};

export default function EditPage() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { pageSlug, country } = useParams();
  //
  const [pageData, setPageData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [dataLoading, setDataLoading] = useState(false);

  useEffect(() => {
    setDataLoading(true);
    request({
      url: `${process.env.REACT_APP_API_URL}/page/get-by-slug`,
      method: 'POST',
      data: { slug: pageSlug.startsWith('/') ? pageSlug : `/${pageSlug}`, country }
    }).then(({ data }) => {
      setPageData(data);
      setDataLoading(false);
    });
  }, []);

  // Validation Schema for From
  const PageSchema = Yup.object({
    pageName: Yup.string().required('Page Name is required')
  });

  return (
    <Container>
      <Grid container justifyContent="center">
        <Grid item lg={10} md={10} xs={10} sm={10} mx="auto">
          <Card
            sx={{
              minWidth: 275,
              pl: 2,
              pt: 2,
              boxShadow: 1,
              borderRadius: 1,
              border: '1px solid #929eaa6b'
            }}
          >
            <CardContent>
              <Typography variant="h4" gutterBottom>
                Edit {pageData?.pageName}
              </Typography>
              {dataLoading && <Loader />}
              {!dataLoading && pageData && Object.keys(pageData).length > 0 && (
                <Formik
                  initialValues={{
                    pageName: pageData.pageName,
                    pageSlug: pageData.pageSlug,
                    metaTitle: pageData.metaTitle,
                    metaDescription: pageData.metaDescription,
                    canonicalLink: pageData.canonicalLink,
                    pageSlug: pageData.pageSlug,
                    country: pageData.country,
                    content: pageData.content
                  }}
                  enableReinitialize={true}
                  validationSchema={PageSchema}
                  onSubmit={async (values) => {
                    dispatch(
                      updatePage(values, navigate, `/dashboard/pages${pageRoutes[values.pageSlug]}`)
                    );
                    setLoading(true);
                    setTimeout(() => {
                      setTimeout(false);
                    }, 2000);
                  }}
                >
                  {({ values, setFieldValue }) => (
                    <Form>
                      <div className="col spacing-form-title">
                        <FormLabel htmlFor="pageName">Page Name</FormLabel>
                        <Field
                          id="pageName"
                          name="pageName"
                          type="text"
                          placeholder="Enter Page Name"
                          className="input-style"
                        />
                        <ErrorMessage name="pageName" component="div" className="error-color" />
                      </div>
                      <div className="col spacing-form-title">
                        <FormLabel htmlFor="pageSlug">Page Slug</FormLabel>
                        <Field
                          id="pageSlug"
                          name="pageSlug"
                          type="text"
                          placeholder="Enter Page Name"
                          className="input-style"
                          disabled
                        />
                        <ErrorMessage name="pageSlug" component="div" className="error-color" />
                        <p className="helper-text-style">Cannot change Page Slug.</p>
                      </div>
                      <div className="col spacing-form-title">
                        <FormLabel htmlFor="metaTitle">SEO Meta Title</FormLabel>
                        <Field
                          id="metaTitle"
                          name="metaTitle"
                          type="text"
                          placeholder="Enter Meta Title"
                          className="input-style"
                        />
                      </div>

                      <div className="col spacing-form-title">
                        <FormLabel htmlFor="metaDescription">SEO Meta Description</FormLabel>
                        <Field
                          id="metaDescription"
                          name="metaDescription"
                          type="text"
                          placeholder="Write Meta Description"
                          className="input-style"
                        />
                      </div>

                      <div className="col spacing-form-title">
                        <FormLabel htmlFor="canonicalLink">SEO Canonical Link</FormLabel>
                        <Field
                          id="canonicalLink"
                          name="canonicalLink"
                          type="text"
                          placeholder="Enter Canonical Link"
                          className="input-style"
                        />
                      </div>

                      {values.pageSlug !== '/faqs' && (
                        <div className="col spacing-form-title">
                          <FormLabel htmlFor="content"> Content </FormLabel>
                          <RichTextEditor
                            setFieldValue={setFieldValue}
                            name="content"
                            value={values?.content}
                          />
                          <ErrorMessage name="content" component="div" className="error-color" />
                        </div>
                      )}

                      <Grid container>
                        <Grid item xs={4} sm={4} md={3} lg={2} mt={3}>
                          <Button
                            variant="contained"
                            fullWidth
                            size="large"
                            type="submit"
                            disabled={loading}
                          >
                            <span style={{ marginRight: 10 }}>Submit</span>
                            {loading && <CircularProgress size={20} />}
                          </Button>
                        </Grid>{' '}
                        <Grid item xs={4} sm={4} md={3} lg={2} ml={2} mt={3}>
                          <Button
                            fullWidth
                            size="large"
                            variant="contained"
                            color="error"
                            component={RouterLink}
                            to={`/dashboard/pages${pageRoutes[values.pageSlug]}`}
                          >
                            Cancel
                          </Button>
                        </Grid>
                      </Grid>
                    </Form>
                  )}
                </Formik>
              )}
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
}
