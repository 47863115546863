import React, { useState } from 'react';
// material
import { Stack, Box, Typography, Button } from '@mui/material';
import { styled } from '@mui/material/styles';
import Tooltip from '@mui/material/Tooltip';
import { DataGrid } from '@mui/x-data-grid';
// components
import CustomButton from 'src/components/Button';
import AddWebhookDialog from './AddWebhookDialog';
import EditWebhookDialog from './EditWebhookDialog';
import Label from 'src/components/Label';

const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({}));

function WebhooksTable({ webhooks = [], stripe = {} }) {
  const [addwebhookDialogOpen, setAddWebhookDialogOpen] = useState(false);
  const [editwebhookDialogOpen, setEditWebhookDialogOpen] = useState(false);
  const [newStripeSettings, setNewStripeSettings] = useState(null);

  // Stripe Table
  const columns = [
    { field: 'id', headerName: 'ID', width: 100 },
    {
      field: 'url',
      headerName: 'URL',
      width: 350
    },
    {
      field: 'status',
      headerName: 'Status',
      width: 100,
      renderCell: (rowData) => (
        <Label color={rowData.row.status === 'enabled' ? 'success' : 'error'}>
          {rowData.row.status}
        </Label>
      )
    },
    {
      field: 'enabled_events',
      headerName: 'Events',
      width: 300,
      renderCell: (rowData) => (
        <HtmlTooltip
          title={rowData.row.enabled_events.map((evt) => (
            <Box className={evt}>
              {evt}
              <br />
            </Box>
          ))}
        >
          <Box>
            {rowData.row.enabled_events.map((evt) => (
              <Box className={evt} component="span" mr={0.6}>
                {evt},
              </Box>
            ))}
          </Box>
        </HtmlTooltip>
      )
    },
    {
      field: 'action',
      headerName: 'Action',
      width: 100,
      renderCell: (rowData) => (
        <Button
          variant="contained"
          id={rowData.row._id}
          onClick={() => {
            setNewStripeSettings(rowData.row);
            setEditWebhookDialogOpen(true);
          }}
        >
          Edit
        </Button>
      )
    }
  ];

  return (
    <Box>
      <Stack direction="row" alignItems="center" justifyContent="space-between" mt={3} mb={2}>
        <Typography variant="h5" gutterBottom>
          Stripe Webhooks
        </Typography>
        <CustomButton
          variant="contained"
          icon="material-symbols:add"
          btnText="Add Webhook"
          click={() => setAddWebhookDialogOpen(true)}
        />
      </Stack>
      <DataGrid
        rows={webhooks}
        columns={columns}
        getRowId={(row) => row?.id}
        pageSize={10}
        rowsPerPageOptions={[10]}
        disableSelectionOnClick
        // checkboxSelection
        autoHeight={true}
      />
      <AddWebhookDialog
        open={addwebhookDialogOpen}
        setOpen={setAddWebhookDialogOpen}
        stripe={stripe}
      />
      <EditWebhookDialog
        open={editwebhookDialogOpen}
        setOpen={setEditWebhookDialogOpen}
        stripe={stripe}
        webhook={newStripeSettings}
      />
    </Box>
  );
}
export default WebhooksTable;
