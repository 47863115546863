import { useState } from 'react';
import { Link as RouterLink, useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
// material
import { Stack, Typography, Button, Grid, FormLabel, TextField } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LoadingButton } from '@mui/lab';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
//
import { updateUser, singleUser } from 'src/Redux/actions/blocklistActions';
import { without } from 'lodash';
import { useEffect } from 'react';
import withRole from 'src/components/HOC/withRole';

Yup.addMethod(Yup.object, 'atLeastOneRequired', function (list, message) {
  return this.shape(
    list.reduce(
      (acc, field) => ({
        ...acc,
        [field]: this.fields[field].when(without(list, field), {
          is: (...values) => !values.some((item) => item),
          then: this.fields[field].required(message)
        })
      }),
      {}
    ),
    list.reduce((acc, item, idx, all) => [...acc, ...all.slice(idx + 1).map((i) => [item, i])], [])
  );
});

function EditBlocklistUser() {
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const pageState = useSelector((state) => state.user.items);
  const [pageSections, setPageSection] = useState({
    data: []
  });
  const formData = pageState;
  // Set Form Data - Coupon
  useEffect(() => {
    setPageSection({
      ...formData
    });
  }, [pageState.user]);

  // Get Single Coupon
  useEffect(() => {
    const fetchData = () => {
      try {
        setLoading(true);
        dispatch(singleUser(id));
        setLoading(false);
      } catch (error) {
        setLoading(true);
      }
    };
    fetchData();
  }, []);
  // Validation Schema for Form
  const AddUserSchema = Yup.object()
    .shape({
      email: Yup.string().email('Invalid email'),
      ipAddress: Yup.string(),
      blockedOn: Yup.date()
    })
    .atLeastOneRequired(['email', 'ipAddress'], 'At least one of Email or IP Address is required');

  return (
    <Grid container justifyContent="center">
      <Grid item lg={8} md={8} xs={10} sm={10}>
        <Card
          sx={{ maxWidth: '100%', boxShadow: 3, borderRadius: 1, border: '1px solid #929eaa6b' }}
        >
          <CardContent>
            <Formik
              enableReinitialize={true}
              initialValues={{
                id: pageState.user ? pageState.user._id : '',
                email: pageState.user ? pageState.user.email : '',
                ipAddress: pageState.user ? pageState.user.ipAddress : '',
                blockedOn: pageState.user ? pageState.user.blockedOn : new Date()
              }}
              validationSchema={AddUserSchema}
              onSubmit={async (values) => {
                dispatch(updateUser(values, navigate));
                setLoading(true);
                setTimeout(() => {
                  setLoading(false);
                }, 3000);
              }}
            >
              {({ errors, touched, values, setFieldValue }) => (
                <Form autoComplete="off" style={{ padding: 'inherit' }}>
                  <Typography variant="h4" gutterBottom>
                    Edit User
                  </Typography>
                  <Stack spacing={1} mt={2}>
                    <FormLabel id="email">Email Address</FormLabel>
                    <Field
                      type="email"
                      id="email"
                      name="email"
                      className="input-style"
                      placeholder="Enter Email Address"
                    />
                    {errors.email && touched.email ? (
                      <div className="error-color">{errors.email}</div>
                    ) : null}
                    <FormLabel id="ipAddress">IP Address</FormLabel>
                    <Field
                      type="text"
                      id="ipAddress"
                      name="ipAddress"
                      className="input-style"
                      placeholder="Enter IP Address"
                    />
                    {errors.ipAddress && touched.ipAddress ? (
                      <div className="error-color">{errors.ipAddress}</div>
                    ) : null}
                    <FormLabel id="blockedOn">Blocked On</FormLabel>
                    <Grid>
                      <Grid item lg={12} md={12} sm={12} xs={12}>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <DatePicker
                            label="Select Date"
                            name="blockedOn"
                            value={values.blockedOn}
                            onChange={(newValue) => {
                              setFieldValue('blockedOn', newValue);
                            }}
                            renderInput={(params) => <TextField {...params} />}
                          />{' '}
                        </LocalizationProvider>
                        {errors.blockedOn && touched.blockedOn ? (
                          <div className="error-color">{errors.blockedOn}</div>
                        ) : null}
                      </Grid>
                    </Grid>
                  </Stack>
                  <Grid container>
                    <Grid item xs={4} sm={4} md={3} lg={2} mt={3}>
                      <LoadingButton
                        fullWidth
                        size="large"
                        type="submit"
                        variant="contained"
                        loading={loading}
                      >
                        Submit
                      </LoadingButton>
                    </Grid>
                    <Grid item xs={4} sm={4} md={3} lg={2} ml={2} mt={3}>
                      <Button
                        fullWidth
                        size="large"
                        variant="contained"
                        color="error"
                        component={RouterLink}
                        to="/dashboard/blocklist"
                      >
                        Cancel
                      </Button>
                    </Grid>
                  </Grid>
                </Form>
              )}
            </Formik>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
}
export default withRole(EditBlocklistUser);
