// ----------------------------------------------------------------------

const account = {
  displayName: 'Followers Cart',
  email: 'hi@digitalaimz.com',
  photoURL: '/static/mock-images/avatars/avatar_default.jpg',
  photo: '/static/mock-images/avatars/avatar_22.jpg'
};

export default account;
