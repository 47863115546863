import React, { useState } from 'react';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
//
import { Stack, Typography, Box } from '@mui/material';
import Page from 'src/components/Page';
import CustomTable from 'src/components/CustomTable';
import withRole from 'src/components/HOC/withRole';
import { getActivityLogs } from 'src/api/api';

function ActivityLogs(props) {
  const navigate = useNavigate();
  // Fetch Data from API
  const [loading, setLoading] = React.useState(false);
  const [platform, setPlatform] = useState([]);
  const [rows, setRows] = useState([]);
  const userRole = props.user?.userRole;

  // Get All data
  React.useEffect(() => {
    setLoading(true);
    const promise = getActivityLogs();
    Promise.resolve(promise).then((data) => {
      if (data.success) {
        const logs = data?.data;
        if (logs && logs?.length) {
          setPlatform(logs);
          setRows(logs);
        }
        setLoading(false);
      } else {
        setPlatform([]);
        setRows([]);
        setLoading(false);
      }
    });
    const type = userRole == '1' ? null : navigate('/dashboard/app');
    return () => {
      return type;
    };
  }, []);

  // Table Columns
  const columns = [
    {
      field: 'email',
      headerName: 'User Email',
      width: 200
    },
    {
      field: 'action',
      headerName: 'Action Performed',
      width: 200
    },
    {
      field: 'timestamp',
      headerName: 'Timestamp',
      renderCell: (params) => {
        return moment(params.row.timestamp).format('MMMM Do YYYY, h:mm:ss a')
      },
      width: 300
    }
  ];

  return (
    <Page title={props.title ? 'Dashboard' : 'Dashboard: Activity Logs'}>
      <Box p={props.title ? 0 : 3}>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={2}>
          <Typography variant="h4" gutterBottom>
            All Activity
          </Typography>
        </Stack>
        <CustomTable
          searchPlaceholder="Search..."
          tableHeading="for Activity Logs"
          platform={platform}
          setRows={setRows}
          rows={rows}
          rowId={(row) => row._id}
          columns={columns}
          loading={loading}
          data={platform?.length ? platform?.length : 'No Data'} // For No Data
        />
      </Box>
    </Page>
  );
}
export default withRole(ActivityLogs);
