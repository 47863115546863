import { Navigate, useRoutes } from 'react-router-dom';
// layouts
import DashboardLayout from './layouts/dashboard';
import LogoOnlyLayout from './layouts/LogoOnlyLayout';
// Pages Routes
// Blogs
import Blog from './pages/Blogs/Blogs';
import CreateBlogForm from './pages/Blogs/components/CreateBlogForm';
import EditBlogForm from './pages/Blogs/components/EditBlogForm';
// Blogs Categories
import BlogCategories from './pages/BlogsCategories/BlogCategories';
import AddBlogCategories from './pages/BlogsCategories/components/CreateBlogForm';
import EditBlogCategories from './pages/BlogsCategories/components/EditBlogForm';
// Orders
import Orders from './pages/Orders/Orders';
import OrderDetails from './pages/Orders/components/OrderDetails';
// Stripe Setting
import StripeSetting from './pages/StripeSetting/StripeSetting';
import AddStripeSetting from './pages/StripeSetting/components/AddStripeSetting';
import EditStripeSetting from './pages/StripeSetting/components/EditStripeSetting';
// User Role
import UserRoles from './pages/UserRole/UserRoles';
import AddUserRoles from './pages/UserRole/components/AddUserRole';
import EditUserRole from './pages/UserRole/components/EditUserRole';
// Users
import AllUsers from './pages/User/AllUsers';
import CreateUser from './pages/User/components/CreateUser';
import EditUser from './pages/User/components/EditUser';
import UserProfile from './pages/User/components/UserProfile';
import EditUserProfile from './pages/User/components/EditUserProfile';
// Coupons
import Coupons from './pages/Coupons/Coupons';
import AddCoupon from './pages/Coupons/components/AddCoupon';
import EditCoupon from './pages/Coupons/components/EditCoupon';
// Home Page Content
import HomePageContent from './pages/HomePageContent/HomePageContent';
import AddHomePage from './pages/HomePageContent/components/AddHomePage';
import EditHomePage from './pages/HomePageContent/components/EditHomePage';
// Pages
import PrivacyPolicy from './pages/EditPage/Privacy';
import RefundPolicy from './pages/EditPage/RefundPolicy';
import EditPage from './pages/EditPage/EditPage';
// Menus
import Menus from './pages/Menus/Menus';
import AddMenu from './pages/Menus/components/AddMenu';
import EditMenu from './pages/Menus/components/EditMenu';
// Sub Menu
// import AddSubMenu from './pages/Menus/components/AddSubMenu';
// import EditSubMenu from './pages/Menus/components/EditSubMenu';
// Footer Content
import FooterContent from './pages/FooterContent/FooterContent';
import AddFooter from './pages/FooterContent/components/AddFooter';
import EditFooter from './pages/FooterContent/components/EditFooter';
// Single Service Content
import SingleServiceContent from './pages/SingleServiceContent/SingleServiceContent';
import AddServiceContent from './pages/SingleServiceContent/components/AddServiceContent';
import UpdateServiceContent from './pages/SingleServiceContent/components/UpdateServiceContents';
// Packages
import Packages from './pages/Packages/Packages';
import AddPackage from './pages/Packages/components/AddPackage';
import EditPackage from './pages/Packages/components/EditPackage';
// Package Categories
// import PackageCategories from './pages/PackagesCategories/PackagesCategories';
// import AddCategory from './pages/PackagesCategories/components/AddCategory';
// import EditCategory from './pages/PackagesCategories/components/EditCategory';
import AllServices from './pages/AllServices/AllServices';
import AddService from './pages/AllServices/components/AddService';
import EditService from './pages/AllServices/components/EditService';
// Reviews
import Reviews from './pages/Reviews/Reviews';
import AddReview from './pages/Reviews/components/AddReview';
import EditReview from './pages/Reviews/components/EditReview';
// Countries
import Countries from './pages/Countries/Countries';
import EditCountries from './pages/Countries/components/EditCountries';
import AddCountry from './pages/Countries/components/AddCountry';
// FAQS
import FAQs from './pages/FAQs/FAQs';
import AddFaq from './pages/FAQs/components/AddFaq';
import EditFaqs from './pages/FAQs/components/EditFaqs';
// Robots.txt
import Robots from './pages/Robots.txt/Robots';
//
// import EditMainPage from './pages/editPage';
// Login
import Login from './pages/Login';
import NotFound from './pages/Page404';
import DashboardApp from './pages/DashboardApp';
import Register from './pages/Register';
import BlockList from './pages/Blocklist/Blocklist';
import AddUser from './pages/Blocklist/components/AddBlocklistUser';
import EditBlocklistUser from './pages/Blocklist/components/EditBlocklistUser';
import ActivityLogs from './pages/ActivityLogs/ActivityLogs';
import Sitemap from './pages/Sitemap/Sitemap';
// ----------------------------------------------------------------------

export default function Router() {
  return useRoutes([
    {
      path: '/dashboard',
      element: <DashboardLayout />,
      children: [
        { path: 'app', element: <DashboardApp /> },
        // Activity Logs
        { path: 'activity-logs', element: <ActivityLogs title={false} /> },
        // Blogs
        { path: 'blogs', element: <Blog /> },
        { path: 'blogs/add-blog', element: <CreateBlogForm /> },
        { path: 'blogs/edit-blog/:id', element: <EditBlogForm /> },
        // Blogs Categories
        { path: 'blogs-category', element: <BlogCategories /> },
        { path: 'blogs-category/add-category', element: <AddBlogCategories /> },
        { path: 'blogs-category/edit-category/:id', element: <EditBlogCategories /> },
        // Orders
        { path: 'orders', element: <Orders /> },
        { path: 'orders/order-details/:id', element: <OrderDetails /> },
        // Stripe Setting
        { path: 'stripe-setting', element: <StripeSetting /> },
        { path: 'stripe-setting/add', element: <AddStripeSetting /> },
        { path: 'stripe-setting/edit-stripe-setting/:id', element: <EditStripeSetting /> },
        // Users
        { path: 'users', element: <AllUsers /> },
        { path: 'users/create-user', element: <CreateUser /> },
        { path: 'users/edit-user/:id', element: <EditUser /> },
        { path: 'user-profile', element: <UserProfile /> },
        { path: 'user-profile/edit', element: <EditUserProfile /> },
        // User Role
        { path: 'user-role', element: <UserRoles /> },
        { path: 'user-role/add-user-role', element: <AddUserRoles /> },
        { path: 'user-role/edit-user-role/:id', element: <EditUserRole /> },
        // Coupons
        {
          path: 'coupons',
          element: <Coupons />
        },
        {
          path: 'coupons/add-coupon',
          element: <AddCoupon />
        },
        {
          path: 'coupons/edit-coupon/:id',
          element: <EditCoupon />
        },
        // Home Page Content
        { path: 'home-page-content', element: <HomePageContent /> },
        { path: 'home-page-content/add-page', element: <AddHomePage /> },
        { path: 'home-page-content/edit-page/:slug', element: <EditHomePage /> },
        // Pages
        { path: 'pages/privacy-policy', element: <PrivacyPolicy /> },
        { path: 'pages/refund-policy', element: <RefundPolicy /> },
        { path: 'pages/edit-page/:pageSlug/:country', element: <EditPage /> },
        // Menus
        { path: 'menus', element: <Menus /> },
        // Main Menu
        { path: 'menus/add-menu', element: <AddMenu /> },
        { path: 'menus/edit-menu/:id', element: <EditMenu /> },
        // Main Menu
        // { path: 'menus/add-sub-menu', element: <AddSubMenu /> },
        // { path: 'menus/edit-sub-menu/:id', element: <EditSubMenu /> },
        // Footer Content
        { path: 'footer-content', element: <FooterContent /> },
        { path: 'footer-content/add-footer-content', element: <AddFooter /> },
        { path: 'footer-content/edit-footer-content/:slug', element: <EditFooter /> },
        // Single Service Content
        { path: 'service-content', element: <SingleServiceContent /> },
        { path: 'service-content/add-service-content', element: <AddServiceContent /> },
        {
          path: 'service-content/edit-service-content/:slug/:id',
          element: <UpdateServiceContent />
        },
        // Pacakges
        {
          path: 'packages',
          element: <Packages />
        },
        {
          path: 'packages/add-package',
          element: <AddPackage />
        },
        {
          path: 'packages/edit-package/:id',
          element: <EditPackage />
        },
        // Services
        { path: 'services', element: <AllServices /> },
        { path: 'services/add-service', element: <AddService /> },
        { path: 'services/edit-service/:id', element: <EditService /> },
        // Single Service Content
        // Testimonials
        { path: 'reviews', element: <Reviews /> },
        // Countries
        { path: 'countries', element: <Countries /> },
        { path: 'countries/:slug/edit', element: <EditCountries /> },
        { path: 'countries/add-country', element: <AddCountry /> },
        // Faqs
        { path: 'pages/faqs', element: <FAQs /> },
        { path: 'pages/faqs/add-faq', element: <AddFaq /> },
        { path: 'pages/faqs/edit-faq/:id', element: <EditFaqs /> },
        // Edit Page Content
        // { path: 'pages/edit-page/:pageId', element: <EditMainPage /> },
        // Reviews
        { path: 'reviews', element: <Reviews /> },
        { path: 'reviews/add-review', element: <AddReview /> },
        { path: 'reviews/edit-review/:id', element: <EditReview /> },
        //BlockList
        { path: 'blocklist', element: <BlockList /> },
        { path: 'blocklist/add-user', element: <AddUser /> },
        {
          path: 'blocklist/edit-user/:id',
          element: <EditBlocklistUser />
        },

        // Reviews
        { path: 'robots.txt', element: <Robots /> },
        { path: 'sitemap.xml', element: <Sitemap/> }

      ]
    },
    {
      path: '/',
      element: <LogoOnlyLayout />,
      children: [
        { path: '/', element: <Navigate to="/dashboard/app" /> },
        { path: 'login', element: <Login /> },
        { path: 'register', element: <Register /> },
        { path: '404', element: <NotFound /> },
        { path: '*', element: <Navigate to="/404" /> }
      ]
    },
    { path: '*', element: <Navigate to="/404" replace /> }
  ]);
}
