import { toast } from 'react-toastify';
import axios from 'axios';
import * as types from '../constants/actionTypes';
import { request } from '../helpers/axiosRequest';
import { logUserActivity } from './activityLogsActions';

export const API = axios.create({ baseURL: process.env.REACT_APP_API_URL });

// Create Review
export const addReview = (data, navigate, setLoading) => async (dispatch) => {
  try {
    dispatch({ type: types.CREATE_REVIEW_ATTEMPT });
    await request({
      url: `${process.env.REACT_APP_API_URL}/reviews/add-new`,
      auth: true,
      method: 'POST',
      data
    });
    setLoading(false);
    toast.success('Review Added Successsully');
    dispatch({
      type: types.CREATE_REVIEW_SUCCESS,
      payload: { ...data }
    });
    dispatch(logUserActivity(`ADDED_REVIEW`));
    setTimeout(() => {
      navigate('/dashboard/reviews');
    }, 2000);
  } catch (err) {
    setLoading(false);
    dispatch({ type: types.CREATE_REVIEW_FAIL, payload: err });
    toast.error(err.response?.data?.message ? err.response.data.message : 'Unable to add review.');
    throw err;
  }
};

// Get All Reviews
export const allReviews = () => async (dispatch) => {
  dispatch({ type: types.GET_REVIEWS_ATTEMPT });
  try {
    const res = await request({
      url: `${process.env.REACT_APP_API_URL}/reviews/get-all`,
      auth: true,
      method: 'GET'
    });

    dispatch({
      type: types.GET_REVIEWS_SUCCESS,
      payload: res.data
    });
  } catch (err) {
    dispatch({ type: types.GET_REVIEWS_FAIL, payload: err });
    throw err;
  }
};

// Get Single Review
export const singleReview = (id) => {
  return async (dispatch) => {
    dispatch({ type: types.SINGLE_REVIEW_ATTEMPT });
    try {
      const res = await request({
        url: `${process.env.REACT_APP_API_URL}/reviews/get-by-id/${id}`,
        auth: true,
        method: 'GET'
      });
      dispatch({
        type: types.SINGLE_REVIEW_SUCCESS,
        payload: res.data
      });
    } catch (err) {
      dispatch({ type: types.SINGLE_REVIEW_FAIL, payload: err });
      throw err;
    }
  };
};
// Update Review
export const updateReview = (data, navigate, setLoading) => async (dispatch) => {
  try {
    dispatch({ type: types.UPDATE_REVIEW_ATTEMPT });
    const res = await request({
      url: `${process.env.REACT_APP_API_URL}/reviews/update-by-id`,
      auth: true,
      method: 'PUT',
      data
    });
    dispatch({
      type: types.UPDATE_REVIEW_SUCCESS,
      payload: { ...data }
    });
    toast.success('Review Updated Successsully');
    setLoading(false);
    dispatch(logUserActivity(`UPDATED_REVIEW`));
    setTimeout(() => {
      navigate('/dashboard/reviews');
    }, 2000);
  } catch (err) {
    setLoading(false);
    toast.error(
      err.response?.data?.message
        ? err.response.data.message
        : 'Unable to update the review, please try again.'
    );
    dispatch({ type: types.UPDATE_REVIEW_FAIL, payload: err });
    throw err;
  }
};

// Delete Review
export const deleteReview = (id) => async (dispatch) => {
  try {
    dispatch({ type: types.DELETE_REVIEW_ATTEMPT });
    const res = await request({
      method: 'POST',
      auth: true,
      url: `${process.env.REACT_APP_API_URL}/reviews/delete-by-id`,
      data: { _id: id }
    });

    toast.success('Review Deleted Successsully');
    dispatch({ type: types.DELETE_REVIEW_SUCCESS, payload: {} });
    dispatch(logUserActivity(`DELETED_REVIEW`));
    setTimeout(() => {
      window.location.href = '/dashboard/reviews';
    }, 1000);
  } catch (err) {
    dispatch({ type: types.DELETE_REVIEW_FAIL, payload: err });
    toast.error(
      err.response?.data?.message
        ? err.response.data.message
        : 'Unable to delete the review, please try again.'
    );
    throw new Error(err);
  }
};
