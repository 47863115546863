import React from 'react';
//
import Box from '@mui/material/Box';
import { Typography, Stack } from '@mui/material';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
//
import ActionButton from 'src/components/ActionButton';
//
import { deleteMenu } from 'src/Redux/actions/menuActions';

function Row(props) {
  const { row } = props;
  const [open, setOpen] = React.useState(false);

  return (
    <React.Fragment>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
        <TableCell>
          <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell>{row.title}</TableCell>
        <TableCell>{row.link}</TableCell>
        <TableCell>{row.parent == null ? 'none' : 1}</TableCell>
        <TableCell>{row.active == true ? 'Active' : 'In-Active'}</TableCell>
        <TableCell>
          <ActionButton
            id={row._id}
            name="Menu"
            action={deleteMenu}
            link={`/dashboard/menus/edit-menu/${row._id}`}
          />
        </TableCell>
      </TableRow>

      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={12}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ m: 1 }}>
              {row.subMenu?.length ? (
                <Typography mt={2} mb={2} align="center" variant="h6">
                  Sub Menu
                </Typography>
              ) : (
                ''
              )}
              <Table size="large" aria-label="table">
                {row.subMenu?.length ? (
                  <TableHead sx={{ borderTop: '1px solid rgba(241, 243, 244, 1)' }}>
                    <TableRow>
                      <TableCell sx={{ fontWeight: '700', textAlign: 'center' }}>Text</TableCell>
                      <TableCell sx={{ fontWeight: '700', textAlign: 'center' }}>Link</TableCell>
                      <TableCell sx={{ fontWeight: '700', textAlign: 'center' }}>Active</TableCell>
                      <TableCell sx={{ fontWeight: '700', textAlign: 'center' }}>Order</TableCell>
                    </TableRow>
                  </TableHead>
                ) : (
                  <Typography align="center" variant="h6">
                    No Sub Menu
                  </Typography>
                )}
                {row.subMenu?.sort((a, b) => a.order - b.order)?.map((items, index) => {
                  return (
                    <TableBody key={index}>
                      <TableRow key={items._id}>
                        <TableCell align="center">{items.title}</TableCell>
                        <TableCell align="center">{items.link}</TableCell>
                        <TableCell align="center">
                          {items.active == true ? 'Active' : 'In-Active'}
                        </TableCell>
                        <TableCell align="center">{items.order}</TableCell>
                      </TableRow>
                    </TableBody>
                  );
                })}
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

export default function MenuTable(props) {
  const { menus } = props;

  return (
    <TableContainer
      component={Paper}
      sx={{
        boxShadow: 5
      }}
    >
      <Table aria-label="collapsible table">
        {menus?.length ? (
          <TableHead>
            <TableRow>
              <TableCell sx={{ fontWeight: '700' }}>Menus</TableCell>
              <TableCell sx={{ fontWeight: '700' }}>Title</TableCell>
              <TableCell sx={{ fontWeight: '700' }}>Link</TableCell>
              <TableCell sx={{ fontWeight: '700' }}>Parent</TableCell>
              <TableCell sx={{ fontWeight: '700' }}>Active</TableCell>
              <TableCell sx={{ fontWeight: '700' }}>Action</TableCell>
            </TableRow>
          </TableHead>
        ) : (
          <Stack sx={{ m: '20px 0', textAlign: 'center' }}>
            <Typography>NO DATA</Typography>
          </Stack>
        )}
        <TableBody>
          {menus?.map((item) => (
            <Row key={item._id} row={item} rows={item} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
