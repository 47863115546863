import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
//
import Tabs, { tabsClasses } from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
//
import Loader from 'src/components/Loader';
import { allPageContent } from 'src/Redux/actions/pageSectionActions';
import SectionContent from './SectionContent';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`
  };
}

export default function PageTabs() {
  const [value, setValue] = useState(0);

  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  // Get All menus
  const pageState = useSelector((state) => state.pageSections?.items);
  const [pageSections, setPageSection] = useState({
    data: []
  });
  const formData = pageState;

  useEffect(() => {
    setPageSection({
      ...formData
    });
  }, [pageState]);

  useEffect(() => {
    const fetchData = () => {
      try {
        setLoading(true);
        setTimeout(() => {
          dispatch(allPageContent());
          setLoading(false);
        }, 1000);
      } catch (error) {
        setLoading(true);
      }
    };
    fetchData();
  }, []);

  // Change Menu Values
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      {loading && <Loader />}
      {!loading && (
        <Box sx={{ mt: 1 }}>
          <Box sx={{ width: '100%' }}>
            <Tabs
              value={value}
              onChange={handleChange}
              variant="scrollable"
              scrollButtons
              aria-label="visible arrows tabs example"
              sx={{
                [`& .${tabsClasses.scrollButtons}`]: {
                  '&.Mui-disabled': { opacity: '0.3' }
                }
              }}
            >
              {pageState.data?.map((item, index) => {
                if (!item?.countryName == '')
                  return <Tab label={item?.countryName} {...a11yProps(index)} key={index} />;
              })}
            </Tabs>
          </Box>

          {pageState.data?.map((item, index) => {
            if (!item?.page == '')
              return (
                <TabPanel value={value} index={index} key={index} id={index}>
                  <Box sx={{ boxShadow: 3, borderRadius: 2 }}>
                    <SectionContent data={item} index={index} />
                  </Box>
                </TabPanel>
              );
          })}
        </Box>
      )}
    </>
  );
}
