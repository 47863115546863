import axios from 'axios';

axios.defaults.withCredentials = true;

export async function request({ url, method, auth, data }, login) {
  // let headers = { 'Content-Type': 'application/json' };
  if (auth === true) {
    const token = localStorage.getItem('token');
    // headers = {
    //   'Content-Type': 'application/json',
    //   authorization: `Bearer ${token}`
    // };
  }
  try {
    return await axios({
      method,
      url: url.endsWith('/') ? url : `${url}/`,
      data
      // headers
    });
  } catch (err) {
    if (
      err &&
      err.response &&
      err.response.data &&
      err.response.data.error &&
      err.response.data.error.statusCode === 401
    ) {
      localStorage.removeItem('token');
      if (!login) window.location.href = '/login';
    }
    throw err;
  }
}
