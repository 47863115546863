import * as React from 'react';
import {
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  Stack,
  Grid,
  Box,
  FormLabel,
  IconButton
} from '@mui/material';
import { toast } from 'react-toastify';
//
import { useNavigate, useLocation } from 'react-router-dom';
import * as Yup from 'yup';
import { Formik, Form, Field, FieldArray } from 'formik';
//
import Iconify from 'src/components/Iconify';
//
import { addStripeWebhook } from 'src/api/api';

const TextInput = ({ field, form, ...props }) => {
  return <TextField size="small" {...field} {...props} />;
};

export default function AddWebhookDialog({ open, setOpen, stripe }) {
  const [adding, setAdding] = React.useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  // close delete dialog
  const handleClose = () => {
    setOpen(false);
  };

  // Validation Schema for From
  const webhookSchema = Yup.object().shape({
    url: Yup.string().required('URL is required'),
    events: Yup.array().test({
      message: 'At least one event is required, or fill in all the event values',
      test: (val) => {
        if (val.length <= 0) return false;
        return val.filter((i) => !i).length <= 0;
      }
    })
  });

  const handleAddWebhook = async (values = {}) => {
    values.settings = stripe;
    setAdding(true);
    addStripeWebhook(values)
      .then((resp) => {
        if (resp.success) {
          toast.success(resp.message);
          setTimeout(() => {
            navigate(0);
          }, 3000);
        } else {
          toast.error(
            resp?.message ? resp.message : 'An error occured while adding webhook, please try again'
          );
          setAdding(false);
        }
      })
      .catch((error) => {
        toast.error(
          error.response?.data?.error?.raw?.message
            ? error.response.data.error?.raw.message
            : error.response?.data?.message
            ? error.response.data.message
            : 'An error occured while adding webhook, please try again'
        );
        setAdding(false);
      });
  };

  return (
    <div>
      <Dialog
        fullWidth
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Add new Webhook for this account?</DialogTitle>
        <DialogContent>
          <Formik
            enableReinitialize={true}
            initialValues={{
              url: 'https://api.followerscart.com/webhook/',
              events: [
                'checkout.session.completed',
                'payment_intent.canceled',
                'payment_intent.succeeded'
              ]
            }}
            validationSchema={webhookSchema}
            onSubmit={(values) => handleAddWebhook(values)}
          >
            {({ errors, touched, values }) => (
              <Form autoComplete="off" style={{ padding: 'inherit' }}>
                <Stack spacing={1} mt={2}>
                  <FormLabel htmlFor="url">Webhook Url</FormLabel>
                  <Field
                    type="text"
                    id="url"
                    name="url"
                    placeholder="https://api.followerscart.com/webhook/"
                    disabled={adding}
                    component={TextInput}
                  />
                  {errors.url && touched.url ? (
                    <div className="error-color">{errors.url}</div>
                  ) : null}
                  <FieldArray
                    name="events"
                    render={({ push, insert, remove }) => (
                      <div className="row">
                        {values.events?.length > 0 ? (
                          values.events?.map((__, idx) => (
                            <Box key={idx}>
                              <Stack>
                                <Box display="flex" alignItems="center" my={1}>
                                  <FormLabel htmlFor={`events[${idx}]`}>Event {idx + 1}:</FormLabel>
                                  <IconButton
                                    sx={{ marginLeft: 1 }}
                                    size="small"
                                    aria-label="add"
                                    variant="contained"
                                    color="primary"
                                    onClick={() => insert(idx + 1, '')}
                                  >
                                    <Iconify icon="material-symbols:add" width={17} />
                                  </IconButton>
                                  <IconButton
                                    sx={{ marginLeft: 1 }}
                                    size="small"
                                    aria-label="delete"
                                    variant="contained"
                                    color="error"
                                    onClick={() => remove(idx)}
                                  >
                                    <Iconify icon="ic:baseline-remove" width={17} />
                                  </IconButton>
                                </Box>
                                <Field
                                  defaultValue=""
                                  id={`events[${idx}]`}
                                  name={`events[${idx}]`}
                                  type="text"
                                  placeholder="event"
                                  disabled={adding}
                                  component={TextInput}
                                />
                              </Stack>
                            </Box>
                          ))
                        ) : (
                          <Button
                            type="button"
                            variant="contained"
                            color="primary"
                            size="small"
                            sx={{ my: 2 }}
                            onClick={() => push('')}
                          >
                            Add event
                          </Button>
                        )}
                      </div>
                    )}
                  />
                  {errors.events && touched.events ? (
                    <div className="error-color">{errors.events}</div>
                  ) : null}
                </Stack>
                <Box my={2} display="flex">
                  <Box mr={2}>
                    <Button
                      autoFocus
                      type="submit"
                      variant="contained"
                      color="primary"
                      disabled={adding}
                    >
                      Add Webhook
                    </Button>
                  </Box>
                  <Box>
                    <Button
                      color="error"
                      variant="contained"
                      disabled={adding}
                      onClick={handleClose}
                    >
                      Cancel
                    </Button>
                  </Box>
                </Box>
              </Form>
            )}
          </Formik>
        </DialogContent>
      </Dialog>
    </div>
  );
}
