import React from 'react';
import moment from 'moment';
//
import Box from '@mui/material/Box';
import { Typography, Stack } from '@mui/material';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
//
import ActionButton from 'src/components/ActionButton';
//
import { deleteCategoryContent } from 'src/Redux/actions/singleServiceActions';

function Row(props) {
  const { row, slug } = props;
  const [open, setOpen] = React.useState(false);
  // console.log('slug', slug);
  return (
    <React.Fragment>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
        <TableCell>
          <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell>{row.name}</TableCell>
        <TableCell>{row.slug}</TableCell>
        <TableCell>{moment(row.createdAt).format('MMMM Do, YYYY')} </TableCell>
        <TableCell>{row.active == true ? 'Active' : 'In-Active'}</TableCell>
      </TableRow>

      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={12}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ m: 1 }}>
              {row.categories?.length ? (
                <Typography mt={2} mb={2} align="center" variant="h6">
                  Categories
                </Typography>
              ) : (
                ''
              )}
              <Table size="large" aria-label="table">
                {row.categories?.length ? (
                  <TableHead sx={{ borderTop: '1px solid rgba(241, 243, 244, 1)' }}>
                    <TableRow>
                      <TableCell sx={{ textAlign: 'center' }}>Category</TableCell>
                      <TableCell sx={{ textAlign: 'center' }}>Service Id</TableCell>
                      <TableCell sx={{ textAlign: 'center' }}>Multiple Link</TableCell>
                      <TableCell sx={{ textAlign: 'center' }}>Status</TableCell>
                      <TableCell sx={{ textAlign: 'center' }}>Action</TableCell>
                    </TableRow>
                  </TableHead>
                ) : (
                  <Typography align="center" variant="h6">
                    No Categories
                  </Typography>
                )}
                {row.categories?.map((items, index) => {
                  const category = items._id;
                  const country = slug;
                  const delObj = { category, country };
                  return (
                    <TableBody key={index}>
                      <TableRow key={items._id}>
                        <TableCell align="center">{items.name}</TableCell>
                        <TableCell align="center">{items.serviceId}</TableCell>
                        <TableCell align="center">
                          {items.allowMultipleLinks == true ? 'YES' : 'NO'}
                        </TableCell>
                        <TableCell align="center">
                          {items.active == true ? 'Active' : 'In-Active'}
                        </TableCell>
                        <TableCell align="center">
                          <ActionButton
                            id={delObj}
                            name="Category"
                            color="primary"
                            action={deleteCategoryContent}
                            slug={slug}
                            link={`/dashboard/service-content/edit-service-content/${slug}/${items._id}`}
                          />
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  );
                })}
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

export default function SingleServicesTable(props) {
  const { data, slug } = props;

  return (
    <TableContainer
      component={Paper}
      sx={{
        boxShadow: 5
      }}
    >
      <Table aria-label="collapsible table">
        <TableHead>
          <TableRow>
            <TableCell sx={{ fontWeight: '700' }}>Categories</TableCell>
            <TableCell sx={{ fontWeight: '700' }}>Name</TableCell>
            <TableCell sx={{ fontWeight: '700' }}>Slug</TableCell>
            <TableCell sx={{ fontWeight: '700' }}>Date</TableCell>
            <TableCell sx={{ fontWeight: '700' }}>Status</TableCell>
          </TableRow>
        </TableHead>
        {data?.length ? (
          <TableBody>
            {data?.map((item) => (
              <Row key={item._id} row={item} rows={item} slug={slug} />
            ))}
          </TableBody>
        ) : (
          <Stack sx={{ m: '20px 0', textAlign: 'center' }}>
            <Typography>NO DATA</Typography>
          </Stack>
        )}
      </Table>
    </TableContainer>
  );
}
