import React from 'react';

export default function Image(props) {
  // image attributes
  const imgSrc = props.src ? props.src : '',
    imgAlt = props.alt ? props.alt : '',
    imgClass = props.class ? props.class : '',
    imgWidth = props.width ? props.width : '100%',
    imgHeight = props.height ? props.height : 'auto';

  return (
    <>
      <img src={imgSrc} width={imgWidth} height={imgHeight} alt={imgAlt} className={imgClass} />
    </>
  );
}
