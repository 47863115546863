import { useState, useEffect } from 'react';
import { Link as RouterLink, useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Formik, Form, Field } from 'formik';
import { TextField, Switch } from 'formik-mui';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
// material
import { Stack, Typography, Button, Grid, FormLabel, MenuItem } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import Card from '@mui/material/Card';
import FormControlLabel from '@mui/material/FormControlLabel';
import CardContent from '@mui/material/CardContent';
//
import { getSettings, singleStripeSetting } from 'src/Redux/actions/stripeSettingActions';
import { updateStripeSettings } from 'src/api/api';
import withRole from 'src/components/HOC/withRole';
import WebhooksTable from './WebhooksTable';
import Loader from 'src/components/Loader';

function EditStripeSetting(props) {
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [dataLoading, setDataLoading] = useState(false);

  // For User Role
  const userRole = props.user?.userRole;

  const pageState = useSelector((state) => state.stripeSetting.items);

  useEffect(() => {
    const fetchData = () => {
      try {
        setDataLoading(true);
        dispatch(singleStripeSetting( id ));
        setDataLoading(false);
      } catch (error) {
        setDataLoading(true);
      }
    };
    fetchData();
    // For User Role
    const type = userRole == '1' ? null : navigate('/dashboard/app');
    return () => {
      return type;
    };
  }, []);

  // Validation Schema for From
  const StripeSettingsSchema = Yup.object().shape({
    companyName: Yup.string().required('Company Name is required'),
    currency: Yup.string().required('Currency is required'),
    publicKey: Yup.string().required('Public Key is required'),
    secretkey: Yup.string().required('Secret key is required')
  });

  const handleUpdateSettings = async (values) => {
    setLoading(true);
    updateStripeSettings(values)
      .then((resp) => {
        if (resp.success) {
          toast.success(resp.message ? resp.message : 'Stripe Settings Added Successsully');
          // if (resp.new) {
          //   setLoading(false);
          //   const { newSettings } = resp;
          //   setNewStripeSettings(newSettings);
          //   setWebHookDialogOpen(true);
          //   return false;
          // }
        }
        navigate('/dashboard/stripe-setting');
      })
      .catch((err) => {
        toast.error(
          err.response?.data?.message ? err.response.data.message : 'Unable to add new settings.'
        );
        setLoading(false);
      });
  };

  return (
    <Grid container>
      <Grid item lg={11} md={10} xs={12} sm={12} mx="auto">
        <Card
          sx={{ minWidth: 275, boxShadow: 3, borderRadius: 1, border: '1px solid #929eaa6b', p: 2 }}
        >
          <CardContent>
            <Formik
              enableReinitialize={true}
              initialValues={{
                id: pageState.settings?._id ? pageState.settings?._id : '',
                companyName: pageState.settings?.companyName ? pageState.settings?.companyName : '',
                currency: pageState.settings?.currency ? pageState.settings?.currency : '',
                publicKey: pageState.settings?.publicKey ? pageState.settings?.publicKey : '',
                secretkey: pageState.settings?.secretkey ? pageState.settings?.secretkey : '',
                webhookSecret: pageState.settings?.webhookSecret
                  ? pageState.settings?.webhookSecret
                  : '',
                active: pageState.settings?.active ? pageState.settings?.active : false
                // sandBox: pageState.settings?.sandBox ? pageState.settings?.sandBox : false
              }}
              validationSchema={StripeSettingsSchema}
              onSubmit={(values) => handleUpdateSettings(values)}
            >
              {({ errors, touched, values }) => (
                <>
                  {dataLoading ? (
                    <Loader />
                  ) : (
                    <Form autoComplete="off">
                      <Typography variant="h4" gutterBottom>
                        Edit Stripe Setting
                      </Typography>
                      <Stack spacing={1} mt={2}>
                        <FormLabel id="companyName">Company Name</FormLabel>
                        <Field
                          type="text"
                          id="companyName"
                          name="companyName"
                          className="input-style"
                          placeholder="Enter Company Name"
                        />
                        {errors.companyName && touched.companyName ? (
                          <div className="error-color">{errors.companyName}</div>
                        ) : null}

                        <FormLabel id="radios">Currency</FormLabel>
                        <Field
                          name="currency"
                          select
                          component={TextField}
                          sx={{ width: '95%' }}
                          label="Select Value"
                        >
                          <MenuItem value="GBP" label="GBP" key="1">
                            GBP
                          </MenuItem>
                          <MenuItem value="USD" label="USD" key="2">
                            USD
                          </MenuItem>
                        </Field>
                        <FormLabel id="publicKey">Public Key</FormLabel>
                        <Field
                          type="text"
                          id="publicKey"
                          name="publicKey"
                          className="input-style"
                          placeholder="Enter Public Key"
                        />
                        {errors.publicKey && touched.publicKey ? (
                          <div className="error-color">{errors.publicKey}</div>
                        ) : null}

                        <FormLabel id="secretkey">Secret Key</FormLabel>
                        <Field
                          type="text"
                          id="secretkey"
                          name="secretkey"
                          className="input-style"
                          placeholder="Enter Secret Key"
                        />
                        {errors.secretkey && touched.secretkey ? (
                          <div className="error-color">{errors.secretkey}</div>
                        ) : null}
                        <FormLabel id="webhookSecret">Webhook Secret</FormLabel>
                        <Typography variant="body2">
                          Webhook secret will be updated automatically when a new webhook is added
                        </Typography>
                        <Field
                          type="text"
                          id="webhookSecret"
                          name="webhookSecret"
                          className="input-style"
                          placeholder="Enter Secret Key"
                        />
                        {errors.webhookSecret && touched.webhookSecret ? (
                          <div className="error-color">{errors.webhookSecret}</div>
                        ) : null}
                        <Grid>
                          <FormLabel id="active">Active</FormLabel>
                          <Grid item lg={5} md={5} xs={10} sm={10}>
                            <FormControlLabel
                              control={<Field component={Switch} type="checkbox" name="active" />}
                              label={values.active === true ? 'Active' : 'In-Active'}
                            />
                          </Grid>
                        </Grid>
                      </Stack>
                      <Grid container>
                        <Grid item xs={4} sm={4} md={3} lg={2} mt={3}>
                          <LoadingButton
                            fullWidth
                            size="large"
                            type="submit"
                            variant="contained"
                            loading={loading}
                          >
                            Submit
                          </LoadingButton>
                        </Grid>
                        <Grid item xs={4} sm={4} md={3} lg={2} ml={2} mt={3}>
                          <Button
                            fullWidth
                            size="large"
                            variant="contained"
                            color="error"
                            component={RouterLink}
                            to="/dashboard/stripe-setting"
                          >
                            Cancel
                          </Button>
                        </Grid>
                      </Grid>
                    </Form>
                  )}
                </>
              )}
            </Formik>
            <WebhooksTable webhooks={pageState.webhookEndpoints} stripe={pageState.settings} />
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
}
export default withRole(EditStripeSetting);
