import { toast } from 'react-toastify';
import axios from 'axios';
import * as types from '../constants/actionTypes';
import { request } from '../helpers/axiosRequest';
import { logUserActivity } from './activityLogsActions';

export const API = axios.create({ baseURL: process.env.REACT_APP_API_URL });

// Create Blocklist User
export const addUser = (data, navigate) => async (dispatch) => {
  try {
    dispatch({ type: types.CREATE_BLOCKLIST_ATTEMPT });
    const res = await request({
      url: `${process.env.REACT_APP_API_URL}/blocklist/add-new`,
      auth: true,
      method: 'POST',
      data
    });
    toast.success('User Added Successsully', {
      position: 'top-center',
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      draggable: true,
      progress: undefined
    });
    dispatch({
      type: types.CREATE_BLOCKLIST_SUCCESS,
      payload: { ...data }
    });
    dispatch(logUserActivity(`ADDED_BLOCKLIST_USER`));
    setTimeout(() => {
      navigate('/dashboard/blocklist');
    }, 2000);
  } catch (err) {
    dispatch({ type: types.CREATE_BLOCKLIST_FAIL, payload: err });
    toast.error('Could not add User', {
      position: 'top-center',
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      draggable: true,
      progress: undefined
    });
    throw err;
  }
};

// Get Single User
export const singleUser = (id) => async (dispatch) => {
  dispatch({ type: types.SINGLE_BLOCKLIST_ATTEMPT });
  try {
    const res = await request({
      url: `${process.env.REACT_APP_API_URL}/blocklist/get-by-id/${id}`,
      auth: true,
      method: 'GET'
    });
    dispatch({
      type: types.SINGLE_BLOCKLIST_SUCCESS,
      payload: res.data
    });
  } catch (err) {
    dispatch({ type: types.SINGLE_BLOCKLIST_FAIL, payload: err });
    throw err;
  }
};

// Update User
export const updateUser = (data, navigate) => async (dispatch) => {
  try {
    dispatch({ type: types.UPDATE_BLOCKLIST_ATTEMPT });
    const res = await request({
      url: `${process.env.REACT_APP_API_URL}/blocklist/update-by-id`,
      auth: true,
      method: 'PUT',
      data
    });
    dispatch({
      type: types.UPDATE_BLOCKLIST_SUCCESS,
      payload: { ...data }
    });
    toast.success('User Updated Successsully', {
      position: 'top-center',
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      draggable: true,
      progress: undefined
    });
    dispatch(logUserActivity(`UPDATED_BLOCKLIST_USER`));
    setTimeout(() => {
      navigate('/dashboard/blocklist');
    }, 2000);
  } catch (err) {
    dispatch({ type: types.UPDATE_BLOCKLIST_FAIL, payload: err });
    toast.error('Could not update user', {
      position: 'top-center',
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      draggable: true,
      progress: undefined
    });
    throw err;
  }
};

// Delete User
export const deleteUser = (id) => async (dispatch) => {
  try {
    dispatch({ type: types.DELETE_BLOCKLIST_ATTEMPT });
    const res = await request({
      method: 'POST',
      auth: true,
      url: `${process.env.REACT_APP_API_URL}/blocklist/delete-by-id`,
      data: { id }
    });

    toast.success('User Deleted Successsully', {
      position: 'top-center',
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      draggable: true,
      progress: undefined
    });
    dispatch({ type: types.DELETE_BLOCKLIST_SUCCESS, payload: {} });
    dispatch(logUserActivity(`DELETED_BLOCKLIST_USER`));
    setTimeout(() => {
      window.location.href = '/dashboard/blocklist';
    }, 1000);
  } catch (err) {
    dispatch({ type: types.DELETE_BLOCKLIST_FAIL, payload: err });
    toast.error('Could not delete user', {
      position: 'top-center',
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      draggable: true,
      progress: undefined
    });
    throw new Error(err);
  }
};
