import * as types from '../constants/actionTypes';

const initialState = {
  items: [],
  loading: true,
  services: {},
  service: {}
};

export default function (state = initialState, action) {
  switch (action.type) {
    case types.ADD_PACKAGE_SERVICE_SUCCESS:
      return {
        ...state,
        items: action.payload,
        services: action.payload,
        loading: false
      };
    case types.SINGLE_PACKAGE_SERVICE_SUCCESS:
      return {
        ...state,
        items: action.payload,
        services: action.payload,
        loading: false
      };
    case types.GET_SERVICE_SUCCESS:
      return {
        ...state,
        items: action.payload,
        services: action.payload,
        loading: false
      };
    case types.UPDATE_PACKAGE_SERVICE_SUCCESS:
      return {
        ...state,
        items: action.payload,
        services: action.payload,
        loading: false
      };
    case types.DELETE_PACKAGE_SERVICE_SUCCESS:
      return {
        ...state,
        items: action.payload,
        services: action.payload,
        loading: false
      };
    default:
      return state;
  }
}
