import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link as RouterLink, useNavigate, useParams } from 'react-router-dom';
import { Formik, Field, Form, ErrorMessage, FieldArray } from 'formik';
import { TextField } from 'formik-mui';
// material
import {
  Typography,
  Container,
  Grid,
  FormLabel,
  Button,
  MenuItem,
  CircularProgress
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Loader from 'src/components/Loader';
// ----------------------------------------------------------------------
import { allCountries } from 'src/Redux/actions/countryActions';
import { singleFooterMenu, updateFooterMenu } from 'src/Redux/actions/footerActions';

export default function EditFooter() {
  const { slug } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // Get Sinle Menu
  const menuState = useSelector((state) => state.footerContent?.footer);
  const [menuLoading, setMenuLoading] = useState(false);
  const [loading, setLoading] = useState(false);

  // Get All Countries
  const [countryLoading, setCountryLoading] = useState(false);
  const pageState = useSelector((state) => state.countries?.items);
  useEffect(() => {
    const fetchData = () => {
      setCountryLoading(true);
      try {
        dispatch(allCountries(setCountryLoading));
      } catch (error) {
        setCountryLoading(false);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = () => {
      try {
        setMenuLoading(true);
        setTimeout(() => {
          dispatch(singleFooterMenu(slug));
          setMenuLoading(false);
        }, 1000);
      } catch (error) {
        setMenuLoading(true);
      }
    };
    fetchData();
  }, []);

  return (
    <Container>
      <Grid container justifyContent="center">
        <Grid item lg={10} md={10} xs={10} sm={10}>
          {menuLoading && <Loader />}
          {!menuLoading && menuState && Object.keys(menuState)?.length > 0 && (
            <Card
              sx={{ minWidth: 275, boxShadow: 3, borderRadius: 1, border: '1px solid #929eaa6b' }}
            >
              <CardContent>
                <Formik
                  enableReinitialize
                  initialValues={menuState}
                  //   validationSchema={FooterSchema}
                  onSubmit={(values) => {
                    dispatch(updateFooterMenu(values, navigate));
                    setLoading(true);
                    setTimeout(() => {
                      setLoading(false);
                    }, 1000);
                  }}
                >
                  {({ values, push, remove }) => (
                    <Form autoComplete="off" style={{ padding: 'inherit' }}>
                      <Typography variant="h4" gutterBottom>
                        Edit Footer Content
                      </Typography>
                      {/* Footer 1st Col */}
                      <div className="input-section">
                        {/* <FormLabel id="country">Country</FormLabel> */}
                        {countryLoading && <CircularProgress size={30} />}
                        {!countryLoading && values?.country && pageState?.countries?.length > 0 && (
                          <Field
                            name="country"
                            select
                            component={TextField}
                            sx={{ width: '95%' }}
                            label="Select Country"
                          >
                            <MenuItem value="">Not Selected</MenuItem>
                            {pageState?.countries?.map((item) => {
                              if (item.active == true)
                                return (
                                  <MenuItem
                                    key={item._id}
                                    id={item._id}
                                    value={item.countrySlug}
                                    label={item.countryName}
                                  >
                                    {item.countryName}
                                  </MenuItem>
                                );
                            })}
                          </Field>
                        )}
                      </div>
                      <div className="input-section">
                        <FormLabel htmlFor="ownershipText"> Ownership Text </FormLabel>
                        <Field
                          id="ownershipText"
                          name="ownershipText"
                          type="text"
                          placeholder="Enter Ownership Text"
                          className="input-style"
                        />
                        <ErrorMessage
                          name="ownershipText"
                          component="div"
                          className="error-color"
                        />
                      </div>
                      <div className="input-section">
                        <FormLabel htmlFor="instagramLink"> Instagram Link </FormLabel>
                        <Field
                          id="instagramLink"
                          name="instagramLink"
                          type="text"
                          placeholder="Enter Instagram Link"
                          className="input-style"
                        />
                        <ErrorMessage
                          name="instagramLink"
                          component="div"
                          className="error-color"
                        />
                      </div>
                      <div className="input-section">
                        <FormLabel htmlFor="facebookLink"> Facebook Link </FormLabel>
                        <Field
                          id="facebookLink"
                          name="facebookLink"
                          type="text"
                          placeholder="Enter Facebook Link"
                          className="input-style"
                        />
                        <ErrorMessage name="facebookLink" component="div" className="error-color" />
                      </div>
                      <div className="input-section">
                        <FormLabel htmlFor="linkedinLink"> Linkedin Link </FormLabel>
                        <Field
                          id="linkedinLink"
                          name="linkedinLink"
                          type="text"
                          placeholder="Enter Linkedin Link"
                          className="input-style"
                        />
                        <ErrorMessage name="linkedinLink" component="div" className="error-color" />
                      </div>
                      <div className="input-section">
                        <FormLabel htmlFor="twitterLink"> Twitter Link </FormLabel>
                        <Field
                          id="twitterLink"
                          name="twitterLink"
                          type="text"
                          placeholder="Enter Twitter Link"
                          className="input-style"
                        />
                        <ErrorMessage name="twitterLink" component="div" className="error-color" />
                      </div>
                      {/*  */}

                      <div>
                        <Typography variant="h6" gutterBottom mt={1}>
                          Services Column
                        </Typography>
                        <div className="input-section">
                          <FormLabel htmlFor={`servicesColumn.order`}>Column Order</FormLabel>
                          <Field
                            id={`servicesColumn.order`}
                            name={`servicesColumn.order`}
                            type="text"
                            placeholder="Enter Column Order"
                            className="input-style"
                          />
                          <ErrorMessage
                            name={`servicesColumn.order`}
                            component="div"
                            className="error-color"
                          />
                        </div>
                        <div className="input-section">
                          <FormLabel htmlFor={`servicesColumn.columnTitle`}>Column Title</FormLabel>
                          <Field
                            id={`servicesColumn.columnTitle`}
                            name={`servicesColumn.columnTitle`}
                            type="text"
                            placeholder="Enter Column Title"
                            className="input-style"
                          />
                          <ErrorMessage
                            name={`servicesColumn.columnTitle`}
                            component="div"
                            className="error-color"
                          />
                        </div>

                        {values.servicesColumn?.columnLinks?.length &&
                          values.servicesColumn.columnLinks?.map((items, idx) => {
                            return (
                              <FieldArray key={idx} name="servicesColumn.columnLinks">
                                {({ remove, push }) => (
                                  <div className="row">
                                    <div className="row">
                                      <div className="col button-style">
                                        {' '}
                                        <Button
                                          type="button"
                                          variant="contained"
                                          color="secondary"
                                          sx={{ mt: 2, mb: 2 }}
                                          onClick={() =>
                                            push({
                                              order: '',
                                              columnLinkText: '',
                                              columnLink: ''
                                            })
                                          }
                                        >
                                          Add Links
                                        </Button>
                                        <Button
                                          type="button"
                                          sx={{ mt: 2, ml: 2, mb: 2 }}
                                          variant="contained"
                                          color="error"
                                          onClick={() => remove(idx)}
                                          className="home-section-delete-button"
                                        >
                                          Delete Links
                                        </Button>
                                      </div>
                                      <div className="col button-style">
                                        <Typography variant="h6" gutterBottom mt={1}>
                                          Column Sub Links {idx + 1}
                                        </Typography>
                                        <div className="input-section">
                                          <FormLabel
                                            htmlFor={`servicesColumn.columnLinks[${idx}].order`}
                                          >
                                            Link Order
                                          </FormLabel>
                                          <Field
                                            id={`servicesColumn.columnLinks[${idx}].order`}
                                            name={`servicesColumn.columnLinks[${idx}].order`}
                                            type="text"
                                            placeholder="Enter Title"
                                            className="input-style"
                                          />
                                          <ErrorMessage
                                            name={`servicesColumn.columnLinks[${idx}].order`}
                                            component="div"
                                            className="error-color"
                                          />
                                        </div>
                                        <div className="input-section">
                                          <FormLabel
                                            htmlFor={`servicesColumn.columnLinks[${idx}].columnLinkText`}
                                          >
                                            Link Title
                                          </FormLabel>
                                          <Field
                                            id={`servicesColumn.columnLinks[${idx}].columnLinkText`}
                                            name={`servicesColumn.columnLinks[${idx}].columnLinkText`}
                                            type="text"
                                            placeholder="Enter Title"
                                            className="input-style"
                                          />
                                          <ErrorMessage
                                            name={`servicesColumn.columnLinks[${idx}].columnLinkText`}
                                            component="div"
                                            className="error-color"
                                          />
                                        </div>
                                        <div className="input-section">
                                          <FormLabel
                                            htmlFor={`servicesColumn.columnLinks[${idx}].columnLink`}
                                          >
                                            Title Link
                                          </FormLabel>
                                          <Field
                                            id={`servicesColumn.columnLinks[${idx}].columnLink`}
                                            name={`servicesColumn.columnLinks[${idx}].columnLink`}
                                            type="text"
                                            placeholder="Enter Title Link"
                                            className="input-style"
                                          />
                                          <ErrorMessage
                                            name={`servicesColumn.columnLinks[${idx}].columnLink`}
                                            component="div"
                                            className="error-color"
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                )}
                              </FieldArray>
                            );
                          })}
                      </div>
                      {/* Policies */}
                      <div>
                        <Typography variant="h6" gutterBottom mt={1}>
                          Policies Column
                        </Typography>
                        <div className="input-section">
                          <FormLabel htmlFor={`policiesColumn.order`}>Column Order</FormLabel>
                          <Field
                            id={`policiesColumn.order`}
                            name={`policiesColumn.order`}
                            type="text"
                            placeholder="Enter Column Order"
                            className="input-style"
                          />
                          <ErrorMessage
                            name={`policiesColumn.order`}
                            component="div"
                            className="error-color"
                          />
                        </div>
                        <div className="input-section">
                          <FormLabel htmlFor={`policiesColumn.columnTitle`}>Column Title</FormLabel>
                          <Field
                            id={`policiesColumn.columnTitle`}
                            name={`policiesColumn.columnTitle`}
                            type="text"
                            placeholder="Enter Column Title"
                            className="input-style"
                          />
                          <ErrorMessage
                            name={`policiesColumn.columnTitle`}
                            component="div"
                            className="error-color"
                          />
                        </div>

                        {values.policiesColumn?.columnLinks?.length &&
                          values.policiesColumn.columnLinks?.map((items, idx) => {
                            return (
                              <FieldArray key={idx} name="policiesColumn.columnLinks">
                                {({ remove, push }) => (
                                  <div className="row">
                                    <div className="row">
                                      <div className="col button-style">
                                        <Button
                                          type="button"
                                          variant="contained"
                                          color="secondary"
                                          sx={{ mt: 2, mb: 2 }}
                                          onClick={() =>
                                            push({
                                              order: '',
                                              columnLinkText: '',
                                              columnLink: ''
                                            })
                                          }
                                        >
                                          Add Links
                                        </Button>
                                        <Button
                                          type="button"
                                          sx={{ mt: 2, ml: 2, mb: 2 }}
                                          variant="contained"
                                          color="error"
                                          onClick={() => remove(idx)}
                                          className="home-section-delete-button"
                                        >
                                          Delete Links
                                        </Button>
                                      </div>
                                      <div className="col button-style">
                                        <Typography variant="h6" gutterBottom mt={1}>
                                          Policies Sub Links {idx + 1}
                                        </Typography>
                                        <div className="input-section">
                                          <FormLabel
                                            htmlFor={`policiesColumn.columnLinks[${idx}].order`}
                                          >
                                            Link Order
                                          </FormLabel>
                                          <Field
                                            id={`policiesColumn.columnLinks[${idx}].order`}
                                            name={`policiesColumn.columnLinks[${idx}].order`}
                                            type="text"
                                            placeholder="Enter Title"
                                            className="input-style"
                                          />
                                          <ErrorMessage
                                            name={`policiesColumn.columnLinks[${idx}].order`}
                                            component="div"
                                            className="error-color"
                                          />
                                        </div>
                                        <div className="input-section">
                                          <FormLabel
                                            htmlFor={`policiesColumn.columnLinks[${idx}].columnLinkText`}
                                          >
                                            Link Title
                                          </FormLabel>
                                          <Field
                                            id={`policiesColumn.columnLinks[${idx}].columnLinkText`}
                                            name={`policiesColumn.columnLinks[${idx}].columnLinkText`}
                                            type="text"
                                            placeholder="Enter Title"
                                            className="input-style"
                                          />
                                          <ErrorMessage
                                            name={`policiesColumn.columnLinks[${idx}].columnLinkText`}
                                            component="div"
                                            className="error-color"
                                          />
                                        </div>
                                        <div className="input-section">
                                          <FormLabel
                                            htmlFor={`policiesColumn.columnLinks[${idx}].columnLink`}
                                          >
                                            Title Link
                                          </FormLabel>
                                          <Field
                                            id={`policiesColumn.columnLinks[${idx}].columnLink`}
                                            name={`policiesColumn.columnLinks[${idx}].columnLink`}
                                            type="text"
                                            placeholder="Enter Title Link"
                                            className="input-style"
                                          />
                                          <ErrorMessage
                                            name={`policiesColumn.columnLinks[${idx}].columnLink`}
                                            component="div"
                                            className="error-color"
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                )}
                              </FieldArray>
                            );
                          })}
                      </div>
                      {/* Contact Section */}
                      <div>
                        <Typography variant="h6" gutterBottom mt={1}>
                          Contact Column
                        </Typography>
                        <div className="input-section">
                          <FormLabel htmlFor={`contactColumn.order`}>Column Order</FormLabel>
                          <Field
                            id={`contactColumn.order`}
                            name={`contactColumn.order`}
                            type="text"
                            placeholder="Enter Column Order"
                            className="input-style"
                          />
                          <ErrorMessage
                            name={`contactColumn.order`}
                            component="div"
                            className="error-color"
                          />
                        </div>
                        <div className="input-section">
                          <FormLabel htmlFor={`contactColumn.columnTitle`}>Column Title</FormLabel>
                          <Field
                            id={`contactColumn.columnTitle`}
                            name={`contactColumn.columnTitle`}
                            type="text"
                            placeholder="Enter Column Title"
                            className="input-style"
                          />
                          <ErrorMessage
                            name={`contactColumn.columnTitle`}
                            component="div"
                            className="error-color"
                          />
                        </div>

                        {values.contactColumn?.columnLinks?.length &&
                          values.contactColumn.columnLinks?.map((items, idx) => {
                            return (
                              <FieldArray name="contactColumn.columnLinks" key={idx}>
                                {({ remove, push }) => (
                                  <div className="row">
                                    <div className="row">
                                      <div className="col button-style">
                                        {' '}
                                        <Button
                                          type="button"
                                          variant="contained"
                                          color="secondary"
                                          sx={{ mt: 2, mb: 2 }}
                                          onClick={() =>
                                            push({ order: '', columnLinkText: '', columnLink: '' })
                                          }
                                        >
                                          Add Links
                                        </Button>
                                        <Button
                                          type="button"
                                          sx={{ mt: 2, ml: 2, mb: 2 }}
                                          variant="contained"
                                          color="error"
                                          onClick={() => remove(idx)}
                                          className="home-section-delete-button"
                                        >
                                          Delete Links
                                        </Button>
                                      </div>
                                      <div className="col button-style">
                                        <Typography variant="h6" gutterBottom mt={1}>
                                          Contact Sub Links {idx + 1}
                                        </Typography>
                                        <div className="input-section">
                                          <FormLabel
                                            htmlFor={`contactColumn.columnLinks[${idx}].order`}
                                          >
                                            Link Order
                                          </FormLabel>
                                          <Field
                                            id={`contactColumn.columnLinks[${idx}].order`}
                                            name={`contactColumn.columnLinks[${idx}].order`}
                                            type="text"
                                            placeholder="Enter Title"
                                            className="input-style"
                                          />
                                          <ErrorMessage
                                            name={`contactColumn.columnLinks[${idx}].order`}
                                            component="div"
                                            className="error-color"
                                          />
                                        </div>
                                        <div className="input-section">
                                          <FormLabel
                                            htmlFor={`contactColumn.columnLinks[${idx}].columnLinkText`}
                                          >
                                            Link Title
                                          </FormLabel>
                                          <Field
                                            id={`contactColumn.columnLinks[${idx}].columnLinkText`}
                                            name={`contactColumn.columnLinks[${idx}].columnLinkText`}
                                            type="text"
                                            placeholder="Enter Title"
                                            className="input-style"
                                          />
                                          <ErrorMessage
                                            name={`contactColumn.columnLinks[${idx}].columnLinkText`}
                                            component="div"
                                            className="error-color"
                                          />
                                        </div>
                                        <div className="input-section">
                                          <FormLabel
                                            htmlFor={`contactColumn.columnLinks[${idx}].columnLink`}
                                          >
                                            Title Link
                                          </FormLabel>
                                          <Field
                                            id={`contactColumn.columnLinks[${idx}].columnLink`}
                                            name={`contactColumn.columnLinks[${idx}].columnLink`}
                                            type="text"
                                            placeholder="Enter Title Link"
                                            className="input-style"
                                          />
                                          <ErrorMessage
                                            name={`contactColumn.columnLinks[${idx}].columnLink`}
                                            component="div"
                                            className="error-color"
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                )}
                              </FieldArray>
                            );
                          })}
                      </div>
                      {/*  */}
                      <div>
                        <Typography variant="h6" gutterBottom mt={1}>
                          Copyright Section
                        </Typography>
                        <div className="input-section">
                          <FormLabel htmlFor="copyrightBar.copyrightText">
                            Copyrights Text
                          </FormLabel>
                          <Field
                            id="copyrightBar.copyrightText"
                            name="copyrightBar.copyrightText"
                            type="text"
                            placeholder="Enter Ownership Text"
                            className="input-style"
                          />
                          <ErrorMessage
                            name="copyrightBar.copyrightText"
                            component="div"
                            className="error-color"
                          />
                        </div>
                        <div className="input-section">
                          <FormLabel htmlFor="copyrightBar.copyrightText2">
                            Copyrights Text - 2
                          </FormLabel>
                          <Field
                            id="copyrightBar.copyrightText2"
                            name="copyrightBar.copyrightText2"
                            type="text"
                            placeholder="Enter Ownership Text"
                            className="input-style"
                          />
                          <ErrorMessage
                            name="copyrightBar.copyrightText2"
                            component="div"
                            className="error-color"
                          />
                        </div>
                        <div className="input-section">
                          <FormLabel htmlFor="copyrightBar.siteMapLinkText">Sitemap Link</FormLabel>
                          <Field
                            id="copyrightBar.siteMapLinkText"
                            name="copyrightBar.siteMapLinkText"
                            type="text"
                            placeholder="Enter Sitemap Link"
                            className="input-style"
                          />
                          <ErrorMessage
                            name="copyrightBar.siteMapLinkText"
                            component="div"
                            className="error-color"
                          />
                        </div>
                      </div>

                      <Grid container>
                        <Grid item xs={4} sm={4} md={3} lg={2} mt={3}>
                          <LoadingButton
                            fullWidth
                            size="large"
                            type="submit"
                            variant="contained"
                            loading={loading}
                          >
                            Submit
                          </LoadingButton>
                        </Grid>
                        <Grid item xs={4} sm={4} md={3} lg={2} ml={2} mt={3}>
                          <Button
                            fullWidth
                            size="large"
                            variant="contained"
                            color="error"
                            component={RouterLink}
                            to="/dashboard/footer-content"
                          >
                            Cancel
                          </Button>
                        </Grid>
                      </Grid>
                    </Form>
                  )}
                </Formik>
              </CardContent>
            </Card>
          )}
        </Grid>
      </Grid>
    </Container>
  );
}
