import React, { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import moment from 'moment';
import { Stack, Typography, Button, Container } from '@mui/material';
// Components
import Iconify from '../../components/Iconify';
import Page from '../../components/Page';
import CustomTable from 'src/components/CustomTable';
import Label from 'src/components/Label';
import { getPackages } from 'src/api/api';
import ActionButton from 'src/components/ActionButton';
import { deletePackage } from 'src/Redux/actions/packageActions';

export default function Packages() {
  // Fetch Data from API
  // Searchable Filters
  const [platform, setPlatform] = useState([]);
  const [loading, setLoading] = React.useState(false);
  const [rows, setRows] = useState([]);

  // Get Page Data
  React.useEffect(() => {
    setLoading(true);
    const promise = getPackages();
    Promise.resolve(promise).then((data) => {
      if (data.success) {
        const packages = data?.packages;
        if (packages && packages?.length) {
          setPlatform(data?.packages);
          setRows(data?.packages);
        }
        setLoading(false);
      } else {
        setPlatform([]);
        setRows([]);
        setLoading(false);
      }
    });
  }, []);

  const columns = [
    {
      field: 'packageTitle',
      headerName: 'Package Title',
      width: 180
    },
    { field: 'quantity', headerName: 'Quantity', width: 150 },
    {
      field: 'active',
      headerName: 'Active',
      width: 180,
      renderCell: (params) => (
        <Label color={params.row?.active ? 'success' : 'error'}>
          {params.row?.active ? 'Active' : 'InActive'}
        </Label>
      )
    },
    {
      field: 'createdAt',
      headerName: 'Created At',
      width: 160,
      renderCell: (rowData) => moment(rowData.row.createdAt).format('Do-MM-YYYY')
    },
    {
      field: 'action',
      headerName: 'Action',
      width: 150,
      renderCell: (value) => (
        <ActionButton
          name="Package"
          action={deletePackage}
          value={value.row.action}
          id={value.row._id}
          link={`/dashboard/packages/edit-package/${value.row._id}`}
        />
      )
    }
  ];

  return (
    <Page title="Dashboard: Packages">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={4}>
          <Typography variant="h4" gutterBottom>
            All Packages
          </Typography>
          <Button
            variant="contained"
            component={RouterLink}
            to="/dashboard/packages/add-package"
            startIcon={<Iconify icon="eva:plus-fill" />}
          >
            Add Package
          </Button>
        </Stack>
        <CustomTable
          searchPlaceholder="Search for packages..."
          tableHeading="for Packages"
          platform={platform}
          setRows={setRows}
          rows={rows}
          rowId={(row) => row._id}
          columns={columns}
          loading={loading}
          data={platform?.length ? platform?.length : 'No Data'} // For No Data
        />
      </Container>
    </Page>
  );
}
