import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { listenToPusher } from './Redux/actions/activityLogsActions';

export default function PusherListener() {
  const dispatch = useDispatch();
  const userRole = useSelector(state => state?.auth?.user?.userRole);

  useEffect(() => {
    if (userRole === 1) {
      const unsubscribe = dispatch(listenToPusher());
      return () => unsubscribe();
    }
  }, [dispatch, userRole]);

  return null;
}
