import { toast } from 'react-toastify';
import * as types from '../constants/actionTypes';
import { request } from '../helpers/axiosRequest';
import { logUserActivity } from './activityLogsActions';

// Add Package Service Content
export const createCategoryContent = (data, navigate) => async (dispatch) => {
  try {
    dispatch({ type: types.CREATE_SERVICES_CATEGORY_ATTEMPT });
    const res = await request({
      url: `${process.env.REACT_APP_API_URL}/category/add-category-content`,
      auth: true,
      method: 'POST',
      data
    });

    dispatch({
      type: types.CREATE_SERVICES_CATEGORY_SUCCESS,
      payload: { ...data }
    });
    if (res.data.success === true) {
      toast.success('Category Content Added Successsully');
      dispatch(logUserActivity(`ADDED_CATEGORY_CONTENT`));
      setTimeout(() => {
        window.location.href = '/dashboard/service-content';
      }, 1000);
    }
  } catch (err) {
    dispatch({ type: types.CREATE_SERVICES_CATEGORY_FAIL, payload: err });
    toast.error('Unable to Add Category Content.');
    throw err;
  }
};

// Add Package Service Content
export const createServiceContent = (data, navigate) => async (dispatch) => {
  try {
    dispatch({ type: types.ADD_SINGLE_SERVICE_ATTEMPT });
    const res = await request({
      url: `${process.env.REACT_APP_API_URL}/category/add-new`,
      auth: true,
      method: 'POST',
      data
    });

    dispatch({
      type: types.ADD_SINGLE_SERVICE_SUCCESS,
      payload: { ...data }
    });
    if (res.data.success === true) {
      toast.success('Service Content Added Successsully');
      dispatch(logUserActivity(`ADDED_SERVICE_CONTENT`));
      setTimeout(() => {
        navigate('/dashboard/service-content');
      }, 1000);
    }
  } catch (err) {
    dispatch({ type: types.ADD_SINGLE_SERVICE_FAIL, payload: err });
    toast.error('Unable to Add Service Content.', {
      position: 'top-center',
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      draggable: true,
      progress: undefined
    });
    throw err;
  }
};

// Single Service Content
export const singleServiceContent = (id) => async (dispatch) => {
  dispatch({ type: types.SINGLE_PACKAGE_SERVICE_ATTEMPT });
  try {
    const res = await request({
      url: `${process.env.REACT_APP_API_URL}/category/get-by-id`,
      auth: true,
      method: 'POST',
      data: { id: id }
    });
    dispatch({
      type: types.SINGLE_PACKAGE_SERVICE_SUCCESS,
      payload: res.data
    });
  } catch (err) {
    dispatch({ type: types.SINGLE_PACKAGE_SERVICE_FAIL, payload: err });
    throw err;
  }
};

// Get Single Service Content with country
export const getServiceContent = (category, country) => async (dispatch) => {
  dispatch({ type: types.GET_SERVICE_ATTEMPT });
  try {
    const res = await request({
      url: `${process.env.REACT_APP_API_URL}/category/get-category-content`,
      auth: true,
      method: 'POST',
      data: { country, category }
    });
    dispatch({
      type: types.GET_SERVICE_SUCCESS,
      payload: res.data
    });
  } catch (err) {
    dispatch({ type: types.GET_SERVICE_FAIL, payload: err });
    throw err;
  }
};

// Get All Service Content
export const allServicesContent = () => async (dispatch) => {
  dispatch({ type: types.ALL_SINGLE_SERVICES_ATTEMPT });
  try {
    const res = await request({
      url: `${process.env.REACT_APP_API_URL}/category/get-all`,
      auth: true,
      method: 'POST'
    });
    dispatch({
      type: types.ALL_SINGLE_SERVICES_SUCCESS,
      payload: res.data
    });
  } catch (err) {
    dispatch({ type: types.ALL_SINGLE_SERVICES_FAIL, payload: err });
    throw err;
  }
};

// Update Package Service
export const updateContentService = (data, navigate) => async (dispatch) => {
  try {
    dispatch({ type: types.UPDATE_PACKAGE_SERVICE_ATTEMPT });
    const res = await request({
      url: `${process.env.REACT_APP_API_URL}/category/update-by-id`,
      auth: true,
      method: 'PUT',
      data
    });

    dispatch({
      type: types.UPDATE_PACKAGE_SERVICE_SUCCESS,
      payload: { ...data }
    });
    toast.success('Service Updated Successsully');
    dispatch(logUserActivity(`UPDATED_SERVICE_CONTENT`));
    setTimeout(() => {
      navigate('/dashboard/service-content');
    }, 1000);
  } catch (err) {
    dispatch({ type: types.UPDATE_PACKAGE_SERVICE_FAIL, payload: err });
    toast.error('Unable to Update Service.');
    throw err;
  }
};

// Delete Content Service
export const deleteContentService = (id) => async (dispatch) => {
  try {
    dispatch({ type: types.DELETE_SINGLE_SERVICE_ATTEMPT });
    const res = await request({
      method: 'POST',
      auth: true,
      url: `${process.env.REACT_APP_API_URL}/category/delete-by-id`,
      data: { id: id }
    });

    toast.success('Content Deleted Successsully');
    dispatch(logUserActivity(`DELETED_SERVICE_CONTENT`));
    setTimeout(() => {
      window.location.href = '/dashboard/service-content';
    }, 1000);
    dispatch({ type: types.DELETE_SINGLE_SERVICE_SUCCESS, payload: {} });
  } catch (err) {
    dispatch({ type: types.DELETE_SINGLE_SERVICE_FAIL, payload: err });
    toast.error('Unable to Delete Content');
    throw new Error(err);
  }
};

// Delete Category Content
export const deleteCategoryContent = (data) => async (dispatch) => {
  try {
    dispatch({ type: types.DELETE_CATEGORY_CONTENT_ATTEMPT });
    const res = await request({
      method: 'DELETE',
      auth: true,
      url: `${process.env.REACT_APP_API_URL}/category/delete-category-content`,
      data
    });

    toast.success('Content Deleted Successsully');
    dispatch(logUserActivity(`DELETED_CATEGORY_CONTENT`));
    setTimeout(() => {
      window.location.href = '/dashboard/service-content';
    }, 1000);

    dispatch({ type: types.DELETE_CATEGORY_CONTENT_SUCCESS, payload: {} });
  } catch (err) {
    dispatch({ type: types.DELETE_CATEGORY_CONTENT_FAIL, payload: err });
    toast.error('Unable to Delete Content');
    throw new Error(err);
  }
};
