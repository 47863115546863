import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { toast } from 'react-toastify';
//
import { deleteContentImg } from 'src/api/api';

export default function ImageDeleteDialog({
  open,
  setOpen,
  imageName,
  content,
  index,
  setFieldValue
}) {
  const [deleting, setDeleting] = React.useState(false);
  // close delete dialog
  const handleClose = () => {
    setOpen(null);
  };

  const handleDelete = async () => {
    setDeleting(true);
    deleteContentImg({ filename: imageName })
      .then((data) => {
        if (data.success) {
          toast.success(data.message);
          const longDescription = content?.longDescription || [];
          const column = longDescription.find((ld) => ld.img.src == imageName) || {};
          column.img.src = '';
          longDescription[index] = column;
          content['longDescription'] = longDescription;
          setFieldValue('content', content);
        } else {
          toast.error(data.message);
        }
        setDeleting(false);
        setOpen(null);
      })
      .catch((error) => {
        toast.error(
          error.response?.data?.message ? error.response?.data?.message : 'Unable to delete image'
        );
        setDeleting(false);
        setOpen(null);
      });
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Delete Content Image?</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete this image, this action is irreversible.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} autoFocus color="success" disabled={deleting}>
            No
          </Button>
          <Button onClick={handleDelete} color="error" disabled={deleting}>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
