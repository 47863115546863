import React from 'react';
// MUI Components
import { Container, Stack, Typography } from '@mui/material';
// Custom Components
import Page from 'src/components/Page';
// import Loader from 'src/components/Loader';
import CustomButton from 'src/components/Button';
import MenuTabs from './components/MenuTabs';

function Menus() {
  return (
    <Page title="Dashboard Pages | Menus">
      <Container>
        <Stack direction="row" justifyContent="space-between" mb={1}>
          <Typography variant="h4" gutterBottom>
            Menus
          </Typography>
          <div mb={1}>
            <CustomButton
              btnText="Add New Menu"
              icon="ic:round-create"
              variant="contained"
              color="secondary"
              link="/dashboard/menus/add-menu"
            />
          </div>
        </Stack>
        <MenuTabs />
      </Container>
    </Page>
  );
}

export default Menus;
