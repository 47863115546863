import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { Form, Formik, Field } from 'formik';
import { Switch } from 'formik-mui';
// material
import { Stack, Typography, Grid, FormLabel } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import FormControlLabel from '@mui/material/FormControlLabel';
//
import CustomButton from 'src/components/Button';
// ----------------------------------------------------------------------
import { addBlogCategories } from 'src/Redux/actions/blogsActions'; // Actions

export default function CreateBlogForm() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  // Loading
  const [loading, setLoading] = useState(false);

  // Validation Schema
  const BlogSchema = Yup.object().shape({
    title: Yup.string().required('Category Title is required'),
    slug: Yup.string().required('Category Slug is required')
  });

  return (
    <Grid container justifyContent="center">
      <Grid item lg={8} md={8} xs={10} sm={10}>
        <Card sx={{ minWidth: 275, boxShadow: 3, borderRadius: 1, border: '1px solid #929eaa6b' }}>
          <CardContent>
            <Formik
              enableReinitialize={true}
              initialValues={{
                title: '',
                slug: '',
                active: true
              }}
              validationSchema={BlogSchema}
              onSubmit={async (values) => {
                dispatch(addBlogCategories(values, navigate));
                setLoading(true);
                setTimeout(() => {
                  setLoading(false);
                }, 2000);
              }}
            >
              {({ errors, touched, values }) => (
                <Form autoComplete="off" style={{ padding: 'inherit' }}>
                  <Typography variant="h4" gutterBottom>
                    Add Blog Category
                  </Typography>
                  <Stack spacing={1} mt={2}>
                    <FormLabel id="title">Title</FormLabel>
                    <Field
                      type="text"
                      id="title"
                      name="title"
                      className="input-style"
                      placeholder="Category Title"
                    />
                    {errors.title && touched.title ? (
                      <div className="error-color">{errors.title}</div>
                    ) : null}
                    <FormLabel id="slug">Slug</FormLabel>
                    <Field
                      type="text"
                      id="slug"
                      name="slug"
                      className="input-style"
                      placeholder="Category Slug"
                    />
                    {errors.slug && touched.slug ? (
                      <div className="error-color">{errors.slug}</div>
                    ) : null}

                    <FormLabel id="active">Status</FormLabel>
                    <Grid>
                      <Grid item lg={5} md={5} xs={10} sm={10}>
                        <FormControlLabel
                          control={<Field component={Switch} type="checkbox" name="active" />}
                          label={values.active === true ? 'Active' : 'In-Active'}
                        />
                      </Grid>
                    </Grid>
                  </Stack>
                  <Grid container>
                    <Grid item xs={5} sm={5} md={3} lg={2} mt={3}>
                      <LoadingButton
                        fullWidth
                        size="large"
                        type="submit"
                        variant="contained"
                        loading={loading}
                      >
                        Submit
                      </LoadingButton>
                    </Grid>
                    <Grid item xs={5} sm={5} md={3} lg={2} ml={2} mt={3}>
                      <CustomButton
                        btnText="Cancel"
                        variant="contained"
                        color="error"
                        link="/dashboard/blogs-category"
                        size="large"
                      />
                    </Grid>
                  </Grid>
                </Form>
              )}
            </Formik>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
}
