import React, { useState } from 'react';
import moment from 'moment';
// MUI Components
import { Stack, Typography } from '@mui/material';
// Custom Components
import Page from 'src/components/Page';
import CustomButton from 'src/components/Button';
import ActionButton from 'src/components/ActionButton';
import CustomTable from 'src/components/CustomTable';
import Image from 'src/components/Image';
import { deleteBlog } from 'src/Redux/actions/blogsActions';
import { getBlogs } from 'src/api/api';

function Blogs(props) {
  // Fetch Data from API
  // Searchable Filters
  const [loading, setLoading] = useState(false);
  const [rows, setRows] = useState([]);
  const [platform, setPlatform] = useState([]);

  // Get Page Data
  React.useEffect(() => {
    setLoading(true);
    const promise = getBlogs();
    Promise.resolve(promise).then((data) => {
      if (data.success) {
        const posts = data?.posts;
        if (posts && posts?.length) {
          setPlatform(data?.posts);
          setRows(data?.posts);
        }
        setLoading(false);
      } else {
        setPlatform([]);
        setRows([]);
        setLoading(false);
      }
    });
  }, []);

  // Columns for Coupons
  const columns = [
    {
      field: 'postImg',
      headerName: 'Image',
      width: 110,
      renderCell: (rowData) => (
        <Image
          src={`${process.env.REACT_APP_NODE_ROOT}/uploads/blog_images/thumbnails/${rowData.row.postThumbnail}`}
          alt={rowData.row.title}
          width="70%"
        />
      )
    },
    { field: 'title', headerName: 'Title', width: 150 },
    { field: 'slug', headerName: 'Slug', width: 150 },
    {
      field: 'categoryId',
      headerName: 'Category',
      width: 140,
      renderCell: (params) => (
        <> {params.row?.categoryId ? params.row?.categoryId?.title : 'No Category'}</>
      )
    },
    {
      field: 'createdAt',
      headerName: 'Created At',
      width: 170,
      renderCell: (rowData) => moment(rowData.row.createdAt).format('MMMM Do, YYYY')
    },
    {
      field: 'active',
      headerName: 'Active',
      width: 100,
      renderCell: (rowData) => <>{rowData.row.active == true ? 'Active' : 'In-Active'}</>
    },
    {
      field: 'action',
      headerName: 'Action',
      width: 110,
      renderCell: (value) => (
        <ActionButton
          id={value.row._id}
          name="blog"
          action={deleteBlog}
          link={`/dashboard/blogs/edit-blog/${value.row._id}`}
        />
      )
    }
  ];

  return (
    <Page title={props.title ? 'Dashboard' : 'Dashboard | Blogs'} p={props?.userRole ? 0 : 3}>
      {!props?.userRole ? (
        <Stack direction="row" justifyContent="space-between" mb={1}>
          <Typography variant="h4" gutterBottom>
            Blogs
          </Typography>
          <CustomButton
            btnText="Add Blog"
            icon="ic:round-create"
            variant="contained"
            color="info"
            link="/dashboard/blogs/add-blog"
          />
        </Stack>
      ) : (
        ''
      )}
      <CustomTable
        searchPlaceholder="Search..."
        tableHeading="for Blogs"
        platform={platform}
        setRows={setRows}
        rows={rows}
        rowId={(row) => row._id}
        columns={columns}
        loading={loading}
        data={platform?.length ? platform?.length : 'No Data'} // For No Data
      />
    </Page>
  );
}

export default Blogs;
