import { toast } from 'react-toastify';
import axios from 'axios';
import * as types from '../constants/actionTypes';
import { request } from '../helpers/axiosRequest';
import { logUserActivity } from './activityLogsActions';

export const API = axios.create({ baseURL: process.env.REACT_APP_API_URL });

// Add Country
export const addCountry = (data, navigate) => async (dispatch) => {
  try {
    dispatch({ type: types.ADD_COUNTRY_ATTEMPT });
    const res = await request({
      url: `${process.env.REACT_APP_API_URL}/country/add-country`,
      auth: true,
      method: 'POST',
      data
    });
    toast.success('Country Added Successsully', {
      position: 'top-center',
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      draggable: true,
      progress: undefined
    });
    dispatch({
      type: types.ADD_COUNTRY_SUCCESS,
      payload: { ...data }
    });
    dispatch(logUserActivity(`ADDED_COUNTRY`));
    setTimeout(() => {
      window.location.href = '/dashboard/countries';
    }, 1000);
  } catch (err) {
    toast.error('Error: Country with the slug may exist.', {
      position: 'top-center',
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      draggable: true,
      progress: undefined
    });
    dispatch({ type: types.ADD_COUNTRY_FAIL, payload: err });
    throw err;
  }
};

// Get All Countries
export const allCountries = (setLoading) => async (dispatch) => {
  dispatch({ type: types.GET_COUNTRIES_ATTEMPT });
  try {
    const res = await request({
      url: `${process.env.REACT_APP_API_URL}/country/get-all`,
      auth: true,
      method: 'POST'
    });
    dispatch({
      type: types.GET_COUNTRIES_SUCCESS,
      payload: res.data
    });
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  } catch (err) {
    dispatch({ type: types.GET_COUNTRIES_FAIL, payload: err });
    setLoading(false);
    throw err;
  }
};

// Get Single Country
export const singleCountry = (slug) => async (dispatch) => {
  dispatch({ type: types.SINGLE_COUNTRY_ATTEMPT });
  try {
    const res = await request({
      url: `${process.env.REACT_APP_API_URL}/country/get-country`,
      auth: true,
      method: 'POST',
      data: { countrySlug: slug }
    });
    dispatch({
      type: types.SINGLE_COUNTRY_SUCCESS,
      payload: res.data
    });
  } catch (err) {
    dispatch({ type: types.SINGLE_COUNTRY_FAIL, payload: err });
    throw err;
  }
};

// Update Country
export const updateCountry = (data, navigate) => async (dispatch) => {
  try {
    dispatch({ type: types.UPDATE_COUNTRY_ATTEMPT });
    const res = await request({
      url: `${process.env.REACT_APP_API_URL}/country/update-country`,
      auth: true,
      method: 'PUT',
      data
    });

    dispatch({
      type: types.UPDATE_COUNTRY_SUCCESS,
      payload: { ...data }
    });
    toast.success('Country Updated Successsully', {
      position: 'top-center',
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      draggable: true,
      progress: undefined
    });
    dispatch(logUserActivity(`UPDATED_COUNTRY`));
    setTimeout(() => {
      window.location.href = '/dashboard/countries';
    }, 1000);
  } catch (err) {
    dispatch({ type: types.UPDATE_COUNTRY_FAIL, payload: err });
    toast.error('Unable to Update Country.', {
      position: 'top-center',
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      draggable: true,
      progress: undefined
    });
    throw err;
  }
};

// Delete Country
export const deleteCountry = (slug) => async (dispatch) => {
  try {
    dispatch({ type: types.DELETE_COUNTRY_ATTEMPT });
    const res = await request({
      method: 'DELETE',
      auth: true,
      url: `${process.env.REACT_APP_API_URL}/country/delete-country`,
      data: { countrySlug: slug }
    });

    toast.success('Country Deleted Successsully', {
      position: 'top-center',
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      draggable: true,
      progress: undefined
    });
    dispatch(logUserActivity(`DELETED_COUNTRY`));
    setTimeout(() => {
      window.location.href = '/dashboard/countries';
    }, 1000);

    dispatch({ type: types.DELETE_COUNTRY_SUCCESS, payload: {} });
  } catch (err) {
    dispatch({ type: types.DELETE_COUNTRY_FAIL, payload: err });
    throw new Error(err);
  }
};
