import React from 'react';
// MUI Components
import { Container, Stack, Typography } from '@mui/material';
import Chip from '@mui/material/Chip';
import LanguageIcon from '@mui/icons-material/Language';
// Custom Components
import Page from 'src/components/Page';
// import Loader from 'src/components/Loader';
import CustomButton from 'src/components/Button';
import PageTabs from './components/PageTabs';

function HomePageContent() {
  const handleClick = () => {
    console.info('You clicked the Chip.');
  };

  const handleDelete = () => {
    console.info('You clicked the delete icon.');
  };
  return (
    <Page title="Dashboard Pages | Home Page Content">
      <Container>
        <Stack direction="row" justifyContent="space-between" mb={2}>
          <Typography variant="h4" gutterBottom>
            Home Page Content
          </Typography>
          <CustomButton
            btnText="Add Page"
            icon="ic:round-create"
            variant="contained"
            color="secondary"
            link="/dashboard/home-page-content/add-page"
          />
        </Stack>
        <Chip
          color="success"
          variant="outlined"
          label="Country Names"
          onClick={handleClick}
          onDelete={handleDelete}
          deleteIcon={<LanguageIcon />}
        />
        <PageTabs />
      </Container>
    </Page>
  );
}

export default HomePageContent;
