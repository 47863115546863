import React, { useState, useEffect } from 'react';
// MUI Components
import { Stack, Typography } from '@mui/material';
import Skeleton from '@mui/material/Skeleton';
import Box from '@mui/material/Box';
// Components
import Image from 'src/components/Image';

export default function Loader(props) {
  // Loading
  const [loading, setLoading] = useState(false);
  const [state, setState] = useState({});

  // Check the data is exist or not.
  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      const value = props.data;
      if (value) setLoading(false);
    }, 3000);
    return () => {
      setState({}); // To Avoid Memory Leak
    };
  }, [props.data]);

  return (
    <>
      {loading ? (
        <Box sx={{ width: 350, mx: 'auto' }}>
          <Skeleton />
          <Skeleton animation="wave" />
          <Skeleton animation={false} />
          <Skeleton />
          <Skeleton animation="wave" />
          <Skeleton animation={false} />
        </Box>
      ) : (
        // In case of No data exists.
        <Stack sx={{ alignItems: 'center' }}>
          <Typography variant="h4" gutterBottom mb={2}>
            Sorry, No Data Found
          </Typography>
          <Image src="/static/empty.svg" width="35%" height="auto" alt="" />
        </Stack>
      )}
    </>
  );
}
