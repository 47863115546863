import React, { useState } from 'react';
import moment from 'moment';
// MUI Components
import { Container, Stack, Typography } from '@mui/material';
// Custom Components
import Page from 'src/components/Page';
import CustomButton from 'src/components/Button';
import ActionButton from 'src/components/ActionButton';
import CustomTable from 'src/components/CustomTable';
//
import { getBlogCategories } from 'src/api/api';
import { deleteBlogCategory } from 'src/Redux/actions/blogsActions';

function BlogCategories() {
  // Fetch Data from API
  // Searchable Filters
  const [platform, setPlatform] = useState([]);
  const [loading, setLoading] = React.useState(false);
  const [rows, setRows] = useState([]);

  // Get Categories
  React.useEffect(() => {
    setLoading(true);
    const promise = getBlogCategories();
    Promise.resolve(promise).then((data) => {
      if (data.success) {
        const categories = data?.categories;
        if (categories && categories?.length) {
          setPlatform(data?.categories);
          setRows(data?.categories);
        }
        setLoading(false);
      } else {
        setPlatform([]);
        setRows([]);
        setLoading(false);
      }
    });
  }, []);

  // Columns for Categories
  const columns = [
    { field: 'title', headerName: 'Name', minWidth: 180 },
    { field: 'slug', headerName: 'Slug', minWidth: 170 },
    {
      field: 'createdAt',
      headerName: 'Created At',
      minWidth: 180,
      renderCell: (rowData) => moment(rowData.row.createdAt).format('MMMM Do, YYYY')
    },
    {
      field: 'active',
      headerName: 'Status',
      minWidth: 150,
      renderCell: (rowData) => <>{rowData.row.active == true ? 'Active' : 'In-Active'}</>
    },
    {
      field: 'action',
      headerName: 'Action',
      minWidth: 130,
      renderCell: (value) => (
        <ActionButton
          action={deleteBlogCategory}
          id={value.row._id}
          name="Category"
          link={`/dashboard/blogs-category/edit-category/${value.row._id}`}
        />
      )
    }
  ];

  return (
    <Page title="Dashboard Pages | Blog Categories">
      <Container>
        <Stack direction="row" justifyContent="space-between" mb={1}>
          <Typography variant="h4" gutterBottom>
            Blog Categories
          </Typography>
          <CustomButton
            btnText="Add Category"
            icon="eva:plus-fill"
            variant="contained"
            color="secondary"
            link="/dashboard/blogs-category/add-category"
          />
        </Stack>
        <CustomTable
          searchPlaceholder="Search..."
          tableHeading="for Blog Categories"
          platform={platform}
          setRows={setRows}
          rows={rows}
          rowId={(row) => row._id}
          columns={columns}
          loading={loading}
          data={platform?.length ? platform?.length : 'No Data'} // For No Data
        />
      </Container>
    </Page>
  );
}

export default BlogCategories;
