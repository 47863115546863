import React from 'react';
import { isAuth } from 'src/Redux/actions/authActions';
import { useDispatch, useSelector } from 'react-redux';

// This function takes a component...
function withAuthentication(WrappedComponent) {
  // ...and returns another component...
  return function WithAuthComponent() {
    const dispatch = useDispatch();
    const auth = useSelector((state) => state.auth);

    React.useEffect(() => {
      dispatch(isAuth());
    }, []);

    // ... and renders the wrapped component with the fresh data!
    // Notice that we pass through any additional props
    return <WrappedComponent user={auth.user} loading={auth.loading} />;
  };
}

export default withAuthentication;
