import { toast } from 'react-toastify';
import axios from 'axios';
import * as types from '../constants/actionTypes';
import { request } from '../helpers/axiosRequest';
import { logUserActivity } from './activityLogsActions';

export const API = axios.create({ baseURL: process.env.REACT_APP_API_URL });

// Add Package
export const addPackage = (data, navigate) => async (dispatch) => {
  try {
    dispatch({ type: types.CREATE_PACKAGE_ATTEMPT });
    const res = await request({
      url: `${process.env.REACT_APP_API_URL}/package/add-new`,
      auth: true,
      method: 'POST',
      data
    });
    toast.success('Package Added Successsully', {
      position: 'top-center',
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      draggable: true,
      progress: undefined
    });

    dispatch({
      type: types.CREATE_PACKAGE_SUCCESS,
      payload: { ...data }
    });
    dispatch(logUserActivity(`ADDED_PACKAGE`));
    setTimeout(() => {
      navigate('/dashboard/packages');
    }, 2000);
  } catch (err) {
    dispatch({ type: types.CREATE_PACKAGE_FAIL, payload: err });
    const resError = err.response.data;
    const existingArr = resError.currentRec?.map(
      (item) => item?.country.countryName + ' - ' + item.slug + ' , '
    );
    toast.error(<>Package with country and slug " {existingArr} " may exists</>, {
      position: 'top-center',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      draggable: true,
      progress: undefined
    });
    throw err;
  }
};

// Get Single Package
export const singlePackage = (id) => async (dispatch) => {
  dispatch({ type: types.SINGLE_PACKAGE_ATTEMPT });
  try {
    const res = await request({
      url: `${process.env.REACT_APP_API_URL}/package/get-by-id/${id}`,
      auth: true,
      method: 'GET'
    });

    dispatch({
      type: types.SINGLE_PACKAGE_SUCCESS,
      payload: res.data
    });
  } catch (err) {
    dispatch({ type: types.SINGLE_PACKAGE_FAIL, payload: err });
    throw err;
  }
};

export const emptyPackageData = () => (dispatch) => {
  dispatch({
    type: types.SINGLE_PACKAGE_SUCCESS,
    payload: {}
  });
};

// Get All Categories
export const getAllCategories = () => async (dispatch) => {
  dispatch({ type: types.GET_CATEGORY_ATTEMPT });
  try {
    const res = await request({
      url: `${process.env.REACT_APP_API_URL}/package/get-all`,
      auth: true,
      method: 'GET'
    });

    dispatch({
      type: types.GET_CATEGORY_SUCCESS,
      payload: res.data
    });
  } catch (err) {
    dispatch({ type: types.GET_CATEGORY_FAIL, payload: err });
    throw err;
  }
};

// Upadate Package
export const updatePackage = (data, navigate) => async (dispatch) => {
  try {
    dispatch({ type: types.UPDATE_PACAKGE_ATTEMPT });
    const res = await request({
      url: `${process.env.REACT_APP_API_URL}/package/update-by-id/${data._id}`,
      auth: true,
      method: 'PUT',
      data
    });
    dispatch({
      type: types.UPDATE_PACAKGE_SUCCESS,
      payload: { ...data }
    });
    toast.success('Package Updated Successsully', {
      position: 'top-center',
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      draggable: true,
      progress: undefined
    });
    dispatch(logUserActivity(`UPDATED_PACKAGE`));
    setTimeout(() => {
      navigate('/dashboard/packages');
    }, 2000);
  } catch (err) {
    dispatch({ type: types.UPDATE_PACAKGE_FAIL, payload: err });
    const resError = err.response.data;
    const existingArr = resError.currentRec?.map(
      (item) => item?.countryName + ' - ' + item.slug + ' , '
    );
    toast.error(<>Package with country and slug " {existingArr} " may exists</>, {
      position: 'top-center',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      draggable: true,
      progress: undefined
    });
    throw err;
  }
};

// Delete Package Details
export const deletePackDetails = (id, values) => async (dispatch) => {
  try {
    dispatch({ type: types.DELETE_PACKAGE_DETAILS_ATTEMPT });
    const res = await request({
      method: 'POST',
      auth: true,
      url: `${process.env.REACT_APP_API_URL}/package/delete-package`,
      data: { id, values }
    });
    toast.success('Package Detail Deleted Successsully', {
      position: 'top-center',
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      draggable: true,
      progress: undefined
    });
    const remaining = values.details.filter((d) => d._id !== id);
    values.details = remaining;
    dispatch(logUserActivity(`DELETED_PACKAGE_DETAILS`));
    dispatch({ type: types.DELETE_PACKAGE_DETAILS_SUCCESS, payload: values });
  } catch (err) {
    dispatch({ type: types.DELETE_PACKAGE_DETAILS_FAIL, payload: err });
    toast.error('Error: Unable to Delete this Package', {
      position: 'top-center',
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      draggable: true,
      progress: undefined
    });
    throw new Error(err);
  }
};

// Delete Package
export const deletePackage = (id) => async (dispatch) => {
  try {
    dispatch({ type: types.DELETE_PACAKGE_ATTEMPT });
    const res = await request({
      method: 'POST',
      auth: true,
      url: `${process.env.REACT_APP_API_URL}/package/delete-by-id`,
      data: { id }
    });

    toast.success('Package Deleted Successsully', {
      position: 'top-center',
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      draggable: true,
      progress: undefined
    });
    dispatch(logUserActivity(`DELETED_PACKAGE`));
    setTimeout(() => {
      window.location.href = '/dashboard/packages';
    }, 1000);
    dispatch({ type: types.DELETE_PACAKGE_SUCCESS, payload: {} });
  } catch (err) {
    toast.error('Error: Unable to Delete this Package', {
      position: 'top-center',
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      draggable: true,
      progress: undefined
    });
    dispatch({ type: types.DELETE_PACAKGE_FAIL, payload: err });
    throw new Error(err);
  }
};
