import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
// material
import { Container, Stack, Typography } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
// components
import CustomButton from 'src/components/Button';
import StripeSettingAction from './components/StripeSettingAction';
import Page from '../../components/Page';
import { getStripeSettingList } from 'src/api/api';
import withRole from 'src/components/HOC/withRole';
import Loader from 'src/components/Loader';

function StripeSetting(props) {
  const navigate = useNavigate();
  // Fetch Data from API
  // Searchable Filters
  const [stripeSettings, setStripeSettings] = useState([]);
  const [loading, setLoading] = React.useState(false);
  // For User Role
  const userRole = props.user?.userRole;

  // Get All Settings
  React.useEffect(() => {
    setLoading(true);
    getStripeSettingList().then((data) => {
      if (data.success) {
        const settings = data?.settings || [];
        setStripeSettings(settings);
        setLoading(false);
      } else {
        setLoading(false);
      }
    });
    const type = userRole == '1' ? null : navigate('/dashboard/app');
    return () => {
      // To Avoid Memory Leak
      return type;
    };
  }, []);

  // Stripe Table
  const columns = [
    { field: 'companyName', headerName: 'Company Name', width: 150 },
    {
      field: 'currency',
      headerName: 'Currency',
      width: 100,
      renderCell: (rowData) => <p style={{ textTransform: 'uppercase' }}>{rowData.row.currency}</p>
    },
    {
      field: 'publicKey',
      headerName: 'Public Key',
      width: 220
    },
    {
      field: 'secretkey',
      headerName: 'Secret Key',
      width: 220
    },
    {
      field: 'active',
      headerName: 'Status',
      width: 110,
      renderCell: (rowData) => <>{rowData.row.active === true ? 'Active' : 'In-Active'}</>
    },
    {
      field: 'action',
      headerName: 'Action',
      width: 130,
      renderCell: (rowData) => <StripeSettingAction id={rowData.row._id} />
    }
  ];

  return (
    <Page title="Dashboard: Stripe Setting">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mt={3} mb={2}>
          <Typography variant="h4" gutterBottom>
            Stripe Settings
          </Typography>
          <CustomButton
            variant="contained"
            icon="material-symbols:add"
            link="/dashboard/stripe-setting/add"
            btnText="Add Settings"
          />
        </Stack>
        {loading ? (
          <Loader />
        ) : (
          <div
            style={{
              width: '100%',
              boxShadow: 3,
              borderRadius: 10,
              border: '1px solid #929eaa6b'
            }}
          >
            <DataGrid
              rows={stripeSettings}
              columns={columns}
              getRowId={(row) => row?._id}
              pageSize={10}
              rowsPerPageOptions={[10]}
              disableSelectionOnClick
              // checkboxSelection
              autoHeight={true}
            />
          </div>
        )}
      </Container>
    </Page>
  );
}
export default withRole(StripeSetting);
