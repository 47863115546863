import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import { useNavigate, useParams } from 'react-router-dom';
import { Form, Formik, Field } from 'formik';
import { Switch } from 'formik-mui';
// material
import { Stack, Typography, Grid, FormLabel } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import FormControlLabel from '@mui/material/FormControlLabel';
//
import CustomButton from 'src/components/Button';
//
import { singleCountry, updateCountry } from 'src/Redux/actions/countryActions';

export default function EditCountries() {
  const { slug } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  // Get data from Redux State
  const pageState = useSelector((state) => state.countries.countries);
  const [pageSections, setPageSection] = useState({
    data: []
  });
  const formData = pageState;

  // Get Single Country
  useEffect(() => {
    setPageSection({
      ...formData
    });
  }, [pageState.country]);

  useEffect(() => {
    const fetchData = () => {
      try {
        setLoading(true);
        dispatch(singleCountry(slug));
        setLoading(false);
      } catch (error) {
        setLoading(true);
      }
    };
    fetchData();
  }, []);

  // Validation Schema
  const CountrySchema = Yup.object().shape({
    countryName: Yup.string().required('Country Name is required'),
    countrySlug: Yup.string().required('Country Slug is required'),
    currencySymbol: Yup.string().required('Currency Symbol is required'),
    currencyCode: Yup.string().required('Currency Code is required')
  });

  return (
    <Grid container justifyContent="center">
      <Grid item lg={10} md={10} xs={10} sm={10} sx={{ boxShadow: 3, borderRadius: 3 }}>
        <Card sx={{ minWidth: 275, boxShadow: 3, borderRadius: 1, border: '1px solid #929eaa6b' }}>
          <CardContent>
            <Formik
              enableReinitialize={true}
              initialValues={{
                id: pageState.country ? pageState.country._id : '',
                countryName: pageState.country ? pageState.country?.countryName : '',
                countrySlug: pageState.country ? pageState.country?.countrySlug : '',
                currencySymbol: pageState.country ? pageState.country?.currencySymbol : '',
                currencyCode: pageState.country ? pageState.country?.currencyCode : '',
                active: pageState.country ? pageState.country?.active : false
              }}
              validationSchema={CountrySchema}
              onSubmit={async (values) => {
                dispatch(updateCountry(values, navigate));
                setLoading(true);
                setTimeout(() => {
                  setLoading(false);
                }, 1000);
              }}
            >
              {({ errors, touched, values }) => (
                <Form autoComplete="off" style={{ padding: 'inherit' }}>
                  <Typography variant="h4" gutterBottom>
                    Edit Country ({pageState.country?.countryName})
                  </Typography>
                  <Stack spacing={1} mt={2}>
                    <FormLabel id="countryName">Country Name</FormLabel>
                    <Field
                      type="text"
                      id="countryName"
                      name="countryName"
                      className="input-style"
                      placeholder="Country Name"
                    />
                    {errors.countryName && touched.countryName ? (
                      <div className="error-color">{errors.countryName}</div>
                    ) : null}
                    <FormLabel id="countrySlug">Country Slug</FormLabel>
                    <Field
                      type="text"
                      id="countrySlug"
                      name="countrySlug"
                      className="input-style"
                      placeholder="Country Slug"
                      disabled
                    />
                    <p className="helper-text-style">Cannot change Country Slug</p>
                    {errors.countrySlug && touched.countrySlug ? (
                      <div className="error-color">{errors.countrySlug}</div>
                    ) : null}
                    <FormLabel id="currencySymbol">Currency Symbol</FormLabel>
                    <Field
                      type="text"
                      id="currencySymbol"
                      name="currencySymbol"
                      className="input-style"
                      placeholder="i.e: $"
                      maxlength="1"
                      size="1"
                    />
                    {errors.currencySymbol && touched.currencySymbol ? (
                      <div className="error-color">{errors.currencySymbol}</div>
                    ) : null}
                    <FormLabel id="currencyCode">Currency Code</FormLabel>
                    <Field
                      type="text"
                      id="currencyCode"
                      name="currencyCode"
                      className="input-style"
                      placeholder="Country Code"
                    />
                    {errors.currencyCode && touched.currencyCode ? (
                      <div className="error-color">{errors.currencyCode}</div>
                    ) : null}
                    <FormLabel id="active">Status</FormLabel>
                    <Grid>
                      <Grid item lg={5} md={5} xs={10} sm={10}>
                        <FormControlLabel
                          control={<Field component={Switch} type="checkbox" name="active" />}
                          label={values.active === true ? 'Active' : 'In-Active'}
                        />
                      </Grid>
                    </Grid>
                  </Stack>
                  <Grid container>
                    <Grid item xs={5} sm={5} md={3} lg={2} mt={3}>
                      <LoadingButton
                        fullWidth
                        size="large"
                        type="submit"
                        variant="contained"
                        loading={loading}
                      >
                        Submit
                      </LoadingButton>
                    </Grid>
                    <Grid item xs={5} sm={5} md={3} lg={2} ml={2} mt={3}>
                      <CustomButton
                        btnText="Cancel"
                        variant="contained"
                        color="error"
                        link="/dashboard/countries"
                        size="large"
                      />
                    </Grid>
                  </Grid>
                </Form>
              )}
            </Formik>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
}
