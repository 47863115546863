import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
// MUI Components
import { Box, Container, Stack, Tab, Tabs, Typography, tabsClasses } from '@mui/material';
// Custom Components
import Page from 'src/components/Page';
import CustomButton from 'src/components/Button';
//
import Loader from 'src/components/Loader';
import SingleServicesTable from './components/SingleServicesTable';
import { getServicesWithCategories } from 'src/api/api';
import { allCountries } from 'src/Redux/actions/countryActions';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`
  };
}
function SingleServiceContent() {
  const dispatch = useDispatch();
  const pageState = useSelector((state) => state.countries.items);

  // Fetch Data from API
  // Searchable Filters
  const [loading, setLoading] = React.useState(false);
  const [rows, setRows] = useState([]);

  // get countries
  const [countryLoading, setCountryLoading] = React.useState(false);
  const [value, setValue] = React.useState(0);

  // Get All Services
  React.useEffect(() => {
    setLoading(true);
    const promise = getServicesWithCategories();
    Promise.resolve(promise).then((data) => {
      if (data.success) {
        const services = data?.services;
        if (services && services?.length) {
          setRows(data?.services);
        }
        setLoading(false);
      } else {
        setRows([]);
        setLoading(false);
      }
    });
  }, []);

  React.useEffect(() => {
    const fetchData = () => {
      setCountryLoading(true);
      try {
        dispatch(allCountries(setCountryLoading));
      } catch (error) {
        setCountryLoading(false);
      }
    };
    fetchData();
  }, []);

  // Change Menu Values
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Page title="Dashboard Pages | Single Service Content">
      <Container>
        <Stack direction="row" justifyContent="space-between" mb={1}>
          <Typography variant="h4" gutterBottom>
            Single Service Content
          </Typography>
          <CustomButton
            btnText="Add Service Content"
            icon="ic:round-create"
            variant="contained"
            color="secondary"
            link="/dashboard/service-content/add-service-content"
          />
        </Stack>
        {loading && <Loader />}
        {!loading && (
          <Box sx={{ mt: 2 }}>
            <Box sx={{ width: '100%' }}>
              <Tabs
                value={value}
                onChange={handleChange}
                variant="scrollable"
                scrollButtons
                aria-label="visible arrows tabs example"
                sx={{
                  [`& .${tabsClasses.scrollButtons}`]: {
                    '&.Mui-disabled': { opacity: '0.3' }
                  }
                }}
              >
                {pageState.countries?.map((item, index) => (
                  <Tab label={item?.countryName} {...a11yProps(index)} key={index} />
                ))}
              </Tabs>
            </Box>

            {pageState.countries?.map((item, index) => (
              <TabPanel value={value} index={index} key={index}>
                <Box sx={{ borderRadius: 1, border: '1px solid #929eaa6b' }}>
                  <SingleServicesTable data={rows} slug={item.countrySlug} />
                </Box>
              </TabPanel>
            ))}
          </Box>
        )}
      </Container>
    </Page>
  );
}

export default SingleServiceContent;
