import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// MUI Components
import { Container, Stack, Typography } from '@mui/material';
// Custom Components
import CoutryTabs from './components/CoutryTabs';
import Page from 'src/components/Page';
// import Loader from 'src/components/Loader';
import CustomButton from 'src/components/Button';
//
import { allCountries } from 'src/Redux/actions/countryActions';

function Countries() {
  const dispatch = useDispatch();
  const pageState = useSelector((state) => state.countries.items);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchData = () => {
      setLoading(true);
      try {
        dispatch(allCountries(setLoading));
      } catch (error) {
        setLoading(true);
      }
    };
    fetchData();
  }, []);

  return (
    <Page title="Dashboard Pages | Countries">
      <Container>
        <Stack direction="row" justifyContent="space-between" mb={3}>
          <Typography variant="h4" gutterBottom>
            All Countries
          </Typography>
          <CustomButton
            btnText="Add Country"
            icon="ic:round-create"
            variant="contained"
            color="info"
            link="/dashboard/countries/add-country"
          />
        </Stack>
        <CoutryTabs data={pageState?.countries} loading={loading} />
      </Container>
    </Page>
  );
}

export default Countries;
