import axios from 'axios';
import * as types from '../constants/actionTypes';
import { request } from '../helpers/axiosRequest';
import { toast } from 'react-toastify';
import { logUserActivity } from './activityLogsActions';

export const API = axios.create({ baseURL: process.env.REACT_APP_API_URL });

// Update Order Item
export const updateOrderItem = (data, id) => async (dispatch) => {
  try {
    dispatch({ type: types.UPDATE_ORDER_ITEM_ATTEMPT });
    const res = await request({
      url: `${process.env.REACT_APP_API_URL}/orders/update-order-item-post`,
      auth: true,
      method: 'post',
      data
    });
    dispatch({
      type: types.UPDATE_ORDER_ITEM_SUCCESS,
      payload: { ...data }
    });
    toast.success('Order Item Updated Successsully', {
      position: 'top-center',
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      draggable: true,
      progress: undefined
    });
    dispatch(logUserActivity(`UPDATED_ORDER_ITEM`));
    setTimeout(() => {
      window.location.href = `/dashboard/orders/order-details/${id}`;
    }, 2000);
  } catch (err) {
    dispatch({ type: types.UPDATE_ORDER_ITEM_FAIL, payload: err });
    toast.error('Unable to Update Order Item.', {
      position: 'top-center',
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      draggable: true,
      progress: undefined
    });
    throw err;
  }
};

// Update Order Item
export const placeOrder = (data, id) => async (dispatch) => {
  try {
    dispatch({ type: types.UPDATE_PLACE_ORDER_ATTEMPT });
    const res = await request({
      url: `${process.env.REACT_APP_API_URL}/orders/place-order`,
      auth: true,
      method: 'post',
      data
    });
    dispatch({
      type: types.UPDATE_PLACE_ORDER_SUCCESS,
      payload: { ...data }
    });
    toast.success('Order Placed Successsully', {
      position: 'top-center',
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      draggable: true,
      progress: undefined
    });
    dispatch(logUserActivity(`PLACED_ORDER`));
    setTimeout(() => {
      window.location.href = `/dashboard/orders/order-details/${id}`;
    }, 2000);
  } catch (err) {
    console.log(err);
    dispatch({ type: types.UPDATE_PLACE_ORDER_FAIL, payload: err });
    toast.error('Unable to Place the Order.', {
      position: 'top-center',
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      draggable: true,
      progress: undefined
    });
    throw err;
  }
};

// Get All Orders
export const allOrders = () => async (dispatch) => {
  dispatch({ type: types.GET_ORDERS_ATTEMPT });
  try {
    const res = await request({
      url: `${process.env.REACT_APP_API_URL}/orders/get-all`,
      auth: true,
      method: 'GET'
    });
    dispatch({
      type: types.GET_ORDERS_SUCCESS,
      payload: res.data
    });
  } catch (err) {
    dispatch({ type: types.GET_ORDERS_FAIL, payload: err });
    throw err;
  }
};

// Get Single Order
export const singleOrder = (id) => {
  return async (dispatch) => {
    dispatch({ type: types.SINGLE_ORDER_ATTEMPT });
    try {
      const res = await request({
        url: `${process.env.REACT_APP_API_URL}/orders/get-order/${id}`,
        auth: true,
        method: 'GET'
      });
      dispatch({
        type: types.SINGLE_ORDER_SUCCESS,
        payload: res.data
      });
    } catch (err) {
      dispatch({ type: types.SINGLE_ORDER_FAIL, payload: err });
      throw err;
    }
  };
};
