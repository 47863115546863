import React, { useState } from 'react';
import moment from 'moment';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
//
import { Stack, Typography, Box, Button } from '@mui/material';
import Tabs, { tabsClasses } from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
//
import Label from 'src/components/Label';
import Page from 'src/components/Page';
import CustomTable from 'src/components/CustomTable';
import withRole from 'src/components/HOC/withRole';
import { getBlocklist } from 'src/api/api'; // Blocklist
import Iconify from 'src/components/Iconify';
import BlocklistActionButton from './components/BlocklistActionButton';

function BlockList(props) {
  const navigate = useNavigate();
  const [loading, setLoading] = React.useState(false);
  const [platform, setPlatform] = useState([]);
  // Tabs
  const [tabValue, setTabValue] = React.useState(0);
  // For User Role
  const userRole = props.user?.userRole;

  // Get Blocklist
  React.useEffect(() => {
    setLoading(true);
    const promise = getBlocklist();
    Promise.resolve(promise).then((data) => {
      if (data.success) {
        const list = data?.users;
        if (list && list.length) {
          setPlatform(list);
        }
        setLoading(false);
      } else {
        setPlatform([]);
        setLoading(false);
      }
    });
    const type = userRole == '1' ? null : navigate('/dashboard/app');
    return () => {
      return type;
    };
  }, []);

  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`
    };
  }

  // Table Columns
  const columns = [
    {
      field: 'email',
      headerName: 'User Email',
      width: 200
    },
    {
      field: 'ipAddress',
      headerName: 'IP Address',
      width: 150
    },
    {
      field: 'blockedOn',
      headerName: 'Blocked On',
      width: 160,
      renderCell: (value) => moment(value.row.blockedOn).format('MMMM Do, YYYY')
    },
    {
      field: 'action',
      headerName: 'Action',
      width: 110,
      renderCell: (value) => <BlocklistActionButton value={value.row.action} id={value.row._id} />
    }
  ];

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  return (
    <Page title={props.title ? 'Dashboard' : 'Dashboard: Blocklist'}>
      <Box p={props.title ? 0 : 3}>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={2}>
          <Typography variant="h4" gutterBottom>
            Blocklist
          </Typography>
          <Button
            variant="contained"
            component={RouterLink}
            to="/dashboard/blocklist/add-user"
             startIcon={<Iconify icon="eva:plus-fill" />}
          >
            Add User
          </Button>
        </Stack>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs
            value={tabValue}
            onChange={handleTabChange}
            variant="scrollable"
            // scrollButtons
            aria-label="tabs"
            sx={{
              [`& .${tabsClasses.scrollButtons}`]: {
                '&.Mui-disabled': { opacity: '0.3' }
              }
            }}
          >
            <Tab label="By IP Address" {...a11yProps(0)} />
            <Tab label="By Email Address" {...a11yProps(1)} />
          </Tabs>
        </Box>
        <TabPanel value={tabValue} index={0}>
          <BlocklistTabPanel
            platform={platform}
            match="IP Address"
            columns={columns}
            loading={loading}
          />
        </TabPanel>
        <TabPanel value={tabValue} index={1}>
          <BlocklistTabPanel
            platform={platform}
            match="Email Address"
            columns={columns}
            loading={loading}
          />
        </TabPanel>
      </Box>
    </Page>
  );
}
export default withRole(BlockList);

const BlocklistTabPanel = ({ platform, match, columns, loading }) => {
  const [rows, setRows] = React.useState(platform);
  const type = match == 'IP Address' ? 'ipAddress' : 'email';
  return (
    <CustomTable
      searchPlaceholder="Search..."
      tableHeading={`for users blocked by ${match}`}
      platform={platform.filter((row) => row[type] != '')}
      setRows={setRows}
      rows={rows.filter((row) => row[type] != '')}
      rowId={(row) => row._id}
      columns={columns}
      loading={loading}
      data={platform?.length ? platform?.length : 'No Data'} // For No Data
    />
  );
};
