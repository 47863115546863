import { createStore, applyMiddleware } from 'redux';
import { Provider } from 'react-redux';
import thunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';
// routes
import Router from './routes';
// theme
import ThemeProvider from './theme';
// components
import ScrollToTop from './components/ScrollToTop';
// Configure Redux Store
import rootReducer from './Redux/reducers';
import PusherListener from './PusherListener';
// ----------------------------------------------------------------------
const store = createStore(rootReducer, composeWithDevTools(applyMiddleware(thunk)));

export default function App() {
  return (
    <Provider store={store}>
      <PusherListener />
      <ThemeProvider>
        <ScrollToTop />
        <Router />
      </ThemeProvider>
    </Provider>
  );
}
