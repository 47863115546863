import axios from 'axios';
import * as types from '../constants/actionTypes';
import Pusher from 'pusher-js';
import { toast } from 'react-toastify';
import moment from 'moment';

const API = axios.create({ baseURL: process.env.REACT_APP_API_URL });

const pusher = new Pusher(process.env.REACT_APP_PUSHER_KEY, {
  cluster: process.env.REACT_APP_PUSHER_CLUSTER,
  encrypted: true
});

export const logUserActivity = (action) => async (dispatch) => {
  const email = localStorage.getItem('email');
  dispatch({ type: types.ADD_ACTIVITY_ATTEMPT });
  try {
    const res = await API.post(`${process.env.REACT_APP_API_URL}/log/log-action`, { email, action });
    dispatch({
      type: types.ADD_ACTIVITY_SUCCESS,
      payload: res.data
    });
  } catch (err) {
    dispatch({ type: types.ADD_ACTIVITY_FAIL, payload: err });
  }
};

export const listenToPusher = () => (dispatch) => {
  const channel = pusher.subscribe('activity-log');
  channel.bind('user-action', (data) => {
    dispatch({
      type: types.ADD_ACTIVITY_SUCCESS,
      payload: data,
    });

    toast.info(`${data.email} ${data.action} at ${moment(data.timestamp).format('MMMM Do YYYY, h:mm:ss a')}`);
  });

  return () => {
    channel.unbind_all();
    channel.unsubscribe();
  };
};