import React, { useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import moment from 'moment';
// Material
import { Stack, Typography, Button, Container } from '@mui/material';
//
import Label from 'src/components/Label';
import Iconify from '../../components/Iconify';
import Page from '../../components/Page';
import UserActionButton from './components/UserActionButton';
import { getUsers } from 'src/api/api';
import CustomTable from 'src/components/CustomTable';
import withRole from 'src/components/HOC/withRole';

function AllUsers(props) {
  const navigate = useNavigate();
  // Fetch Data from API
  // Searchable Filters
  const [platform, setPlatform] = useState([]);
  const [loading, setLoading] = React.useState(false);
  const [rows, setRows] = useState([]);
  // For User Role
  const userRole = props.user?.userRole;
  const [state, setState] = useState({});

  // Get All Users
  React.useEffect(() => {
    setLoading(true);
    const promise = getUsers();
    Promise.resolve(promise).then((data) => {
      if (data.success) {
        const users = data?.users;
        if (users && users?.length) {
          setPlatform(data?.users);
          setRows(data?.users);
        }
        setLoading(false);
      } else {
        setPlatform([]);
        setRows([]);
        setLoading(false);
      }
    });
    const type = userRole == '1' ? null : navigate('/dashboard/app');
    return () => {
      setState({}); // To Avoid Memory Leak
      return type;
    };
  }, []);

  const columns = [
    {
      field: 'name',
      headerName: 'Name',
      width: 180,
      renderCell: (rowData) => <p style={{ textTransform: 'capitalize' }}>{rowData.row.name}</p>
    },
    { field: 'email', headerName: 'Email', width: 250 },
    {
      field: 'userRole',
      headerName: 'User Role',
      width: 180,
      renderCell: (rowData) => (
        <Label
          variant="ghost"
          color={
            rowData.row.userRole === 2
              ? 'error'
              : rowData.row.userRole === 3
              ? 'warning'
              : rowData.row.userRole === 4
              ? 'info'
              : 'success'
          }
        >
          {rowData.row.roleName}
        </Label>
      )
    },
    {
      field: 'createdAt',
      headerName: 'Created At',
      width: 200,
      renderCell: (rowData) => moment(rowData.row.createdAt).format('MMMM Do, YYYY')
    },
    {
      field: 'action',
      headerName: 'Action',
      width: 160,
      renderCell: (rowData) => <UserActionButton id={rowData.row._id} />
    }
  ];

  return (
    <Page title="Dashboard: Users">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={4}>
          <Typography variant="h4" gutterBottom>
            All Users
          </Typography>
          <Button
            variant="contained"
            component={RouterLink}
            to="/dashboard/users/create-user"
            startIcon={<Iconify icon="eva:plus-fill" />}
          >
            Add User
          </Button>
        </Stack>
        <CustomTable
          searchPlaceholder="Search..."
          tableHeading="for Users"
          platform={platform}
          setRows={setRows}
          rows={rows}
          rowId={(row) => row._id}
          columns={columns}
          loading={loading}
          data={platform?.length ? platform?.length : 'No Data'} // For No Data
        />
      </Container>
    </Page>
  );
}
export default withRole(AllUsers);
