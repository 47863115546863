const currencies = {
  usd: '$',
  gbp: '£',
  cad: 'C$',
  aud: 'A$',
  eur: '€'
};

export const getCurrencySymbol = (currency) => {
  return currencies.hasOwnProperty(currency) ? currencies[currency] : '$';
};
