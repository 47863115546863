import { useState, useEffect } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
//
// import * as Yup from 'yup';
import { Formik, Form, Field, FieldArray } from 'formik';
import { TextField } from 'formik-mui';
// material
import { Stack, Typography, Button, Grid, FormLabel, MenuItem } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import { LoadingButton } from '@mui/lab';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
//
import AllCountriesSelect from './AllCountriesSelect';
// import RichTextEditor from 'src/components/RichTextEditor';
//
import { getCountries, getServicesWithCategories } from 'src/api/api';
import { addPackage } from 'src/Redux/actions/packageActions';

export default function AddPackage() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  // Get Countries
  const [countryLoading, setCountryLoading] = useState(false);
  const [countryData, setCountryData] = useState([]);

  // Get Services
  const [serviceValue, setServiceValue] = useState('');
  const [serviceLoading, setServiceLoading] = useState(false);
  const [services, setServices] = useState([]);
  const [servicesCategory, setServicesCategory] = useState([]);
  const [serviceCategoryLoading, setServiceCategoryLoading] = useState(false);

  const requiredField = <span className="text-danger">*</span>;

  // Get ALL Services
  useEffect(() => {
    setServiceLoading(true);
    const promise = getServicesWithCategories();
    Promise.resolve(promise).then((data) => {
      // console.log('data', data);
      if (data.success) {
        const services = data?.services;
        if (services && services?.length) {
          setServices(data?.services);
        }
        setServiceLoading(false);
      } else {
        setServices([]);
        setServiceLoading(false);
      }
    });
  }, []);

  // Get ALL Countries
  useEffect(() => {
    setCountryLoading(true);
    const promise = getCountries();
    Promise.resolve(promise).then((data) => {
      if (data.success) {
        const countries = data?.countries;
        if (countries && countries?.length) {
          setCountryData(data?.countries);
        }
        setCountryLoading(false);
      } else {
        setCountryData([]);
        setCountryLoading(false);
      }
    });
  }, []);

  // Filter SubCategories
  useEffect(() => {
    const data = services?.find((item) => {
      return item._id == serviceValue;
    });

    // console.log('data', data);
    setServiceCategoryLoading(true);
    setServicesCategory(data?.categories);
    setTimeout(() => {
      setServiceCategoryLoading(false);
    }, 1000);
  }, [serviceValue]);

  return (
    <Grid container>
      <Grid item lg={10} md={10} xs={11} sm={11} mx="auto">
        <Card
          sx={{ minWidth: 275, p: 4, boxShadow: 3, borderRadius: 1, border: '1px solid #929eaa6b' }}
        >
          <CardContent>
            <Formik
              enableReinitialize={true}
              initialValues={{
                service: '',
                packageTitle: '',
                category: '',
                quantity: '',
                packages: [
                  {
                    country: '',
                    currency: '',
                    slug: '',
                    price: '',
                    metaTitle: '',
                    metaDescription: '',
                    canonicalLink: '',
                    shortDescription: '',
                    longDescription: '',
                    features: ''
                  }
                ]
              }}
              onSubmit={(values, e) => {
                const updated = values.packages.map((p) => {
                  p['category'] = values.category;
                  return p;
                });
                var valueArr = values.packages?.map(function (item) {
                  return item.country;
                });
                var isDuplicate = valueArr.some(function (item, idx) {
                  return valueArr.indexOf(item) != idx;
                });
                if (isDuplicate === true) {
                  toast.error('Duplicate Countries Found in this package.');
                  return false;
                }
                dispatch(addPackage(values, navigate));
                e.preventDefault();
                setLoading(true);
                setTimeout(() => {
                  setLoading(false);
                }, 3000);
              }}
            >
              {({ setFieldValue, values }) => (
                <Form autoComplete="off">
                  <Typography variant="h4" gutterBottom>
                    Add New Package
                  </Typography>
                  <Stack spacing={1} mt={2}>
                    <FormLabel id="service">Service Type</FormLabel>
                    {serviceLoading && <CircularProgress size={30} />}
                    {!serviceLoading && (
                      <Field
                        name="service"
                        select
                        defaultValue=""
                        component={TextField}
                        onChange={(e) => {
                          setFieldValue('service', e.target.value);
                          setServiceValue(e.target.value);
                        }}
                        sx={{ width: '95%' }}
                        label="Select Value"
                        required
                      >
                        <MenuItem value="">Not Selected</MenuItem>
                        {services?.map((item) => {
                          return (
                            <MenuItem key={item._id} value={item._id} label={item.name}>
                              {item.name}
                            </MenuItem>
                          );
                        })}
                      </Field>
                    )}
                    <p className="helper-text-style">Select Service first then Categorey.</p>
                    <FormLabel id="category">Category</FormLabel>
                    {serviceCategoryLoading && <CircularProgress size={30} />}
                    {!serviceCategoryLoading && (
                      <Field
                        name="category"
                        select
                        defaultValue=""
                        component={TextField}
                        onChange={(e) => {
                          setFieldValue('category', e.target.value);
                        }}
                        sx={{ width: '95%' }}
                        label="Select Value"
                        disabled={!values.service}
                        required
                      >
                        <MenuItem value="">Not Selected</MenuItem>
                        {servicesCategory?.map((item) => {
                          return (
                            <MenuItem key={item._id} value={item._id} label={item.name}>
                              {item.name}
                            </MenuItem>
                          );
                        })}
                      </Field>
                    )}
                    <FormLabel id="packageTitle">Package Title {requiredField}</FormLabel>
                    <Field
                      type="text"
                      id="packageTitle"
                      name="packageTitle"
                      className="input-style"
                      placeholder="Enter Package Title"
                      required
                    />
                    <FormLabel id="quantity">Quantity {requiredField}</FormLabel>
                    <Field
                      type="number"
                      id="quantity"
                      name="quantity"
                      className="input-style"
                      placeholder="Enter Quantity (Number)"
                      min={0}
                      onWheel={(e) => e.target.blur()}
                      required
                    />

                    <div>
                      {values.packages?.length &&
                        values.packages?.map((item, idx) => {
                          return (
                            <FieldArray name="packages" key={idx}>
                              {({ push, remove }) => (
                                <div className="row" key={idx} id={idx}>
                                  <div className="col button-style">
                                    <div>
                                      {!idx == 0 && (
                                        <Button
                                          type="button"
                                          sx={{ mt: 2, mb: 2 }}
                                          variant="contained"
                                          color="error"
                                          onClick={() => remove(idx)}
                                          className="home-section-delete-button"
                                        >
                                          Delete Column
                                        </Button>
                                      )}
                                      <Button
                                        type="button"
                                        variant="contained"
                                        color="secondary"
                                        sx={{ mt: 2, ml: 1, mb: 2 }}
                                        onClick={() =>
                                          push({
                                            country: '',
                                            currency: '',
                                            slug: '',
                                            price: '',
                                            metaTitle: '',
                                            metaDescription: '',
                                            canonicalLink: '',
                                            shortDescription: '',
                                            longDescription: '',
                                            features: ''
                                          })
                                        }
                                      >
                                        Add Column
                                      </Button>
                                    </div>
                                  </div>
                                  <Grid container>
                                    <Grid item lg={6} md={6} sm={12} xs={12} mt={1}>
                                      {countryLoading ? (
                                        <CircularProgress size={30} />
                                      ) : (
                                        <AllCountriesSelect
                                          requiredField={requiredField}
                                          countries={countryData}
                                          name={`packages[${idx}].country`}
                                          value={item?.country}
                                        />
                                      )}
                                    </Grid>
                                    <Grid item lg={6} md={6} sm={12} xs={12} mt={1}>
                                      <FormLabel id={`packages[${idx}].slug`}>
                                        Package Slug {requiredField}
                                      </FormLabel>
                                      <Field
                                        type="text"
                                        defaultValue=""
                                        id={`packages[${idx}].slug`}
                                        name={`packages[${idx}].slug`}
                                        className="input-style"
                                        placeholder="i.e: (quantity)-service-name"
                                        required
                                      />
                                    </Grid>
                                    <Grid item lg={6} md={6} sm={12} xs={12} mt={1}>
                                      <FormLabel id={`packages[${idx}].currency`}>
                                        Currency (Valid only) {requiredField}
                                      </FormLabel>
                                      <Field
                                        type="text"
                                        defaultValue=""
                                        id={`packages[${idx}].currency`}
                                        name={`packages[${idx}].currency`}
                                        className="input-style"
                                        placeholder="i.e: $"
                                        required
                                        helperText="Text here"
                                      />
                                    </Grid>
                                    <Grid item lg={6} md={6} sm={12} xs={12} mt={1}>
                                      <FormLabel id={`packages[${idx}].price`}>
                                        Price {requiredField}
                                      </FormLabel>
                                      <Field
                                        type="number"
                                        defaultValue=""
                                        step="any"
                                        id={`packages[${idx}].price`}
                                        name={`packages[${idx}].price`}
                                        className="input-style"
                                        placeholder="Price (Number)"
                                        min={0}
                                        onWheel={(e) => e.target.blur()}
                                        required
                                      />
                                    </Grid>
                                    <Grid item lg={6} md={6} sm={12} xs={12} mt={1}>
                                      <FormLabel id={`packages[${idx}].metaTitle`}>
                                        Meta Title
                                      </FormLabel>
                                      <Field
                                        type="text"
                                        defaultValue=""
                                        id={`packages[${idx}].metaTitle`}
                                        name={`packages[${idx}].metaTitle`}
                                        className="input-style"
                                        placeholder="SEO Meta Title"
                                      />
                                    </Grid>
                                    <Grid item lg={6} md={6} sm={12} xs={12} mt={1}>
                                      <FormLabel id={`packages[${idx}].metaDescription`}>
                                        Meta Description
                                      </FormLabel>
                                      <Field
                                        type="text"
                                        defaultValue=""
                                        id={`packages[${idx}].metaDescription`}
                                        name={`packages[${idx}].metaDescription`}
                                        className="input-style"
                                        placeholder="SEO Meta Description"
                                      />
                                    </Grid>
                                    <Grid item lg={6} md={6} sm={12} xs={12} mt={1}>
                                      <FormLabel id={`packages[${idx}].canonicalLink`}>
                                        Canonical Link
                                      </FormLabel>
                                      <Field
                                        type="text"
                                        defaultValue=""
                                        id={`packages[${idx}].canonicalLink`}
                                        name={`packages[${idx}].canonicalLink`}
                                        className="input-style"
                                        placeholder="SEO Canonical Link"
                                      />
                                    </Grid>
                                  </Grid>
                                  <Grid container>
                                    <Grid item lg={4} md={4} sm={6} xs={6} mt={1}>
                                      <FormLabel id={`packages[${idx}].shortDescription`}>
                                        Short Description
                                      </FormLabel>
                                      <Field
                                        as="textarea"
                                        defaultValue=""
                                        id={`packages[${idx}].shortDescription`}
                                        name={`packages[${idx}].shortDescription`}
                                        className="input-style package-textarea"
                                        placeholder="Short Description"
                                      />
                                    </Grid>
                                    <Grid item lg={4} md={4} sm={6} xs={6} mt={1}>
                                      <FormLabel id={`packages[${idx}].longDescription`}>
                                        Long Description
                                      </FormLabel>
                                      <Field
                                        as="textarea"
                                        defaultValue=""
                                        id={`packages[${idx}].longDescription`}
                                        name={`packages[${idx}].longDescription`}
                                        className="input-style package-textarea"
                                        placeholder="Long Description"
                                      />
                                    </Grid>
                                    <Grid item lg={4} md={4} sm={6} xs={6} mt={1}>
                                      <FormLabel id={`packages[${idx}].features`}>
                                        Features
                                      </FormLabel>
                                      <Field
                                        as="textarea"
                                        defaultValue=""
                                        id={`packages[${idx}].features`}
                                        name={`packages[${idx}].features`}
                                        className="input-style package-textarea"
                                        placeholder="One Feature Per Line"
                                      />
                                    </Grid>
                                  </Grid>
                                </div>
                              )}
                            </FieldArray>
                          );
                        })}
                    </div>

                  </Stack>

                  <Grid container>
                    <Grid xs={4} sm={4} md={3} lg={2} mt={3} item>
                      <LoadingButton
                        fullWidth
                        size="large"
                        type="submit"
                        variant="contained"
                        loading={loading}
                        // onClick={(e) => e.preventDefault()}
                      >
                        Submit
                      </LoadingButton>
                    </Grid>
                    <Grid xs={4} sm={4} md={3} lg={2} ml={2} mt={3} item>
                      <Button
                        fullWidth
                        size="large"
                        variant="contained"
                        color="error"
                        component={RouterLink}
                        to="/dashboard/packages"
                      >
                        Cancel
                      </Button>
                    </Grid>
                  </Grid>
                </Form>
              )}
            </Formik>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
}
