import React from 'react';
import { Grid, Container, Stack, Typography, Button, Box, Card, CardContent } from '@mui/material';
import Label from 'src/components/Label';

function StripeWebhook(props) {

  return (
    <Grid container display="flex" alignItems="start" justifyContent="start" spacing={1}>
      {props.data.data?.length > 0 &&
        props.data.data.map((item) => {
          return (
            <Grid item lg={6} md={6} xs={12} sm={12} mt={2}>
              <Box sx={{ borderRadius: '10px', border: '1px solid #929eaa6b' }} key={item.id}>
                <Card sx={{ maxWidth: '100%' }}>
                  <CardContent>
                    <Label variant="ghost" color="warning">
                      Stripe Webhook Status
                    </Label>
                    <Typography variant="body2" mt={2} mb={2} sx={{ fontSize: 17 }}>
                      <span>
                        <strong>ID: </strong> {item.id}
                      </span>
                    </Typography>
                    <Typography variant="body2" mt={2} mb={2} sx={{ fontSize: 17 }}>
                      <span>
                        <strong>Url: </strong> {item.url}
                      </span>
                    </Typography>
                    <strong>Status: </strong>{' '}
                    <Label variant="ghost" color="success">
                      {item.status}
                    </Label>
                    <Typography variant="body2" mt={2} mb={2} sx={{ fontSize: 17 }}>
                      <span>
                        <strong>Live Mode: </strong> {item.livemode === true ? 'True' : 'False'}
                      </span>
                    </Typography>
                    {item.description !== null && (
                      <Typography variant="body2" mt={2} mb={2} sx={{ fontSize: 17 }}>
                        <strong> Description: {item.description} </strong>
                      </Typography>
                    )}
                    <Typography variant="body2" mt={2} mb={2} sx={{ fontSize: 17 }}>
                      <strong>Enabled Events: </strong>
                      {item.enabled_events.length > 0 &&
                        item.enabled_events.map((e) => {
                          return (
                            <Label variant="ghost" color="success">
                              {e}
                            </Label>
                          );
                        })}
                    </Typography>
                  </CardContent>
                </Card>
              </Box>
            </Grid>
          );
        })}
    </Grid>
  );
}

export default StripeWebhook;
