import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// MUI Components
import { Container, Stack, Typography } from '@mui/material';
// Custom Components
import Page from 'src/components/Page';
// import Loader from 'src/components/Loader';
import CustomButton from 'src/components/Button';
// action
// import { deleteFaq } from 'src/Redux/actions/faqsActions';
import { allPageSections } from 'src/Redux/actions/pageSectionActions';
import FaqsMenuTabs from './components/FaqsMenuTabs';

function FAQs() {
  const dispatch = useDispatch();

  const [loading, setLoading] = React.useState(false);

  // Get Data from Redux Store
  const pageState = useSelector((state) => state);
  const [pageSections, setPageSection] = useState({
    sections: []
  });
  const formData = pageState.pageSections;

  useEffect(() => {
    setPageSection({
      ...formData
    });
  }, [pageState.pageSections]);

  // Get Page Data
  useEffect(() => {
    const fetchData = () => {
      try {
        setLoading(true);
        dispatch(allPageSections('/faqs'));
        setLoading(false);
      } catch (error) {
        setLoading(true);
      }
    };
    fetchData();
  }, []);
  return (
    <Page title="Dashboard Pages | FAQs">
      <Container>
        <Typography variant="h4" gutterBottom>
          FAQs Page Data
        </Typography>

        <Stack direction="row" justifyContent="end" mb={0.5}>
          <CustomButton
            btnText="Add Faqs"
            icon="ic:round-create"
            variant="contained"
            color="secondary"
            link="/dashboard/pages/faqs/add-faq"
          />
        </Stack>
        <FaqsMenuTabs />
      </Container>
    </Page>
  );
}

export default FAQs;
