import React from 'react';
//
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import Box from '@mui/material/Box';
import { Typography, Stack } from '@mui/material';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
//
import OrderActionButton from './OrderActionButton';

function Row(props) {
  const { row, orderData } = props;
  const [open, setOpen] = React.useState(false);

  const BootstrapTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
      color: theme.palette.common.black
    },
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.black
    }
  }));
  return (
    <React.Fragment>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
        <TableCell>
          <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell>{row.serviceTitle}</TableCell>
        <TableCell>{row.serviceId ? row.serviceId : 'Not Found'}</TableCell>
        <TableCell>{row.quantity}</TableCell>
        {props.userRole !== '4' && (
          <TableCell>
            {row.currency}
            {row.price}
          </TableCell>
        )}
      </TableRow>

      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={12}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ m: 1 }}>
              {row.orderItemPosts?.length ? (
                <Typography mt={2} mb={2} align="center" variant="h6">
                  Order Posts
                </Typography>
              ) : (
                ''
              )}
              <Table size="large" aria-label="table">
                {row.orderItemPosts?.length ? (
                  <TableHead sx={{ borderTop: '1px solid rgba(241, 243, 244, 1)' }}>
                    <TableRow>
                      <TableCell sx={{ fontWeight: '700', textAlign: 'center' }}>Post ID</TableCell>
                      <TableCell sx={{ fontWeight: '700', textAlign: 'center' }}>
                        Per Post
                      </TableCell>
                      <TableCell sx={{ fontWeight: '700', textAlign: 'center' }}>API Id</TableCell>
                      <TableCell sx={{ fontWeight: '700', textAlign: 'center' }}>
                        Comments
                      </TableCell>
                      <TableCell sx={{ fontWeight: '700', textAlign: 'center' }}>Action</TableCell>
                    </TableRow>
                  </TableHead>
                ) : (
                  <Typography align="center" variant="h6">
                    No Posts Found
                  </Typography>
                )}
                {row.orderItemPosts?.map((items, index) => {
                  return (
                    <TableBody key={index}>
                      <TableRow key={items.id}>
                        <TableCell align="center" width="15%">
                          <BootstrapTooltip title={items.post_id}>
                            <a
                              href={items.post_id}
                              target="_blank"
                              style={{ textDecoration: 'none' }}
                            >
                              <Button>Link</Button>
                            </a>
                          </BootstrapTooltip>
                        </TableCell>
                        <TableCell align="center" width="15%">
                          {items.per_post}
                        </TableCell>
                        <TableCell align="center" width="15%">
                          {items.apiOrderId ? items.apiOrderId : <i>empty</i>}
                        </TableCell>
                        <TableCell align="center" width="50%">
                          {items.comments ? (
                            items.comments.split(/\\?\n/).map((cmt, i) => (
                              <p key={cmt + i} style={{ wordBreak: 'break-word' }}>
                                {cmt}
                              </p>
                            ))
                          ) : (
                            <i>empty</i>
                          )}
                          {items.comments && (
                            <Button
                              variant="outlined"
                              size="small"
                              onClick={(e) => {
                                navigator.clipboard.writeText(items.comments);
                                e.target.textContent = 'Comments Copied';
                                setTimeout(() => {
                                  e.target.textContent = 'Copy Comments';
                                }, 3000);
                              }}
                            >
                              Copy Comments
                            </Button>
                          )}
                        </TableCell>
                        <TableCell align="center" width="15%">
                          <OrderActionButton
                            orderData={orderData}
                            items={row}
                            item={items}
                            urlId={props.urlId}
                            color="primary"
                          />
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  );
                })}
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

export default function OrderItemsTable(props) {
  const { items, loading } = props;

  return (
    <TableContainer
      component={Paper}
      sx={{
        boxShadow: 5
      }}
    >
      {!loading && items?.length && (
        <Table aria-label="collapsible table">
          <TableHead>
            <TableRow>
              <TableCell sx={{ fontWeight: '700' }}>Items</TableCell>
              <TableCell sx={{ fontWeight: '700' }}>Title</TableCell>
              <TableCell sx={{ fontWeight: '700' }}>Service ID</TableCell>
              <TableCell sx={{ fontWeight: '700' }}>Package Quantity</TableCell>
              {props.userRole !== '4' && <TableCell sx={{ fontWeight: '700' }}>Price</TableCell>}
            </TableRow>
          </TableHead>
          <TableBody>
            {items?.map((item) => (
              <Row
                key={item._id}
                row={item}
                userRole={props.userRole}
                rows={item}
                urlId={props.urlId}
                orderData={props?.orderData}
              />
            ))}
          </TableBody>
        </Table>
      )}
      {!loading && items?.length <= 0 && (
        <Stack sx={{ m: '20px 0', textAlign: 'center' }}>
          <Typography>NO DATA</Typography>
        </Stack>
      )}
    </TableContainer>
  );
}
