import * as types from '../constants/actionTypes';

const initialState = {
  items: [],
  loading: true,
  reviews: {}
};

export default function (state = initialState, action) {
  switch (action.type) {
    case types.CREATE_FAQS_SUCCESS:
      return {
        ...state,
        items: action.payload,
        reviews: action.payload,
        loading: false
      };
    case types.SINGLE_FAQS_SUCCESS:
      return {
        ...state,
        items: action.payload,
        loading: false
      };
    case types.GET_FAQS_SUCCESS:
      return {
        ...state,
        items: action.payload,
        reviews: action.payload,
        loading: false
      };
    case types.UPDATE_FAQS_SUCCESS:
      return {
        ...state,
        items: action.payload,
        reviews: action.payload,
        loading: false
      };
    case types.DELETE_FAQS_SUCCESS:
      return {
        ...state,
        items: action.payload,
        reviews: action.payload,
        loading: false
      };
    default:
      return state;
  }
}
