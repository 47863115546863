import { useState, useEffect } from 'react';
import { Link as RouterLink, useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Formik, Form, Field, FieldArray } from 'formik';
import { TextField } from 'formik-mui';
import { generateId } from 'src/Redux/helpers/generalHelper';
// material
import { Stack, Typography, Button, Grid, FormLabel, MenuItem } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import { LoadingButton } from '@mui/lab';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
//
import { getCountries, getServicesWithCategories } from 'src/api/api';
import { singlePackage, updatePackage, emptyPackageData } from 'src/Redux/actions/packageActions';
import AllCountriesSelect from './AllCountriesSelect';
// import { request } from 'src/Redux/helpers/axiosRequest';
import ActionButtonPackages from './ActionButtonPackages';

export default function EditPackage() {
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  // Get Countries
  const [countryLoading, setCountryLoading] = useState(false);
  const [countryData, setCountryData] = useState([]);

  // Get All Packages
  const pageState = useSelector((state) => state.packages);

  // Get Services
  const [serviceValue, setServiceValue] = useState('');
  const [serviceLoading, setServiceLoading] = useState(false);
  const [services, setServices] = useState([]);
  const [servicesCategory, setServicesCategory] = useState([]);

  useEffect(() => {
    setServiceValue(pageState.items?.category?.service);
  }, [pageState.items?.category?.service]);

  useEffect(() => {
    const fetchData = () => {
      try {
        setLoading(true);
        dispatch(singlePackage(id));
        setLoading(false);
      } catch (error) {
        setLoading(true);
      }
    };
    fetchData();

    return () => dispatch(emptyPackageData());
  }, []);

  // Get ALL Services
  useEffect(() => {
    setServiceLoading(true);
    getServicesWithCategories().then((data) => {
      if (data.success) {
        const services = data?.services;
        if (services && services?.length) {
          setServiceValue(pageState.items?.category?.service);
          setServices(data?.services);
        }
        setServiceLoading(false);
      } else {
        setServices([]);
        setServiceLoading(false);
      }
    });
  }, []);

  // Get ALL Countries
  useEffect(() => {
    setCountryLoading(true);
    getCountries().then((data) => {
      if (data.success) {
        const countries = data?.countries;
        if (countries && countries?.length) {
          setCountryData(data?.countries);
        }
        setCountryLoading(false);
      } else {
        setCountryData([]);
        setCountryLoading(false);
      }
    });
  }, []);

  // Filter SubCategories
  useEffect(() => {
    const service = services?.find((item) => item._id == serviceValue) || {};
    setServicesCategory(service?.categories || []);
  }, [services, serviceValue]);

  return (
    <Grid container>
      <Grid item lg={10} md={10} xs={11} sm={11} mx="auto">
        <Card
          sx={{ minWidth: 275, p: 4, boxShadow: 3, borderRadius: 1, border: '1px solid #929eaa6b' }}
        >
          <CardContent>
            <Formik
              enableReinitialize={true}
              initialValues={{
                _id: pageState.items._id,
                packageTitle: pageState.items ? pageState.items.packageTitle : '',
                service: pageState.items ? pageState.items?.category?.service : '',
                category: pageState.items ? pageState.items?.category?._id : '',
                quantity: pageState.items ? pageState.items.quantity : '',
                details:
                  pageState?.items?.details?.length > 0
                    ? pageState?.items?.details
                    : [
                        {
                          _id: generateId(),
                          package: pageState?.items._id,
                          active: true,
                          category: pageState.items?.category?._id,
                          country: '',
                          currency: '',
                          slug: '',
                          price: '',
                          metaTitle: '',
                          metaDescription: '',
                          canonicalLink: '',
                          shortDescription: '',
                          longDescription: '',
                          features: '',
                          buttonDescription: ''
                        }
                      ]
              }}
              onSubmit={(values, e) => {
                dispatch(updatePackage(values, navigate));
                e.preventDefault();
                setLoading(true);
                setTimeout(() => {
                  setLoading(false);
                }, 3000);
              }}
            >
              {({ values, errors, touched, setFieldValue }) => (
                <Form autoComplete="off">
                  {/* <code style={{ lineBreak: 'anywhere' }}>{JSON.stringify(values.service)}</code> */}
                  <Typography variant="h4" gutterBottom>
                    Edit Package
                  </Typography>
                  <Stack spacing={1} mt={2}>
                    <FormLabel id="service">Service Type</FormLabel>
                    {serviceLoading && <CircularProgress size={30} />}
                    {!serviceLoading && (
                      <Field
                        name="service"
                        select
                        defaultValue={pageState.items?.category?.service}
                        component={TextField}
                        onChange={(e) => {
                          setServiceValue(e.target.value);
                          setFieldValue('service', e.target.value);
                        }}
                        sx={{ width: '95%' }}
                        label="Select Value"
                        required
                        // disabled
                      >
                        <MenuItem value="">Not Selected</MenuItem>
                        {services?.map((item) => {
                          return (
                            <MenuItem
                              key={item._id}
                              id={item._id}
                              value={item._id}
                              label={item.name}
                            >
                              {item.name}
                            </MenuItem>
                          );
                        })}
                      </Field>
                    )}
                    <FormLabel id="category">Category</FormLabel>
                    {serviceLoading && <CircularProgress size={30} />}
                    {!serviceLoading && (
                      <Field
                        name="category"
                        select
                        defaultValue={pageState.items?.category?._id}
                        component={TextField}
                        onChange={(e) => {
                          setFieldValue('category', e.target.value);
                        }}
                        sx={{ width: '95%' }}
                        label="Select Value"
                        required
                      >
                        <MenuItem value="">Not Selected</MenuItem>
                        {servicesCategory?.map((item) => (
                          <MenuItem key={item._id} value={item._id} label={item.name}>
                            {item.name}
                          </MenuItem>
                        ))}
                      </Field>
                    )}

                    <FormLabel id="packageTitle">Package Title</FormLabel>
                    <Field
                      type="text"
                      id="packageTitle"
                      name="packageTitle"
                      className="input-style"
                      placeholder="Enter Package Title"
                      required
                    />
                    {errors.packageTitle && touched.packageTitle ? (
                      <div className="error-color">{errors.packageTitle}</div>
                    ) : null}
                    <FormLabel id="quantity">Quantity</FormLabel>
                    <Field
                      type="number"
                      id="quantity"
                      name="quantity"
                      className="input-style"
                      placeholder="Enter Quantity (Number)"
                      min={0}
                      onWheel={(e) => e.target.blur()}
                      required
                    />
                    <div>
                      {values.details?.length &&
                        values.details?.map((item, idx) => {
                          item.category = pageState.items?.category?._id;
                          return (
                            <FieldArray name="details">
                              {({ push, remove }) => (
                                <div className="row" key={idx} id={idx}>
                                  <div className="col button-style">
                                    <ActionButtonPackages
                                      id={item?._id}
                                      packageId={item?.package}
                                      remove={remove}
                                      push={push}
                                      idx={idx}
                                      values={values}
                                    />
                                  </div>
                                  <Grid container>
                                    <Grid item lg={6} md={6} sm={12} xs={12} mt={1}>
                                      {countryLoading ? (
                                        <CircularProgress size={30} />
                                      ) : (
                                        <AllCountriesSelect
                         
                                          countries={countryData}
                                          name={`details[${idx}].country`}
                                          value={item?.country}
                                        />
                                      )}
                                    </Grid>
                                    <Grid item lg={6} md={6} sm={12} xs={12} mt={1}>
                                      <FormLabel id={`details[${idx}].slug`}>
                                        Package Slug
                                      </FormLabel>
                                      <Field
                                        type="text"
                                        defaultValue=""
                                        id={`details[${idx}].slug`}
                                        name={`details[${idx}].slug`}
                                        className="input-style"
                                        placeholder="i.e: (quantity)-service-(category)"
                                        required
                                      />
                                    </Grid>
                                    <Grid item lg={6} md={6} sm={12} xs={12} mt={1}>
                                      <FormLabel id={`details[${idx}].currency`}>
                                        Currency
                                      </FormLabel>
                                      <Field
                                        type="text"
                                        defaultValue=""
                                        id={`details[${idx}].currency`}
                                        name={`details[${idx}].currency`}
                                        className="input-style"
                                        placeholder="i.e: $"
                                        required
                                      />
                                    </Grid>
                                    <Grid item lg={6} md={6} sm={12} xs={12} mt={1}>
                                      <FormLabel id={`details[${idx}].price`}>Price</FormLabel>
                                      <Field
                                        type="number"
                                        defaultValue=""
                                        step="any"
                                        id={`details[${idx}].price`}
                                        name={`details[${idx}].price`}
                                        className="input-style"
                                        placeholder="Enter Package Price"
                                        onWheel={(e) => e.target.blur()}
                                        required
                                      />
                                    </Grid>
                                    <Grid item lg={6} md={6} sm={12} xs={12} mt={1}>
                                      <FormLabel id={`details[${idx}].metaTitle`}>
                                        Meta Title
                                      </FormLabel>
                                      <Field
                                        type="text"
                                        defaultValue=""
                                        id={`details[${idx}].metaTitle`}
                                        name={`details[${idx}].metaTitle`}
                                        className="input-style"
                                        placeholder="Enter Meta Title"
                                      />
                                    </Grid>
                                    <Grid item lg={6} md={6} sm={12} xs={12} mt={1}>
                                      <FormLabel id={`details[${idx}].metaDescription`}>
                                        Meta Description
                                      </FormLabel>
                                      <Field
                                        type="text"
                                        defaultValue=""
                                        id={`details[${idx}].metaDescription`}
                                        name={`details[${idx}].metaDescription`}
                                        className="input-style"
                                        placeholder="Enter Meta Description"
                                      />
                                    </Grid>
                                    <Grid item lg={6} md={6} sm={12} xs={12} mt={1}>
                                      <FormLabel id={`details[${idx}].canonicalLink`}>
                                        Canonical Link
                                      </FormLabel>
                                      <Field
                                        type="text"
                                        defaultValue=""
                                        id={`details[${idx}].canonicalLink`}
                                        name={`details[${idx}].canonicalLink`}
                                        className="input-style"
                                        placeholder="Enter Canonical Link"
                                      />
                                    </Grid>
                                    <Grid item lg={6} md={6} sm={12} xs={12} mt={1}>
                                      <FormLabel id={`details[${idx}].buttonDescription`}>
                                        Button Description
                                      </FormLabel>
                                      <Field
                                        type="text"
                                        defaultValue=""
                                        id={`details[${idx}].buttonDescription`}
                                        name={`details[${idx}].buttonDescription`}
                                        className="input-style"
                                        placeholder="Enter Button Description"
                                      />
                                    </Grid>
                                  </Grid>
                                  <Grid container>
                                    <Grid item lg={4} md={4} sm={6} xs={6} mt={1}>
                                      <FormLabel id={`details[${idx}].shortDescription`}>
                                        Short Description
                                      </FormLabel>
                                      <Field
                                        as="textarea"
                                        defaultValue=""
                                        value={item?.shortDescription}
                                        id={`details[${idx}].shortDescription`}
                                        name={`details[${idx}].shortDescription`}
                                        className="input-style package-textarea"
                                        placeholder="Short Description"
                                      />
                                    </Grid>
                                    <Grid item lg={4} md={4} sm={6} xs={6} mt={1}>
                                      <FormLabel id={`details[${idx}].longDescription`}>
                                        Long Description
                                      </FormLabel>
                                      <Field
                                        as="textarea"
                                        defaultValue=""
                                        value={item?.longDescription}
                                        id={`details[${idx}].longDescription`}
                                        name={`details[${idx}].longDescription`}
                                        className="input-style package-textarea"
                                        placeholder="Long Description"
                                      />
                                    </Grid>
                                    <Grid item lg={4} md={4} sm={6} xs={6} mt={1}>
                                      <FormLabel id={`details[${idx}].features`}>
                                        Features
                                      </FormLabel>
                                      <Field
                                        as="textarea"
                                        value={item?.features}
                                        defaultValue=""
                                        id={`details[${idx}].features`}
                                        name={`details[${idx}].features`}
                                        className="input-style package-textarea"
                                        placeholder="One Feature Per Line"
                                      />
                                    </Grid>
                                  </Grid>
                                </div>
                              )}
                            </FieldArray>
                          );
                        })}
                    </div>
                  </Stack>
                  <Grid container>
                    <Grid xs={4} sm={4} md={3} lg={2} mt={3} item>
                      <LoadingButton
                        fullWidth
                        size="large"
                        type="submit"
                        variant="contained"
                        loading={loading}
                      >
                        Submit
                      </LoadingButton>
                    </Grid>
                    <Grid xs={4} sm={4} md={3} lg={2} ml={2} mt={3} item>
                      <Button
                        fullWidth
                        size="large"
                        variant="contained"
                        color="error"
                        component={RouterLink}
                        to="/dashboard/packages"
                      >
                        Cancel
                      </Button>
                    </Grid>
                  </Grid>
                </Form>
              )}
            </Formik>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
}
