import * as types from '../constants/actionTypes';

const initialState = {
  items: [],
  loading: true,
  footer: {}
};

export default function (state = initialState, action) {
  switch (action.type) {
    case types.ADD_FOOTER_MENU_SUCCESS:
      return {
        ...state,
        items: action.payload,
        footer: action.payload,
        loading: false
      };
    case types.GET_FOOTER_MENUS_SUCCESS:
      return {
        ...state,
        items: action.payload,
        footer: action.payload,
        loading: false
      };
    case types.GET_COUNTRY_MENUS_SUCCESS:
      return {
        ...state,
        items: action.payload,
        footer: action.payload,
        loading: false
      };
    case types.SINGLE_FOOTER_MENU_SUCCESS:
      return {
        ...state,
        // items: action.payload,
        footer: action.payload,
        loading: false
      };
    case types.UPDATE_FOOTER_MENU_SUCCESS:
      return {
        ...state,
        items: action.payload,
        footer: action.payload,
        loading: false
      };
    case types.DELETE_FOOTER_MENU_SUCCESS:
      return {
        ...state,
        items: action.payload,
        footer: action.payload,
        loading: false
      };
    default:
      return state;
  }
}
