import * as React from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import moment from 'moment';
import { Stack, Typography, Button, Container } from '@mui/material';
//
import CustomTable from 'src/components/CustomTable';
import Iconify from '../../components/Iconify';
import Page from '../../components/Page';
import CouponActionButton from './components/CouponActionButton';
import { request } from 'src/Redux/helpers/axiosRequest';
import withRole from 'src/components/HOC/withRole';

function Coupons(props) {
  // Fetch Data from API
  // Searchable Filters
  const [platform, setPlatform] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [rows, setRows] = React.useState([]);
  // Get All Coupons
  React.useEffect(() => {
    setLoading(true);
    request({
      url: `${process.env.REACT_APP_API_URL}/coupons/get-all`,
      method: 'get',
      data: null
    }).then(({ data }) => {
      setRows(data.coupons);
      setPlatform(data.coupons);
      setLoading(false);
    });
  }, []);

  const columns = [
    { field: 'promoName', headerName: 'Promo Name', width: 110 },
    { field: 'promoCode', headerName: 'Promo Code', width: 110 },
    { field: 'promoLimit', headerName: 'Promo Limit', width: 100 },
    {
      field: 'promoUsed',
      headerName: 'Promo Used',
      width: 100
    },
    {
      field: 'discountPercent',
      headerName: 'Discount Percent',
      width: 130,
      renderCell: (rowData) => <>{rowData.row.discountPercent} %</>
    },
    {
      field: 'startDate',
      headerName: 'Start Date',
      width: 130,
      renderCell: (rowData) => moment(rowData.row.startDate).format('MMMM Do, YYYY')
    },
    {
      field: 'endDate',
      headerName: 'End Date',
      width: 130,
      renderCell: (rowData) => moment(rowData.row.endDate).format('MMMM Do, YYYY')
    },
    {
      field: 'action',
      headerName: 'Action',
      width: 110,
      renderCell: (value) => <CouponActionButton value={value.row.action} id={value.row._id} />
    }
  ];

  return (
    <Page title={props.title ? 'Dashboard' : 'Dashboard: Coupons'}>
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={2}>
          {!props?.userRole ? (
            <>
              <Typography variant="h4" gutterBottom>
                All Coupons
              </Typography>
              <Button
                variant="contained"
                component={RouterLink}
                to="/dashboard/coupons/add-coupon"
                startIcon={<Iconify icon="eva:plus-fill" />}
              >
                Add Coupons
              </Button>
            </>
          ) : (
            ''
          )}
        </Stack>
        <CustomTable
          searchPlaceholder="Search..."
          tableHeading="for Coupons"
          platform={platform}
          setRows={setRows}
          rows={rows}
          rowId={(row) => row._id}
          columns={columns}
          loading={loading}
          data={platform?.length ? platform?.length : 'No Data'} // For No Data
        />
      </Container>
    </Page>
  );
}
export default withRole(Coupons);
