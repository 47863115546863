import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import Iconify from 'src/components/Iconify';
// MUI Components
import { Button } from '@mui/material';

export default function CustomButton(props) {
  const btnText = props.btnText ? props.btnText : 'Click me';
  return <RenderIconButton {...props}>{btnText}</RenderIconButton>;
}

const RenderIconButton = (props) => {
  const handleClick = (e) => {
    // console.log('button clicked', e.target.textContent);
  };
  const btnClasses = props.btnClasses ? props.btnClasses : '',
    disabled = props.disabled ? props.disabled : false,
    icon = props.icon ? props.icon : '',
    color = props.color ? props.color : 'primary',
    link = props.link ? props.link : '#',
    variant = props.variant ? props.variant : '',
    type = props.btnType ? props.btnType : 'button',
    width = props.width ? props.width : 'auto',
    size = props.size ? props.size : 'default',
    click = props.click ? props.click : handleClick;

  return (
    <Button
      disabled={disabled}
      type={type}
      className={btnClasses}
      variant={variant}
      color={color}
      component={RouterLink}
      to={link}
      startIcon={<Iconify icon={icon} />}
      sx={{ width, ...props.style }}
      size={size}
      onClick={click}
    >
      {props.children}
      {/* {props.spinner && <BtnSpinner />} */}
    </Button>
  );
};

// const BtnSpinner = () => {
//   return (
//     <div className="wrap-btn-spinner">
//       <div class="spinner-border spinner-border-sm" role="status">
//         <span class="visually-hidden">Loading...</span>
//       </div>
//     </div>
//   );
// };
