import React from 'react';
import { useDispatch } from 'react-redux';
import { Form, Formik, Field } from 'formik';
import { Stack, FormLabel } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { updateOrderItem } from 'src/Redux/actions/ordersActions';

function EditOrderForm(props) {
  const { orderData, orderItem } = props;
  const dispatch = useDispatch();
  const [loading, setLoading] = React.useState(false);

  return (
    <div>
      <Formik
        enableReinitialize={true}
        initialValues={{
          orderId: orderData._id,
          serviceId: orderItem.serviceId,
          id: props.item._id ? props.item._id : null,
          apiOrderId: props.item.apiOrderId ? props.item.apiOrderId : null,
          post_id: props.item.post_id ? props.item.post_id : '',
          per_post: props.item.per_post ? props.item.per_post : ''
        }}
        onSubmit={(values) => {
          // console.log('values', values);
          // console.log('props.urlId', props.urlId);
          dispatch(updateOrderItem(values, props.urlId));
          setLoading(true);
          setTimeout(() => {
            setLoading(false);
          }, 1000);
        }}
      >
        {({}) => (
          <Form autoComplete="off" style={{ padding: 'inherit' }}>
            <Stack spacing={1}>
              <FormLabel id="post_id">Post Id:</FormLabel>
              <Field
                type="text"
                id="post_id"
                name="post_id"
                className="input-style"
                placeholder="e.g: https://www.instagram.com/therock"
              />
              <FormLabel id="per_post">Per Post:</FormLabel>
              <Field
                type="number"
                id="per_post"
                name="per_post"
                className="input-style"
                placeholder="Enter Per Post. e.g: 100"
              />
              <FormLabel id="apiOrderId">API Order ID:</FormLabel>
              <Field
                type="text"
                id="apiOrderId"
                name="apiOrderId"
                className="input-style"
                placeholder="Enter API Order ID"
              />
            </Stack>

            <Stack direction="row" alignItems="center" justifyContent="space-between" mt={4}>
              <LoadingButton size="large" type="submit" variant="contained" loading={loading}>
                Submit
              </LoadingButton>
              <LoadingButton
                size="large"
                color="error"
                variant="contained"
                onClick={props.handleClose}
              >
                Cancel
              </LoadingButton>
            </Stack>
          </Form>
        )}
      </Formik>
    </div>
  );
}

export default EditOrderForm;
